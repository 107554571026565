import {
  Avatar,
  Box,
  Typography,
  Tooltip,
  CardActionArea,
} from "@mui/material";
import React from "react";
import { shortHospName } from "../../../functions/FuncPerjer";
import { IVisit } from "@/types/visit";
import { dateToLocalDateString } from "../../../functions/FuncDateTimes";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";

interface IVisitItem {
  visitItem: IVisit;
  onSelectVisit(visitItem: any): void;
  idVisit: string;
}
function VisitItem({ visitItem, onSelectVisit, idVisit }: IVisitItem) {
  const { appData }: any = useSelector((state) => state);
  const {
    visitDateTime,
    departmentName,
    hospName,
    hospCode,
    labResults,
    xrays,
    dentals,
    doctorNotes,
    an,
    appointments,
    referrals,
    _id,
  } = visitItem;
  const bgColor = _id === idVisit ? "bg-sky-50" : "bg-white";
  const textColor = _id === idVisit ? "#2F729D" : "rgba(0, 0, 0, 0.75)";

  return (
    <CardActionArea>
      <Box
        onClick={() => onSelectVisit(visitItem)}
        paddingY={1}
        paddingX={2}
        className={`${bgColor} border-b-2 border-gray-100 w-full text-left`}
      >
        <Typography color={textColor} fontSize={14} fontWeight={"bold"}>
          {hospName ? shortHospName(hospName) : hospCode}
        </Typography>

        <Box display={"flex"} gap={1} mt={1}>
          <DateRangeIcon fontSize="inherit" color="warning" />
          <Typography color={textColor} fontSize={12}>
            {dateToLocalDateString(visitDateTime) || "ไม่ระบุ"}
          </Typography>
        </Box>

        <Box display={"flex"} gap={1}>
          <LocationOnIcon fontSize="inherit" color="warning" />
          <Typography color={textColor} fontSize={12}>
            {departmentName || "ไม่ระบุ"}
          </Typography>
        </Box>

        <Box mt={2} gap={1.5} display="flex">
          {labResults?.length > 0 && (
            <Tooltip title="Lab">
              <Avatar
                variant="square"
                sx={{
                  marginTop: "2px",
                  width: 28,
                  height: 28,
                }}
                src="/lab.png"
              />
            </Tooltip>
          )}
          {xrays?.length > 0 && (
            <Tooltip title="Xray">
              <Avatar
                variant="square"
                sx={{
                  marginTop: "2px",
                  width: 28,
                  height: 28,
                }}
                src="/xray.png"
              />
            </Tooltip>
          )}
          {dentals?.length > 0 && (
            <Tooltip title="Dental">
              <Avatar
                variant="square"
                sx={{
                  width: 30,
                  height: 30,
                }}
                src="/dentist.png"
              />
            </Tooltip>
          )}
          {doctorNotes?.length > 0 && (
            <Tooltip title="Doctor Note">
              <Avatar
                variant="square"
                sx={{
                  width: 32,
                  height: 32,
                }}
                src="/note.png"
              />
            </Tooltip>
          )}
          {an && visitItem.class === "IMP" && (
            <Tooltip title="Admit">
              <Avatar
                variant="square"
                sx={{
                  width: 30,
                  height: 30,
                }}
                src="/admit.png"
              />
            </Tooltip>
          )}
          {appointments?.length > 0 && (
            <Tooltip title="Appointment (การนัดหมาย)">
              <Avatar
                variant="square"
                sx={{
                  marginTop: "2px",
                  width: 30,
                  height: 30,
                }}
                src="/appointment.png"
              />
            </Tooltip>
          )}
          {referrals?.length > 0 && (
            <Tooltip title="Refer">
              <Avatar
                variant="square"
                sx={{
                  width: 32,
                  height: 32,
                  transform:
                    appData?.loginData?.hospCode !== hospCode
                      ? "scaleX(-1)"
                      : "scaleX(1)",
                }}
                src="/refer.png"
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    </CardActionArea>
  );
}

export default VisitItem;
