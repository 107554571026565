import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { IDoctor } from "../../types/clinic";

type IFetchDoctorParams = {
  token: string;
  doctorId: string[];
};
const fetchDoctors = async ({
  token,
  doctorId,
}: IFetchDoctorParams): Promise<IDoctor[]> => {
  const url = getApiUrl();
  const result = await axios.post(
    `${url}/v1/clinic/doctors`,
    { doctorId },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return result.data;
};

export default fetchDoctors;
