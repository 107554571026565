import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import { forEach, reduce } from "lodash";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IconButton, Tooltip } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { DateTimeToStrDatetime, ThaiDate } from "../../functions/FuncDateTimes";
import "../../styles/reports/ReportDiag4BranchsTable.scss";
import { IReportDiagFourBranchObject, IReportResponse } from "@/types/report";
import { ReferralType } from "../../types/refers";

interface IReportDiag4BranchTable {
  report: IReportResponse;
  referType: ReferralType;
  startDate: Date;
  endDate: Date;
}

export default function ReportDiag4BranchTable({
  report,
  referType,
  startDate,
  endDate,
}: IReportDiag4BranchTable) {
  const [outerData, setOuterData] =
    useState<{ id: string; name: string; amount: number }[]>();
  const [innerData, setInnerData] = useState<{
    accident: IReportDiagFourBranchObject[];
    heart: IReportDiagFourBranchObject[];
    cancer: IReportDiagFourBranchObject[];
    newborn: IReportDiagFourBranchObject[];
  }>();

  const renderExpandableRow = (rowData: string[]) => {
    const tableData = innerData
      ? (innerData[
          rowData[0] as "accident" | "heart" | "cancer" | "newborn"
        ] as IReportDiagFourBranchObject[])
      : [];

    const formattedData = tableData.map((data) => ({
      cid: data.cid || " - ",
      fromHospName: data.fromHospName,
      ptname: data?.ptname || "-",
      age: data.age,
      sex: data.sex || "-",
      referPoint: data.referPoint,
      toHospName: data.toHospName,
      icd: `${data.icd} ${data.icdName}`,
      reason: data.reason,
      referDateTime: DateTimeToStrDatetime(data.referDateTime) || "",
      referoutEmergencyTypeName: data.referoutEmergencyTypeName || "-",
    }));

    const onDownload = ({ columns, values }: { columns: any; values: any }) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const json = reduce(
        values,
        (results: object[], val: { data: any }) => {
          const temp: any[] = [];
          forEach(val.data, (v: any, idx: string | number) => {
            temp[columns[idx].label] = v;
          });
          results.push(temp);
          return results;
        },
        []
      );

      const strStartDate = new Date(startDate).toLocaleDateString("en-GB");
      const strEndDate = new Date(endDate).toLocaleDateString("en-GB");

      const fileName = `${rowData[0].substr(2)}_${strStartDate}_${strEndDate}`;
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
    };

    const innerColumns = [
      {
        name: "cid",
        label: "เลขบัตรประชาชน",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "fromHospName",
        label: "สถานพยาบาลส่งต่อ",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "ptname",
        label: "ชื่อผู้ป่วย",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "age",
        label: "อายุ",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "sex",
        label: "เพศ",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "referDateTime",
        label: "วันเวลา",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "referoutEmergencyTypeName",
        label: "ความเร่งด่วน",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "referPoint",
        label: "จุดรีเฟอร์",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "toHospName",
        label: "สถานพยาบาลรับส่ง",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "icd",
        label: "โรคที่ส่งต่อ",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "reason",
        label: "สาเหตุที่ต้องส่งต่อ",
        options: {
          filter: false,
          sort: false,
        },
      },
    ];

    const innerOptions: MUIDataTableOptions = {
      responsive: "standard",
      filter: false,
      print: false,
      search: false,
      viewColumns: false,
      expandableRowsHeader: false,
      download: false,
      elevation: 0,
      count: formattedData.length,
      rowsPerPage: 5,
      rowsPerPageOptions: [5],
      selectableRows: "none",
      customToolbar: (data: any) => (
        <Tooltip title="Download Excel">
          <IconButton
            onClick={() => {
              onDownload({ columns: innerColumns, values: data.displayData });
            }}
            size="large"
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      ),
    };

    return (
      <TableRow className="inner-table">
        <TableCell colSpan={1} />
        <TableCell colSpan={rowData.length}>
          <MUIDataTable
            title=""
            data={formattedData}
            columns={innerColumns}
            options={innerOptions}
          />
        </TableCell>
      </TableRow>
    );
  };

  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "สาขา",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "amount",
      label: "จำนวน (คน)",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: false,
    expandableRows: true,
    expandableRowsHeader: false,
    selectableRows: "none",
    responsive: "standard",
    renderExpandableRow,
  };

  useEffect(() => {
    const referIn = referType === ReferralType.REFER_IN;
    const referOut = referType === ReferralType.REFER_OUT;

    if (referIn && !report.branchIn) {
      return;
    }
    if (referOut && !report.branchOut) {
      return;
    }

    const data = referIn ? report.branchIn : report.branchOut;
    const outerTableData = [
      {
        id: "accident",
        name: "1.อุบัติเหตุ",
        amount: data.branchAccident?.length,
      },
      { id: "heart", name: "2.หัวใจ", amount: data.branchHeart?.length },
      { id: "cancer", name: "3.มะเร็ง", amount: data.branchCancers?.length },
      {
        id: "newborn",
        name: "4.ทารกแรกเกิด",
        amount: data.branchNewBorn?.length,
      },
    ];

    const innerTableData = {
      accident: data.branchAccident,
      heart: data.branchHeart,
      cancer: data.branchCancers,
      newborn: data.branchNewBorn,
    };

    setOuterData(outerTableData);
    setInnerData(innerTableData);
  }, [report]);

  return (
    <div id="report-diag-4-branch-table" className="relative">
      {outerData && (
        <MUIDataTable
          title={`4 สาขาหลัก | ${ThaiDate(startDate)} - ${ThaiDate(endDate)}`}
          data={outerData}
          columns={columns}
          options={options}
        />
      )}
    </div>
  );
}
