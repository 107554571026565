import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TClinic } from "../../types/clinic";

const createClinic = async (token: string, data: TClinic) => {
  const url = getApiUrl();
  await axios.post(`${url}/v1/clinic`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export default createClinic;
