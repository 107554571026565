import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IReferral } from "@/types/refers";
import { fetchSampleReferral } from "../../api/sampleData/fetchSampleReferral";
interface IReferralTable {
  date: string;
  token: string;
}
function ReferralTable({ date, token }: IReferralTable) {
  const [referrals, setReferrals] = useState<IReferral[]>([]);
  const getReferralData = async () => {
    const referReps = await fetchSampleReferral(new Date(date), token);
    setReferrals(referReps);
  };
  useEffect(() => {
    if (date) {
      getReferralData();
    }
  }, [date]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">รหัสบัตรประชาชน</TableCell>
            <TableCell>ชื่อ นามสกุลผู้ป่วย</TableCell>
            <TableCell>จากโรงพยาบาล</TableCell>
            <TableCell>ถึงโรงพยาบาล</TableCell>
            <TableCell>แพทย์ผู้รักษา</TableCell>
            <TableCell>วันที่ส่งตัวผู้ป่วย</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referrals.length > 0 ? (
            referrals.map((referral) => (
              <TableRow
                key={referral._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{referral.data.cid}</TableCell>
                <TableCell component="th" scope="row">
                  {referral.data.ptname}
                </TableCell>
                <TableCell>{referral.data.fromHospCode}</TableCell>
                <TableCell>{referral.data.toHospCode}</TableCell>

                <TableCell component="th" scope="row">
                  {referral.data.doctorName}
                </TableCell>
                <TableCell align="left">
                  {new Date(referral.data.referDateTime).toLocaleDateString(
                    "th-TH"
                  )}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="center">ไม่มีข้อมูล</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ReferralTable;
