import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ChipInput from "material-ui-chip-input";
import LinearProgress from "@mui/material/LinearProgress";
import {
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// styles
import { Container, Form } from "./styles";

// apis
import AddCategories from "../../../api/Billings/AddCategories";
import EditCategories from "../../../api/Billings/EditCategories";
import {
  fetchItemRules,
  deleteItemRule,
} from "../../../api/Billings/BillingRules";

// const pdfTypeList = [
//   { value: 'UC', label: 'บัตรทอง' },
//   { value: 'SS', label: 'ประกันสังคม' },
//   { value: 'AL', label: 'ประกันสุขภาพแรงงานต่างด้าว' },
// ];

function EditCategory() {
  const [pttype, setPttype] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const [modal, setModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const { appData } = useSelector((state) => state);
  const { idToken, loginData } = appData;
  const RULE = () => ({
    id: uuid(),
    icode: "",
    price: "",
    capExclude: false,
  });
  const { control, handleSubmit, setValue, reset } = useForm();
  const [rules, setRules] = useState([RULE()]);
  const fetchData = async (body) => {
    const response = await fetchItemRules(body);
    setCategory(response.categories);
    setRules(response.itemRules);
    setPttype(response.categories.pttype);
  };
  useEffect(() => {
    if (id) {
      fetchData({ hospCode: loginData?.hospCode, idToken, categoryId: id });
    }
  }, [loginData?.hospCode, idToken, id]);
  useEffect(() => {
    if (category.name) reset(category);
  }, [category]);
  const onSubmit = async (data) => {
    setLoading(true);
    const body = {
      categories: {
        hospCode: loginData.hospCode,
        cap: data.cap,
        name: data.name,
        pttype,
      },
      idToken,
    };
    if (!category.name) {
      // create new category
      const bodyCreate = {
        ...body,
        itemRules: data.rule || [],
      };
      await AddCategories(bodyCreate);
    } else {
      const bodyUpdate = {
        ...body,
        updateItemRules: data.rule || [],
        categoryId: id,
        hospCode: loginData.hospCode,
      };
      await EditCategories(bodyUpdate);
    }
    setLoading(false);
    setRules([]);
    setPttype([]);
    history.push("/settings");
  };
  const onDeleteItemRules = (ruleItem, _id) => {
    if (_id) {
      // ถ้าเป็นข้อมูลที่อยู่ในดาต้าเบสจะมี _id และจะทำการยืนยันก่อนที่จะลบในดาต้าเบส
      setDeleteItem({ ...ruleItem, _id });
      setModal(true);
      return;
    }
    // เป็นไอเท็ม ที่สร้างขึ้นมาใหม่ยังไม่ได้บันทึกลงดาต้าเบส แล้วลบออก
    setRules((oldRules) =>
      oldRules?.filter((oldRule) => oldRule.id !== ruleItem.id)
    );
  };
  const onConfirmDeleteItemRules = async () => {
    await deleteItemRule({ idToken, itemId: deleteItem?._id });
    setRules((oldRules) =>
      oldRules?.filter((oldRule) => oldRule.id !== deleteItem.id)
    );
    setModal(false);
  };
  const onChangePage = (page = 1) => {
    const startIndex = page === 1 ? 0 : (page - 1) * 20;
    return rules.slice(startIndex, 20 * page);
  };
  const onAddItemRules = () => {
    setRules((oldRule) => [...oldRule, RULE()]);
    setPageNumber(Math.ceil((rules.length + 1) / 20));
  };
  return (
    <Container>
      <Form className="mx-4 mt-20 w-auto p-4">
        <div className="header my-3 mx-2">
          {id ? "แก้ไขกลุ่มสิทธิ์" : "กลุ่มสิทธิ์ใหม่"}
        </div>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            required
            as={TextField}
            control={control}
            name="name"
            rules={{ required: true }}
            className="input"
            label="ชื่อสิทธิ์หลัก"
            variant="outlined"
            disabled={loading}
            defaultValue={category?.name || ""}
          />
          <ChipInput
            name="pttype"
            className="input"
            label="กรอกรหัสสิทธิ์ pttype และกด enter"
            variant="outlined"
            value={pttype}
            onAdd={(chip) => setPttype((olds) => [...olds, chip])}
            onDelete={(chip) =>
              setPttype((olds) => olds?.filter((old) => old !== chip))
            }
            disabled={loading}
          />

          {!loading && (
            <Controller
              as={TextField}
              control={control}
              name="cap"
              className="input"
              label="ยอดไม่เกิน"
              variant="outlined"
              defaultValue={category?.cap || ""}
              disabled={loading}
              type="number"
            />
          )}

          {/* <Controller
            as={(
              <TextField
                required
                select
                variant='outlined'
                className='input'
                label='รูปแบบใบปะหน้า'
              />
            )}
            control={control}
            name='code'
            defaultValue={category?.code}
          >
            {pdfTypeList?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Controller> */}

          <Divider className="divider" />

          <h5>รายการยาและบริการ</h5>
          <div className="special">เก็บเกินได้</div>
          {onChangePage(pageNumber)?.map((rule, index) => (
            <div key={rule.id} className="billing" id={rule.id}>
              <IconButton size="large">
                <DeleteIcon
                  onClick={() =>
                    onDeleteItemRules(
                      { id: rule.id, icode: rule.icode },
                      rule._id
                    )
                  }
                />
              </IconButton>
              {rule?._id && (
                <Controller
                  required
                  as={TextField}
                  control={control}
                  name={`rule[${index}]._id`}
                  rules={{ required: true }}
                  className="input"
                  label="รหัส icode"
                  variant="outlined"
                  size="small"
                  defaultValue={rule?._id}
                  hidden={rule?._id}
                />
              )}

              <div className="billing-item">
                <Controller
                  required
                  as={TextField}
                  control={control}
                  name={`rule[${index}].icode`}
                  rules={{ required: true }}
                  className="input"
                  label="รหัส icode"
                  variant="outlined"
                  size="small"
                  defaultValue={rule?.icode}
                />
              </div>
              <div className="billing-item">
                <Controller
                  as={TextField}
                  control={control}
                  name={`rule[${index}].price`}
                  type="number"
                  className="input"
                  label="ราคา"
                  variant="outlined"
                  size="small"
                  defaultValue={rule?.price}
                />
              </div>
              <FormControlLabel
                label={setValue}
                control={
                  <Controller
                    name={`rule[${index}].capExclude`}
                    render={() => (
                      <Checkbox
                        defaultChecked={rule ? rule.capExclude : false}
                        onChange={(e) => {
                          setValue(
                            `rule[${index}].capExclude`,
                            e.target.checked
                          );
                        }}
                        color="primary"
                      />
                    )}
                    defaultValue={rule ? rule.capExclude : false}
                    control={control}
                  />
                }
                className="checkbox"
                key={`rule[${rule.id}].capExclude`}
              />
            </div>
          ))}
          <Button onClick={() => onAddItemRules()} className="add-rule">
            + เพิ่มรายการยาและบริการ
          </Button>
          <div className="action">
            <Button
              disabled={pageNumber <= 1}
              color="primary"
              variant="text"
              onClick={() => setPageNumber((page) => page - 1)}
            >
              <ArrowBackIos />
            </Button>
            <span style={{ margin: 4 }}>{pageNumber}</span>
            <Button
              disabled={
                onChangePage(pageNumber).length % 20 !== 0 ||
                onChangePage(pageNumber).length === 0
              }
              color="primary"
              variant="text"
              onClick={() => setPageNumber((page) => page + 1)}
            >
              <ArrowForwardIos />
            </Button>
          </div>
          <div className="action">
            <Button
              disabled={loading}
              className="!mr-2 rounded !text-sky-500"
              variant="outlined"
              onClick={() => history.push("/settings")}
            >
              ยกเลิก
            </Button>
            <Button
              disabled={loading}
              type="submit"
              className="rounded !bg-sky-500 text-white"
              variant="contained"
            >
              ยืนยัน
            </Button>
          </div>
        </form>
        {loading && <LinearProgress />}
      </Form>
      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle>
          ลบรายการยาและบริการ รหัส icode ที่ {deleteItem?.icode}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>ยืนยันที่จะลบ ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setModal(false)}
            color="primary"
          >
            ยกเลิก
          </Button>
          <Button
            disabled={loading}
            onClick={() => onConfirmDeleteItemRules()}
            color="primary"
          >
            ยืนยัน
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
    </Container>
  );
}

export default EditCategory;
