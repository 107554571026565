import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default ({
  idToken,
  roleId,
  roleName,
}: {
  idToken: string;
  roleId: string;
  roleName: string;
}) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/roles`;

  const body = {
    roleId,
    roleName,
  };

  return axios
    .put(url, body, { headers: { Authorization: `Bearer ${idToken}` } })
    .then((res) => {
      dispatch = {
        permissionManagement: {
          role: res.data,
        },
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
