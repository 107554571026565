import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

interface IXrayItem {
  xrayItemsName: string;
  requestDateTime: string;
  reportDateTime: string;
  reportText: string;
}
function XrayItem({
  xrayItemsName,
  requestDateTime,
  reportDateTime,
  reportText,
}: IXrayItem) {
  return (
    <Box className="bg-green-50 rounded-lg mx-2 my-2 p-4 flex flex-col justify-between gap-2">
      <Box>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "12px", color: "#1D7852" }}
        >
          {xrayItemsName}
        </Typography>
        <Tooltip title={reportText || "ไม่ระบุ"}>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#1D7852",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              textOverflow: "ellipsis",
              maxHeight: "4.5em",
              lineHeight: "1.5em",
            }}
          >{`คำอธิบาย Xray: ${reportText || "ไม่ระบุ"}`}</Typography>
        </Tooltip>
      </Box>
      <Box>
        <Typography
          sx={{ fontSize: "10px", color: "#1D7852" }}
        >{`วันที่ขอ Xray: ${dateToLocalDateString(
          requestDateTime
        )}`}</Typography>
        <Typography
          sx={{ fontSize: "10px", color: "#1D7852" }}
        >{`วันที่เอกสาร Xray ออก: ${
          dateToLocalDateString(reportDateTime) || "ไม่ระบุ"
        }`}</Typography>
      </Box>
    </Box>
  );
}

export default XrayItem;
