import { APPDATA } from "../../constants/appData";
import fetchRoles from "../../api/PermissionManagement/fetchRoles.service";

export default ({
  idToken,
  page,
  limit,
}: {
  idToken: string;
  page: number;
  limit?: number;
}) => ({
  type: APPDATA,
  payload: fetchRoles({ idToken, page, limit }),
});
