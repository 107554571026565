import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import uploadFileToStorage from "../../../../api/Refers/ReferOut/AttachFileToStorage";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { RootState } from "@/reducers";
import { useSelector } from "react-redux";

interface IReferralAttachDialog {
  open: boolean;
  onClose(): void;
  referDocId: string;
}
interface ISourceAttachment {
  contentType: string;
  base64Data: string;
  description: string;
  file: File;
}
interface IResponseAttactment {
  status: AlertColor;
  message: string;
}

const ReferralStorageAttachDialog = ({
  open,
  onClose,
  referDocId,
}: IReferralAttachDialog) => {
  const { appData } = useSelector((state: RootState) => state);
  const { idToken } = appData;
  const [sourceAttachments, setSourceAttachments] = useState<
    ISourceAttachment[]
  >([]);
  const [description, setDescription] = useState<string[]>([]);
  const [response, setResponse] = useState<IResponseAttactment | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const filetypes = /image/;
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    setOpenSnackbar(true);

    if (sourceAttachments.length === 0) {
      setResponse({
        status: "warning",
        message: "กรุณาแนบไฟล์",
      });
      return;
    }

    const sourceMapDescription = sourceAttachments.map((attachment, index) => ({
      ...attachment,
      description: description[index],
    }));
    const resp: IResponseAttactment = await uploadFileToStorage(
      sourceMapDescription,
      referDocId,
      idToken
    );
    setResponse(resp);
    if (resp.status === "success") {
      setSourceAttachments([]);
      setDescription([]);
      setTimeout(() => {
        onClose();
        setOpenSnackbar(false);
      }, 1000);
    }
  };
  const onSelectImage = async (files: FileList | null) => {
    if (files) {
      const sources: ISourceAttachment[] = [];
      for (let index = 0; index < files.length; index++) {
        const file: File = files[index];
        const convertBase64 = new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
        const result: any = await convertBase64;
        const replitResult = result.split(";");
        if (replitResult.length > 0) {
          const contentType = replitResult[0].replace("data:", "");
          const base64Data = result.replace("data:", "").replace(/^.+,/, "");
          const attachment: ISourceAttachment = {
            contentType,
            base64Data,
            description: "",
            file,
          };
          sources.push(attachment);
        }
      }
      setSourceAttachments((prevSources) => [...prevSources, ...sources]);
      setDescription((prevDescription) => [...prevDescription, ""]);
    }
  };

  const onDeleteImage = (indexRemove: number) => {
    setSourceAttachments((prevSources) =>
      prevSources.filter((_, index) => index !== indexRemove)
    );
    setDescription((prevDescription) =>
      prevDescription.filter((_, index) => index !== indexRemove)
    );
  };
  const onChangeDescription = (value: string, index: number) => {
    setDescription((prevDescription) => {
      prevDescription[index] = value;
      return [...prevDescription];
    });
  };
  const base64ToBlob = (base64: string, contentType: string) => {
    const binary = atob(base64.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view.buffer], { type: contentType });
    const url = URL.createObjectURL(blob);

    return url;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="refer-dialog-attach"
    >
      <DialogTitle id="alert-dialog-title">
        <strong>แนบไฟล์</strong>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <div>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md w-full h-auto">
              <div className="space-y-1 text-center">
                <div className="grid grid-cols-2">
                  {sourceAttachments?.length > 0 ? (
                    sourceAttachments.map((attachment, index) => (
                      <div className="inline-block mx-1 my-2" key={index}>
                        {filetypes.test(attachment.contentType) ? (
                          <img
                            src={base64ToBlob(
                              attachment.base64Data,
                              attachment.contentType
                            )}
                            key={attachment.base64Data}
                            alt={attachment.file.name}
                            className="object-contain w-full h-20"
                          />
                        ) : (
                          <div className="flex justify-center items-center h-20 border-2 border-gray-100 rounded-md bg-gray-100">
                            {attachment.file.name}
                          </div>
                        )}
                        <textarea
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-2"
                          id="description"
                          rows={3}
                          value={description[index]}
                          onChange={(e) =>
                            onChangeDescription(e.target.value, index)
                          }
                          placeholder="อธิบายเพิ่มเติม"
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          fullWidth
                          onClick={() => onDeleteImage(index)}
                        >
                          {attachment.contentType === "application/pdf"
                            ? "ลบไฟล์ PDF"
                            : "ลบรูปภาพ"}
                        </Button>
                      </div>
                    ))
                  ) : (
                    <div className="col-span-2">
                      <svg
                        className=" mx-auto h-12 w-full text-gray-400 "
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  )}
                </div>

                <div className="flex text-sm text-gray-600 justify-center">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer mt-4 bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>แนบไฟล์</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      onChange={(e) => onSelectImage(e.target.files)}
                      multiple
                      // accept=".pdf, image/jpeg, image/png, image/jpg"
                    />
                  </label>
                  <p className="pl-2 mt-4">การรักษา และ คำอธิบายเพิ่มเติม</p>
                </div>
                <p className="text-xs text-gray-500">JPEG, PDF up to 10MB</p>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button type="submit" color="primary" variant="contained">
            บันทึกไฟล์
          </Button>
          <Button
            onClick={() => onClose()}
            color="secondary"
            variant="contained"
            autoFocus
          >
            ยกเลิก
          </Button>
        </DialogActions>
        {openSnackbar && response && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert
              onClose={() => setOpenSnackbar(false)}
              severity={response.status || "warning"}
              sx={{ width: "100%" }}
            >
              {response.message}
            </Alert>
          </Snackbar>
        )}
      </form>
    </Dialog>
  );
};

export default ReferralStorageAttachDialog;
