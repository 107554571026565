import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { IReasonRefer } from "@/types/reasonRefer";

const updateReasonRefer = async (token: string, data: IReasonRefer) => {
  try {
    const url = `${getApiUrl()}/v1/reason/${data._id}`;
    const response = await axios.put(url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return;
  }
};

export default updateReasonRefer;
