import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";
import ListboxComponent from "../../Utility/ListBoxComponents";
import { IDoctor } from "../../../types/clinic";

type TDropdownDoctor = {
  doctors: IDoctor[];
  onSelectedDoctor(doctor: IDoctor): void;
};
function DropdownDoctor({ doctors, onSelectedDoctor }: TDropdownDoctor) {
  const handleOptionLabel = (option: IDoctor) => {
    const fullNameTarget = {
      title: "",
      firstName: "",
      lastName: "",
    };
    const { title, firstName, lastName } = Object.assign(
      {},
      fullNameTarget,
      option.fullName
    );
    return `${title} ${firstName} ${lastName}`;
  };
  return (
    <FormControl className="bg-white flex-auto w-full">
      <Autocomplete
        disableListWrap
        ListboxComponent={ListboxComponent}
        options={doctors}
        onChange={(option, value) => value && onSelectedDoctor(value)}
        getOptionLabel={(option) => handleOptionLabel(option)}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            fullWidth
            label="แพทย์"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default DropdownDoctor;
