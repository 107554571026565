import axios from "axios";

// constants
import { PENDING } from "../constants/referralStatus";

// functions
import { getApiUrl } from "../functions/Commons";

export default (dataRequest) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = "Bearer ".concat(dataRequest.idToken);
      const { hn, hospCode, cid, limit } = dataRequest;
      axios
        .get(
          `${getApiUrl()}/v1/patients/visits/details?cid=${cid}&limit=${limit}&hospCode=${hospCode}&hn=${hn}`,
          {
            headers: { Authorization: AuthStr },
          }
        )
        .then((res) => {
          const visits = res.data.result.visits.map((visit) => ({
            ...visit,
            referrals: visit.referrals.map((referral) =>
              referral.status ? referral : { ...referral, status: PENDING }
            ),
          }));

          if (res.status === 200) {
            const data = {
              emrData: {
                patients: res.data.result.patient,
                visits,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
          const data = {
            emrData: {
              patients: res.data.result.patient,
              visits,
            },
            FetchingStatus: false,
          };

          return resolve(data);
        })
        .catch((err) => {
          console.log("AXIOS ERROR: error !");
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
