import React from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import { IPatient } from "@/types/patient";
import ImagePatientMale from "../../../../assets/images/hospital-patient.png";
import ImagePatientFemale from "../../../../assets/images/hospital-patient-f.png";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PersonIcon from "@mui/icons-material/Person";
import { SEX } from "../../../../constants/commons";

function PatientInfo(props: IPatient) {
  const { data, cid } = props;

  const { t, i18n } = useTranslation("patientemr");

  const checkMale = /นาย|เด็กชาย|ด.ช.|male/;
  const checkFemale = /นาง|นางสาว|เด็กหญิง|ด.ญ.|น.ส.|female/;

  const convertDate = (date: string) => {
    return new Date(date).toLocaleDateString(
      i18n.language === "en" ? "en-EN" : "th-TH",
      {
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );
  };

  const calculateAge = (dob: string) => {
    if (!dob || new Date(dob).toString() === "Invalid Date") return "";
    const age =
      new Date().getMonth() > new Date(dob).getMonth()
        ? new Date().getFullYear() - new Date(dob).getFullYear()
        : new Date().getFullYear() - new Date(dob).getFullYear() - 1;
    return age;
  };

  const formatSex = ({ sex = "", title }: { sex?: string; title: string }) => {
    const female = ["2", "SX2", "20"];
    const male = ["1", "SX1", "10"];
    if (male.includes(sex) || checkMale.test(title)) return SEX.MALE;
    if (female.includes(sex) || checkFemale.test(title)) return SEX.FEMALE;
    return "";
  };

  const patientFullName = () => {
    if (!data) return "";

    const title = data.fullname?.title || "";
    const name = data.fullname?.firstName || "-";
    const lastName = data.fullname?.lastName || "-";

    return `${title} ${name} ${lastName}`;
  };

  const PatientContactForIpad = () => {
    return (
      <Grid item xs sx={{ display: { md: "none", xs: "block" } }}>
        <Box display={"flex"} gap={1} color={"text.primary"} marginTop={0.5}>
          <LocationOnIcon fontSize="inherit" />
          <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
            ที่อยู่
          </Typography>
          <Typography fontSize={12}>
            {data?.address?.text || "ไม่ระบุ"}
          </Typography>
        </Box>

        <Box display={"flex"} gap={1} color={"text.primary"} marginTop={0.5}>
          <PhoneIphoneIcon fontSize="inherit" />
          <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
            {data?.contactInfo?.mobileNumber || "ไม่ระบุ"}
          </Typography>
        </Box>
      </Grid>
    );
  };

  const PatientEmergencyContactForIpad = () => {
    return (
      <Grid
        item
        xs
        sx={{
          display: { md: "none", xs: "block" },
          "@media print": { marginRight: 2 },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          border={1}
          borderRadius={3}
          padding={1}
          marginTop={1}
        >
          <Typography
            variant="caption"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            {t("patient_info.emergency_contact")}
          </Typography>

          <Box display={"flex"} gap={1} color={"text.primary"} marginTop={0.5}>
            <PersonIcon fontSize="inherit" />
            <Typography variant="caption" color="text.primary">
              ชื่อ
            </Typography>
            <Typography variant="caption" color="text.primary">
              {`${data?.emergencyInfo?.familyName || "ไม่ระบุ"}`}
            </Typography>
          </Box>

          <Box display={"flex"} gap={1} color={"text.primary"} marginTop={0.5}>
            <LocationOnIcon fontSize="inherit" />
            <Typography fontSize={12}>ที่อยู่</Typography>
            <Typography fontSize={12}>
              {data?.emergencyInfo?.address?.text || "ไม่ระบุ"}
            </Typography>
          </Box>

          <Box display={"flex"} gap={1} color={"text.primary"} marginTop={0.5}>
            <PhoneIphoneIcon fontSize="inherit" />
            <Typography fontSize={12}>เบอร์ติดต่อ</Typography>
            <Typography fontSize={12}>
              {data?.emergencyInfo?.mobileNumber || "ไม่ระบุ"}
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  };

  const ContactForDesktop = () => {
    return (
      <Grid
        item
        xs
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{ display: { md: "block", xs: "none" } }}
      >
        <Box display={"flex"} gap={1} color={"text.primary"}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            ที่อยู่
          </Typography>
          <Typography variant="body2">
            {data?.address?.text || "ไม่ระบุ"}
          </Typography>
        </Box>

        <Box display={"flex"} gap={1} color={"text.primary"}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            เบอร์ติดต่อ
          </Typography>
          <Typography variant="body2">
            {data?.contactInfo?.mobileNumber || "ไม่ระบุ"}
          </Typography>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          border={1}
          borderRadius={3}
          padding={1}
          marginTop={1}
        >
          <Typography
            variant="caption"
            color="text.primary"
            sx={{ fontWeight: "bold" }}
          >
            {t("patient_info.emergency_contact")}
          </Typography>

          <Box display={"flex"} gap={1} color={"text.primary"}>
            <PersonIcon fontSize="inherit" />
            <Typography variant="caption" color="text.primary">
              ชื่อ
            </Typography>
            <Typography variant="caption" color="text.primary">
              {`${data?.emergencyInfo?.familyName || "ไม่ระบุ"}`}
            </Typography>
          </Box>

          <Box display={"flex"} gap={1} color={"text.primary"}>
            <LocationOnIcon fontSize="inherit" />
            <Typography variant="caption">ที่อยู่</Typography>
            <Typography variant="caption">
              {data?.emergencyInfo?.address?.text || "ไม่ระบุ"}
            </Typography>
          </Box>

          <Box display={"flex"} gap={1} color={"text.primary"}>
            <PhoneIphoneIcon fontSize="inherit" />
            <Typography variant="caption">เบอร์ติดต่อ</Typography>
            <Typography variant="caption">
              {data?.emergencyInfo?.mobileNumber || "ไม่ระบุ"}
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Box className="flex flex-col w-full bg-white rounded-2xl border-2 border-gray-200">
      <Grid container paddingY={2} spacing={2}>
        <Grid item xs="auto">
          <Box
            width="100%"
            maxWidth="8rem"
            sx={{
              border: "1px solid #D4D9DF",
              borderRadius: "0.625rem",
              margin: "0rem 1rem",
            }}
          >
            <img
              alt="patient_image"
              src={
                formatSex({
                  sex: data?.sex,
                  title: data?.fullname?.title,
                }) === SEX.MALE
                  ? ImagePatientMale
                  : ImagePatientFemale
              }
            />
          </Box>
        </Grid>

        <Grid item xs>
          <Typography variant="h6" fontWeight={"bold"} color={"text.primary"}>
            {patientFullName()}
          </Typography>

          <Grid item display={"flex"} gap={2}>
            <Grid
              item
              xs
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              gap={3}
            >
              <Box>
                <Box display={"flex"} gap={1}>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    sx={{ fontWeight: "bold" }}
                  >
                    CID
                  </Typography>
                  <Typography variant="subtitle1" color="text.primary">
                    {cid}
                  </Typography>
                </Box>

                <Box display={"flex"}>
                  <Typography variant="body2" color="text.primary">
                    {formatSex({
                      sex: data?.sex,
                      title: data?.fullname?.title,
                    }) === SEX.MALE
                      ? t("patient_info.male")
                      : t("patient_info.female")}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "0.8rem" }}
                    color="text.primary"
                  >{`, ${calculateAge(data?.DOB)} ${t(
                    "patient_info.years_old"
                  )}`}</Typography>
                </Box>

                <PatientContactForIpad />
              </Box>

              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} gap={1}>
                  <Typography
                    variant="body2"
                    color={"custom.warning"}
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("patient_info.persis_disease")}
                  </Typography>
                  <Typography variant="body2" color={"custom.warning"}>
                    {data?.condition?.ccPersistDisease || "ไม่มี/ไม่ระบุ"}
                  </Typography>
                </Box>

                <Box display={"flex"} gap={1}>
                  <Typography
                    variant="body2"
                    color="custom.warning"
                    sx={{ fontWeight: "bold" }}
                  >
                    {t("patient_info.drug_allergy")}
                  </Typography>
                  <Typography variant="body2" color="custom.warning">
                    {data?.drugAllergies && data?.drugAllergies?.length > 0
                      ? data?.drugAllergies?.map((allergy, index) => (
                          <Typography key={index} variant="caption">
                            {allergy?.genericname?.length > 90
                              ? ` ${allergy?.genericname.slice(0, 90)}...`
                              : ` ${allergy?.genericname}`}
                          </Typography>
                        ))
                      : "ไม่มี/ไม่ระบุ"}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs md="auto" color="text.primary">
              <Box display={"flex"} gap={1}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {t("patient_info.birthday")}
                </Typography>
                <Typography variant="body2">
                  {convertDate(data?.DOB) || "ไม่ระบุ"}
                </Typography>
              </Box>

              <Box display={"flex"} gap={1}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {t("patient_info.race")}
                </Typography>
                <Typography variant="body2">
                  {data?.citizenship || "ไม่ระบุ"}
                </Typography>
              </Box>

              <Box display={"flex"} gap={1}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {t("patient_info.nationality")}
                </Typography>
                <Typography variant="body2">
                  {data?.nationality || "ไม่ระบุ"}
                </Typography>
              </Box>

              <Box display={"flex"} gap={1}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {t("patient_info.religion")}
                </Typography>
                <Typography variant="body2">
                  {data?.religion || "ไม่ระบุ"}
                </Typography>
              </Box>

              <PatientEmergencyContactForIpad />
            </Grid>

            <ContactForDesktop />
          </Grid>
        </Grid>

        <Grid
          item
          xs="auto"
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"flex-start"}
          marginTop={-1}
          marginRight={1}
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <IconButton onClick={props.handlePrint}>
            <DownloadIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PatientInfo;
