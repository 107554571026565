import { Box, Typography } from "@mui/material";
import React from "react";
import { IDiagnosis } from "@/types/visit";
import DiagnosisItem from "./DiagnosisItem";

interface IDiagnosisProps {
  diagnosis: IDiagnosis[];
}
function Diagnosis({ diagnosis }: IDiagnosisProps) {
  const diagnosisItem = (diagnosies: IDiagnosis[]) =>
    diagnosies.map((diag, index: number) => (
      <DiagnosisItem key={index} index={index + 1} diagItem={diag} />
    ));
  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box className="border-b-2 border-gray-100 h-10 px-6 py-1">
        <Typography variant="h6" color="#58595B">
          Diagnosis
        </Typography>
      </Box>
      <div className="grid grid-cols-1 px-6 py-3">
        {diagnosisItem(diagnosis)}
      </div>
    </Box>
  );
}

export default Diagnosis;
