import axios from "axios";

// functions
import { getApiUrl } from "../functions/Commons";

export default (dataRequest) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = "Bearer ".concat(dataRequest.idToken);

      axios
        .put(`${getApiUrl()}/v1/refer/accept`, dataRequest, {
          headers: { Authorization: AuthStr },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = {
              referAccept: {
                referAcceptData: res.data.opResult,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
          const data = {
            referAccept: {
              referAcceptData: res.data.opResult,
            },
            FetchingStatus: false,
          };

          return resolve(data);
        })
        .catch((err) => {
          console.log("AXIOS ERROR: error !");
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
