import { emergencyText } from "../../functions/transform";
import React, { useMemo } from "react";

interface IEmergencyType {
  emergencyTypeName: string;
}

const emergencyColor = (emergencyTypeName: string) => {
  switch (emergencyTypeName) {
    case "Life threatening":
    case "Unconcious":
      return "border-red-500 text-red-500 bg-red-200";
    case "Emergency":
      return "border-pink-500 text-pink-500 bg-pink-200";
    case "Urgent":
      return "border-yellow-500 text-yellow-500 bg-yellow-200";
    case "Acute":
      return "border-green-500 text-green-500 bg-green-200";
    case "":
    case "Non acute":
      return "border-gray-500 text-gray-500 bg-gray-200";
    default:
      return "";
  }
};

export default function EmergencyBadge({ emergencyTypeName }: IEmergencyType) {
  const hostname = window.location.hostname;
  const text = useMemo(
    () => emergencyText(emergencyTypeName, hostname).text,
    [emergencyTypeName, hostname]
  );
  const color = useMemo(
    () => emergencyColor(emergencyTypeName),
    [emergencyTypeName]
  );

  if (!emergencyTypeName) {
    return <div />;
  }

  return (
    <div
      className={`self-center px-1 py-2 rounded !border text-xs font-bold ${color}`}
    >
      {text}
    </div>
  );
}
