import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { ThaiDate } from "../../functions/FuncDateTimes";
import { Box, Typography } from "@mui/material";
import { calculateReportHeight } from "../../functions/report";
import { IReportBarStackedSeries, IReportResponse } from "@/types/report";
import { ReferralType } from "../../types/refers";

interface IReport4Branch {
  report: IReportResponse;
  referType: ReferralType;
  startDate: Date;
  endDate: Date;
}

export default function ReportDiag4Branch({
  report,
  referType,
  startDate,
  endDate,
}: IReport4Branch) {
  const [categories] = useState<string[]>([
    "1. อุบัติเหตุ",
    "2. หัวใจ",
    "3. มะเร็ง",
    "4. ทารกแรกเกิด",
  ]);
  const [series, setSeries] = useState<IReportBarStackedSeries[]>();

  const options: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    title: {
      text: `4 สาขาหลัก | ${ThaiDate(startDate)} - ${ThaiDate(endDate)}`,
    },
    tooltip: {
      y: {
        formatter(val: any) {
          return `${val} ครั้ง`;
        },
      },
    },
    xaxis: {
      categories,
    },
    legend: {
      position: "top",
    },
  };

  const formatSeries = () => {
    const referIn = referType === ReferralType.REFER_IN;
    const referOut = referType === ReferralType.REFER_OUT;

    if (referIn && !report.fourBranchIn) {
      return;
    }
    if (referOut && !report.fourBranchOut) {
      return;
    }

    const fourBranch = referIn ? report.fourBranchIn : report.fourBranchOut;
    setSeries(fourBranch);
  };

  useEffect(() => {
    formatSeries();
  }, []);

  const reportHeight = calculateReportHeight({
    categoriesLength: categories.length,
  });
  const adjustedHeight = reportHeight > 285 ? reportHeight : 285;

  return categories && categories.length > 0 && series && series.length > 0 ? (
    <Box>
      <ApexCharts
        options={options}
        series={series}
        type="bar"
        height={adjustedHeight}
      />
    </Box>
  ) : (
    <Box
      className="border"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      minHeight={300}
    >
      <Typography variant="h6">ไม่พบรายงาน 4 สาขาหลัก</Typography>
    </Box>
  );
}
