import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import fetchLoginHistory from "../../actions/fetchLoginHistory.action";
import { RootState } from "@/reducers";
import { DateTimeToStrDatetime } from "../../functions/FuncDateTimes";
import { deepOrange, blueGrey } from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import Chart from "react-apexcharts";
import { DateRangePicker } from "@mui/lab";

interface ILoginHistory {
  username: string;
  roleId: string;
  hospCode: string;
  loginTime: string;
  role?: { role: string };
}

interface IGroupedDataByHospCode {
  [hospCode: string]: {
    [role: string]: {
      [username: string]: ILoginHistory[];
    };
  };
}

const LoginHistory = () => {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);
  const { idToken, loginHistory } = appData;

  const [filteredLoginHistory, setFilteredLoginHistory] = useState<
    ILoginHistory[]
  >([]);
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([null, null]);
  const [usernameFilter, setUsernameFilter] = useState<string>("");
  const [roleFilter, setRoleFilter] = useState<string>("");

  useEffect(() => {
    dispatch(fetchLoginHistory(idToken));
  }, [dispatch, idToken]);

  useEffect(() => {
    filterLoginHistory();
  }, [loginHistory, dateRange, usernameFilter, roleFilter]);

  const filterLoginHistory = () => {
    let filtered = loginHistory || [];

    if (dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((entry: ILoginHistory) => {
        const loginDate = dayjs(entry.loginTime);
        return (
          loginDate.isAfter(dateRange[0]) && loginDate.isBefore(dateRange[1])
        );
      });
    }

    if (usernameFilter) {
      filtered = filtered.filter((entry: ILoginHistory) =>
        entry.username.toLowerCase().includes(usernameFilter.toLowerCase())
      );
    }

    if (roleFilter) {
      filtered = filtered.filter(
        (entry: ILoginHistory) => entry.role?.role === roleFilter
      );
    }

    setFilteredLoginHistory(filtered);
  };

  const getAvailableRoles = (data: ILoginHistory[]): string[] => {
    const roles = data
      .map((entry) => entry.role?.role)
      .filter((role): role is string => !!role);
    return Array.from(new Set(roles));
  };

  const availableRoles = loginHistory ? getAvailableRoles(loginHistory) : [];

  const handleReset = () => {
    setDateRange([null, null]);
    setUsernameFilter("");
    setRoleFilter("");
    setFilteredLoginHistory(loginHistory);
  };

  const groupByHospCodeRoleAndUser = (
    data: ILoginHistory[]
  ): IGroupedDataByHospCode => {
    if (!data || !data.length) return {};

    return data.reduce((acc: IGroupedDataByHospCode, entry: ILoginHistory) => {
      const hospCode = entry.hospCode;
      const role = entry.role?.role;
      const username = entry.username;

      if (!role || !hospCode || !username) return acc;

      if (!acc[hospCode]) {
        acc[hospCode] = {};
      }

      if (!acc[hospCode][role]) {
        acc[hospCode][role] = {};
      }

      if (!acc[hospCode][role][username]) {
        acc[hospCode][role][username] = [];
      }

      acc[hospCode][role][username].push(entry);

      return acc;
    }, {});
  };

  const groupedData = groupByHospCodeRoleAndUser(filteredLoginHistory);

  const chartSeries = Object.entries(groupedData).flatMap(
    ([hospCode, roles]) => {
      return Object.entries(roles).flatMap(([role, users]) => {
        return Object.entries(users).map(([username, entries]) => ({
          name: `${username} (${role} - ${hospCode})`,
          data: entries.map((entry: ILoginHistory) => ({
            x: dayjs(entry.loginTime)
              .add(7, "hour")
              .format("YYYY-MM-DD HH:mm:ss"),
            y: 1,
          })),
        }));
      });
    }
  );

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Logins",
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    tooltip: {
      x: {
        formatter: function (value) {
          return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Box sx={{ padding: 3, marginTop: 8 }}>
      <Typography variant="h4" gutterBottom>
        Login History Dashboard
      </Typography>

      <Box
        mb={3}
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" gap={1} alignItems="center">
          <TextField
            label="Username"
            value={usernameFilter}
            onChange={(e) => setUsernameFilter(e.target.value)}
            variant="outlined"
            size="small"
          />

          <FormControl variant="outlined" size="small">
            <Select
              value={roleFilter}
              onChange={(e) => setRoleFilter(e.target.value as string)}
              displayEmpty
            >
              <MenuItem value="">
                <em>All Roles</em>
              </MenuItem>
              {availableRoles.map((role, idx) => (
                <MenuItem key={idx} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DateRangePicker
            startText="Start Date"
            endText="End Date"
            value={dateRange}
            onChange={(newValue) => setDateRange(newValue)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} size="small" />
                <Box sx={{ mx: 1 }}> to </Box>
                <TextField {...endProps} size="small" />
              </>
            )}
          />
        </Box>

        <Box>
          <IconButton color="primary" onClick={filterLoginHistory}>
            <SearchIcon />
          </IconButton>
          <IconButton color="secondary" onClick={handleReset}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      <Box mb={5}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={350}
        />
      </Box>

      <Grid container spacing={3}>
        {Object.entries(groupedData).map(([hospCode, roles], index) => (
          <Grid item xs={12} key={index}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" color={blueGrey[700]}>
                  Hospital Code: {hospCode}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(roles).map(([role, users]) => (
                  <Accordion key={role} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6" color={blueGrey[600]}>
                        Role: {role}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2} alignItems="stretch">
                        {Object.entries(users).map(
                          ([username, entries], index) => (
                            <Grid item xs={12} sm={6} md={4} key={username}>
                              <Card
                                variant="outlined"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  height: "100%",
                                }}
                              >
                                <CardContent sx={{ flexGrow: 1 }}>
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                  >
                                    <Avatar
                                      sx={{ bgcolor: deepOrange[500] }}
                                      alt={username}
                                    >
                                      {index + 1}
                                    </Avatar>
                                    <Box>
                                      <Typography variant="body1">
                                        Username: {username}
                                      </Typography>
                                      <Typography variant="body2">
                                        Logins: {entries.length}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Divider sx={{ my: 1 }} />

                                  {/* Container for timestamps with scroll */}
                                  <Box
                                    sx={{
                                      overflowY: "auto",
                                      maxHeight: "190px", // Adjust as needed
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {entries.map((entry, idx) => (
                                      <Typography
                                        key={idx}
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {idx + 1}.{" "}
                                        {DateTimeToStrDatetime(entry.loginTime)}
                                      </Typography>
                                    ))}
                                  </Box>
                                </CardContent>
                              </Card>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LoginHistory;
