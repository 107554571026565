import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TCreateSchedule } from "../../types/schedule";

const createSchedule = async (token: string, data: TCreateSchedule) => {
  const url = getApiUrl();
  await axios.post(`${url}/v1/schedule`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export default createSchedule;
