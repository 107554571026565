import React, { useEffect, useState } from "react";
import CountBox from "./CountBox";
import { ReferralStatus, ReferralType } from "../../types/refers";
import { IReportResponse } from "../../types/report";
import _ from "lodash";
import { getReportFromReferType } from "../../functions/report";

interface ICountBar {
  referType: ReferralType;
  report: IReportResponse;
}

export default function CountBar({ referType, report }: ICountBar) {
  const [nTotal, setNTotal] = useState(0);
  const [nPending, setNPending] = useState(0);
  const [nAccepted, setNAccepted] = useState(0);
  const [nRejected, setNRejected] = useState(0);

  const groupByEncounter = () => {
    const encounterReport = getReportFromReferType({ report, referType });

    const allItems = _.flatMap(encounterReport, (hospital) => hospital);
    const totalSum = _.sumBy(allItems, "total");
    const pendingTotalSum = _.sumBy(
      _.filter(allItems, { label: ReferralStatus.PENDING }),
      "total"
    );
    const acceptedTotalSum = _.sumBy(
      _.filter(allItems, { label: ReferralStatus.COMPLETE }),
      "total"
    );
    const rejectedTotalSum = _.sumBy(
      _.filter(allItems, { label: ReferralStatus.REJECTED }),
      "total"
    );

    setNTotal(totalSum);
    setNPending(pendingTotalSum);
    setNAccepted(acceptedTotalSum);
    setNRejected(rejectedTotalSum);
  };

  useEffect(() => {
    groupByEncounter();
  }, []);

  return (
    <div className="bg-transparent shadow-none grid grid-cols-4 gap-4">
      <div>
        <CountBox encounterType={referType} nTimes={nTotal} />
      </div>
      <div>
        <CountBox encounterType="PENDING" nTimes={nPending} />
      </div>
      <div>
        <CountBox encounterType="ACCEPTED" nTimes={nAccepted} />
      </div>
      <div>
        <CountBox encounterType="REJECTED" nTimes={nRejected} />
      </div>
    </div>
  );
}
