import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export default async ({ otp, idToken }: { otp: string; idToken: string }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/user/verifyTwoFactor`;
  const body = { otp };

  return axios
    .post(url, body, { headers: { Authorization: `Bearer ${idToken}` } })
    .then((res) => {
      dispatch = {
        loginStatus: res.status === 200 ? true : false,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
