import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  Box,
  DialogTitle,
  Card,
  CardContent,
  CardActions,
  ListItemText,
  Typography,
} from "@mui/material";
import fetchUserHospital from "../../../api/SettingAppointment/fetchUserHospital";
import { IDoctor } from "../../../types/clinic";
import DropdownDoctor from "./DropdownDoctor";

type IDialogAddDoctor = {
  token: string;
  open: boolean;
  doctorsInClinic: string[];
  onHandleDialog(status: boolean): void;
  onAddDoctor(doctors: IDoctor[]): void;
};
function DialogAddDoctor({
  open,
  onHandleDialog,
  onAddDoctor,
  token,
  doctorsInClinic,
}: IDialogAddDoctor) {
  const [doctors, setUsers] = useState<IDoctor[]>([]);
  const [newDoctors, setNewDoctos] = useState<IDoctor[]>([]);
  const onSelectDoctor = (doctor: IDoctor) => {
    setNewDoctos((prevDoctor) => [...prevDoctor, doctor]);
  };
  const removeDoctor = (doctor: IDoctor) => {
    setNewDoctos((doctors) => doctors.filter((d) => d !== doctor));
  };
  const onFetchUser = async () => {
    try {
      if (doctors.length === 0) {
        const resp = await fetchUserHospital({ token, doctorsInClinic });
        setUsers(resp);
      }
    } catch (error) {
      console.log("fetch user error", error);
    }
  };

  const onHandleCreate = (newDoctors: IDoctor[]) => {
    onAddDoctor(newDoctors);
    onHandleDialog(false);
    setNewDoctos([]);
  };

  useEffect(() => {
    if (open) {
      onFetchUser();
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={() => onHandleDialog(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>เลือกแพทย์</DialogTitle>
      <Box sx={{ width: "55%", justifyContent: "center", m: 2 }}>
        <DropdownDoctor doctors={doctors} onSelectedDoctor={onSelectDoctor} />
        <Box className="grid grid-cols-4">
          {newDoctors &&
            newDoctors.map((doctor) => (
              <Card key={doctor._id} sx={{ width: "full" }}>
                <CardContent>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="subtitle2"
                        sx={{ fontSize: 13, mt: 0.5 }}
                      >
                        {doctor.username}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        component="div"
                        sx={{ fontSize: 11, color: "text.disabled" }}
                      >
                        {doctor.fullName.firstName} {doctor.fullName.lastName}
                      </Typography>
                    }
                  />
                </CardContent>
                <CardActions>
                  <Button onClick={() => removeDoctor(doctor)} color="error">
                    remove
                  </Button>
                </CardActions>
              </Card>
            ))}
        </Box>
      </Box>
      <DialogActions>
        <Button onClick={() => onHandleCreate(newDoctors)}>Create</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogAddDoctor;
