import { AddAlarm, AutoDelete } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useFieldArray } from "react-hook-form";

const SchedulesArray = ({ index, control, register }: any) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `days.${index}.schedules`,
  });
  return (
    <div className="my-1">
      <div className="flex justify-end">
        <button
          type="button"
          onClick={() => {
            append({
              sTime: "",
              eTime: "",
              totalAppointment: 0,
            });
          }}
        >
          <AddAlarm color="primary" />
        </button>
      </div>

      {fields.map((item, k) => {
        return (
          <div
            className="grid md:grid-cols-4 sm:grid-cols-2 items-center m-2 "
            key={item.id}
          >
            <input
              type="time"
              name={`days.${index}.schedules.${k}.sTime`}
              ref={register()}
              className="rounded-lg border-2 border-gray-100 p-2"
              defaultValue={item.sTime}
            />
            <input
              type="time"
              name={`days.${index}.schedules.${k}.eTime`}
              ref={register()}
              className="rounded-lg border-2 border-gray-100 p-2"
              defaultValue={item.eTime}
            />
            <input
              type="number"
              name={`days.${index}.schedules.${k}.totalAppointment`}
              ref={register()}
              className="rounded-lg border-2 border-gray-100 p-2"
              defaultValue={item.totalAppointment}
            />
            <button onClick={() => remove(k)}>
              <AutoDelete color="error" />
            </button>
          </div>
        );
      })}

      <hr />
    </div>
  );
};

const DayArrayForm = ({ control, register, setValue }: any) => {
  const { fields } = useFieldArray({
    control,
    name: "days",
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <div className=" mx-20">
            <Typography>{item.name}</Typography>
            <SchedulesArray
              index={index}
              {...{ control, register, setValue }}
            />
          </div>
        );
      })}
    </>
  );
};

export default DayArrayForm;
