import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// mui
import { TextField, CircularProgress, Autocomplete } from "@mui/material";

// types
import { IAutocompleteHospital, IHospital } from "../type";

// api
import fetchHospitalListForBilling from "../../../api/Invoices/fetchHospitalListForBilling.service";

function AutocompleteHospital({ onChangeHospital }: IAutocompleteHospital) {
  const { appData }: any = useSelector((state) => state);
  const [hospitalList, setHospitalList] = useState<IHospital[]>([]);
  const { idToken } = appData;

  const fetchData = async () => {
    const result = await fetchHospitalListForBilling({ idToken });
    setHospitalList(result);
  };

  useEffect(() => {
    fetchData();
  }, [idToken]);

  return (
    <Autocomplete
      disableListWrap
      loading={hospitalList?.length === 0}
      options={Array.isArray(hospitalList) ? hospitalList : []}
      onChange={(option, value: any) => onChangeHospital(value)}
      getOptionLabel={(option) => `${option?.hospCode} :${option?.hospName} `}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="medium"
          label="เรียกเก็บ รพ."
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {hospitalList?.length === 0 ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default AutocompleteHospital;
