import React, { useState } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import { IconButton, Tooltip, Avatar, Hidden } from "@mui/material";
import MenuApps from "./Menu/MenuApp";
import MenuInfo from "./Menu/MenuInfo";
import { IBarApp } from "./Menu/type";

function BarApp({ fullName, loginData }: IBarApp) {
  const [AppMenuAnchorEl, setAppMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [InfoMenuAnchorEl, setInfoMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const AppMenuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAppMenuAnchorEl(event.currentTarget);
  };

  const AppMenuHandleClose = () => {
    setAppMenuAnchorEl(null);
  };
  const InfoMenuHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setInfoMenuAnchorEl(event.currentTarget);
  };
  const InfoMenuHandleClose = () => {
    setInfoMenuAnchorEl(null);
  };

  return (
    <>
      <Hidden mdDown>
        <IconButton
          className="icon-button"
          onClick={AppMenuHandleClick}
          size="large"
        >
          <Tooltip title="App">
            <AppsIcon />
          </Tooltip>
        </IconButton>
        <MenuApps
          open={Boolean(AppMenuAnchorEl)}
          anchorEl={AppMenuAnchorEl}
          handleClose={AppMenuHandleClose}
        />
      </Hidden>

      <IconButton
        className="icon-button avatar-button"
        onClick={InfoMenuHandleClick}
        size="large"
      >
        <Avatar>{fullName?.firstName?.charAt(0)}</Avatar>
      </IconButton>
      <MenuInfo
        open={Boolean(InfoMenuAnchorEl)}
        anchorEl={InfoMenuAnchorEl}
        handleClose={InfoMenuHandleClose}
        fullName={fullName}
        loginData={loginData}
      />
    </>
  );
}

export default BarApp;
