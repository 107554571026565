import {
  APPDATA_PENDING,
  APPDATA_FULFILLED,
  APPDATA_REJECTED,
} from "../constants/appData";

const initialState = {
  FetchingStatus: false,
  loginData: null,
  idToken: "",
  emrData: {
    patients: null,
    visits: [],
    referIn: [],
    referOut: [],
    referDatas: [],
    referReportChwBwHosp: [],
    referStartDate: String,
    referEndDate: String,
    referType: String,
  },
  onlineGatewayHospital: [],
  referAccept: null,
  referReject: null,
  categories: null,
  participatingHospitals: null,
  billings: null,
  hospmains: null,
  hospCode: null,
  selectedHospmain: null,
  billingSummary: null,
  chargeModal: false,
  calculateSummary: null,
  billlingPatients: null,
  patientData: null,
  alert: {
    show: false,
    msg: "",
    type: null,
  },
  previousUser: null,
  hospitalList: [],
  hospitalTypeList: [],
  users: [],
  ReferDatas: [],
  customerConfig: null,
  permissionRules: null,
  permissionManagement: {
    role: null,
    roles: null,
    distinctRoles: null,
  },
  report: null,
  loginHistory: null,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case APPDATA_PENDING:
      return {
        ...state,
        FetchingStatus: true,
      };
    case APPDATA_FULFILLED:
      return {
        ...state,
        ...action.payload,
      };
    case APPDATA_REJECTED:
      return {
        ...state,
        FetchingStatus: false,
      };

    default:
      return state;
  }
}
