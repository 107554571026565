import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

const FetchUsers = ({
  idToken,
  page = 1,
  limit = 20,
  sortColumn = "",
  sortType = 0,
  filterText = "",
  filterList = [],
}) => {
  let dispatch = {};
  const url = `${getApiUrl()}/user`;

  const filter = {};
  if (filterList.length) {
    if (filterList[0].length) {
      const hospCode = filterList[0];
      filter.hospCode = hospCode;
    }
  }

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
      params: {
        page,
        limit,
        sortColumn,
        sortType,
        filterText,
        filter,
      },
    })
    .then((res) => {
      dispatch = {
        users: res.data.result,
        FetchingStatus: false,
        alert: {
          show: false,
          msg: "",
          type: null,
        },
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};

export default FetchUsers;
