import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IVisit } from "@/types/visit";
import { fetchSampleVisit } from "../../api/sampleData/fetchSampleVisit";
import { Avatar } from "@mui/material";
interface IVisitTable {
  date: string;
  token: string;
}
function VisitTable({ date, token }: IVisitTable) {
  const [visits, setVisits] = useState<IVisit[]>([]);
  const getVisitData = async () => {
    const visitResp = await fetchSampleVisit(new Date(date), token);
    setVisits(visitResp);
  };
  useEffect(() => {
    if (date) {
      getVisitData();
    }
  }, [date]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">รหัสบัตรประชาชน</TableCell>
            <TableCell>รหัสโรงพยาบาล</TableCell>
            <TableCell>แผนก</TableCell>
            <TableCell>วันที่เข้ารพ.</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visits.length > 0 ? (
            visits.map((visit) => (
              <TableRow
                key={visit._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{visit.cid}</TableCell>

                <TableCell component="th" scope="row">
                  {visit.hospCode}
                </TableCell>
                <TableCell component="th" scope="row">
                  {visit.departmentName}
                </TableCell>
                <TableCell align="left">
                  {new Date(visit.visitDateTime).toLocaleDateString("th-TH")}
                </TableCell>
                <TableCell>
                  <div className="flex">
                    {visit.labResults?.length > 0 && (
                      <Avatar
                        sx={{ width: "24px", height: "24px", margin: "5px" }}
                        src="/lab.png"
                      />
                    )}
                    {visit.xrays?.length > 0 && (
                      <Avatar
                        sx={{ width: "24px", height: "24px", margin: "5px" }}
                        src="/xray.png"
                      />
                    )}
                    {visit.dentals?.length > 0 && (
                      <Avatar
                        sx={{
                          width: "24px",
                          height: "24px",
                          margin: "5px",
                          background: "black",
                        }}
                        src="/dentist.png"
                      />
                    )}
                    {visit.doctorNotes?.length > 0 && (
                      <Avatar
                        sx={{ width: "24px", height: "24px", margin: "5px" }}
                        src="/note.png"
                      />
                    )}
                    {visit.an && (
                      <Avatar
                        sx={{ width: "24px", height: "24px", margin: "5px" }}
                        src="/admit.png"
                      />
                    )}
                    {visit.appointments?.length > 0 && (
                      <Avatar
                        sx={{ width: "24px", height: "24px", margin: "5px" }}
                        src="/appointment.png"
                      />
                    )}
                    {visit.referrals?.length > 0 && (
                      <Avatar
                        sx={{
                          width: "24px",
                          height: "24px",
                          margin: "5px",
                          background: "green",
                        }}
                        src="/refer.png"
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell />
              <TableCell align="right">ไม่มีข้อมูล</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default VisitTable;
