import React, { useState, useEffect } from "react";
import fetchReasonRefer from "../../api/reasonRefer/fetchReasonRefer";
import updateReasonRefer from "../../api/reasonRefer/updateReasonRefer";
import createReasonRefer from "../../api/reasonRefer/createReasonRefer";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/servicePermission";
import { useSelector } from "react-redux";
import SettingReasonRefer from "../Utility/Refers/SettingReasonDialog/settingReasonRefer";
import { IReasonRefer } from "@/types/reasonRefer";
import { Box, IconButton, Pagination } from "@mui/material";
import { Add, Close, Delete, Edit } from "@mui/icons-material";
import { Alert, Stack } from "@mui/material";

export interface IPaginateReason {
  totalDocs: number;
  totalPages: number;
  reasons: IReasonRefer[];
}
function ReasonRefer() {
  const {
    appData: { permissionRules, idToken, hospCode },
  }: any = useSelector((state) => state);
  // const history = useHistory();
  const [data, setData] = useState<IPaginateReason>({
    totalDocs: 0,
    totalPages: 0,
    reasons: [],
  });
  const [openSetting, setOpenSetting] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedReason, setSelectedReason] = useState<IReasonRefer | null>(
    null
  );
  const [alert, setAlert] = useState({ open: false, message: "" });
  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  const onFetchReason = async (token: string, page: number) => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));

      const ability = new Ability(permissionRules);
      const canRead = ability.can(
        ServicePermissionEvent.READ,
        ServicePermissionService.SETTING_REASON_REFERRAL
      );

      if (canRead) {
        const result = await fetchReasonRefer(token, page, 10);
        setData(result);
        return;
      }
    }
  };

  useEffect(() => {
    onFetchReason(idToken, page);
    // return () => history.push("/");
  }, [permissionRules]);
  const onOpenDialogSetting = (reason: IReasonRefer | null) => {
    setSelectedReason(reason);
    setOpenSetting(true);
  };
  const onCloseDialogSetting = () => {
    setOpenSetting(false);
  };
  const onUpsertReason = async (reason: string) => {
    try {
      // if edit have selectedReason but create have't selectedReason
      if (selectedReason) {
        const dataUpdate = {
          ...selectedReason,
          reason: reason,
        };
        await updateReasonRefer(idToken, dataUpdate);
      } else {
        const dataCreate: IReasonRefer = {
          reason,
          hospCode: hospCode,
          isDeleted: false,
        };
        await createReasonRefer(idToken, dataCreate);
      }
      onFetchReason(idToken, page);
      onCloseDialogSetting();
      setAlert({ open: true, message: "อัพเดทข้อมูลเรียบร้อย" });
    } catch (error) {
      setAlert({ open: true, message: "อัพเดทข้อมูลล้มเหลว" });
    }
  };
  const onChangePage = (value: number) => {
    setPage(value);
    onFetchReason(idToken, value);
  };
  const onDeleteReason = async (reasonRefer: IReasonRefer) => {
    await updateReasonRefer(idToken, reasonRefer);
    await onFetchReason(idToken, page);
  };

  return (
    <div className="mt-16">
      <div className="flex justify-center ">
        <Box
          sx={{ marginTop: "35px", height: 620 }}
          className="w-1/2 bg-white shadow overflow-y-scroll"
        >
          <div>
            {alert?.open && (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert({ open: false, message: "" });
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                  severity="info"
                >
                  {alert.message}
                </Alert>
              </Stack>
            )}
          </div>
          <div className="flex justify-between">
            <span className="mx-5 my-3 text-lg text-blue-500">
              เหตุผลส่งต่อผู้ป่วย
            </span>
            {ability &&
              ability.can(
                ServicePermissionEvent.CREATE,
                ServicePermissionService.SETTING_REASON_REFERRAL
              ) && (
                <IconButton
                  onClick={() => onOpenDialogSetting(null)}
                  size="medium"
                >
                  <Add color="success" />
                </IconButton>
              )}
          </div>

          {data.reasons.map((reason, index) => (
            <div
              key={index}
              className="flex justify-between border-y border-gray-200 p-2 text-gray-600 rounded"
            >
              <span>{` ${reason.reason}`}</span>
              <div>
                {ability &&
                  ability.can(
                    ServicePermissionEvent.UPDATE,
                    ServicePermissionService.SETTING_REASON_REFERRAL
                  ) && (
                    <IconButton
                      onClick={() => onOpenDialogSetting(reason)}
                      size="small"
                      color="primary"
                    >
                      <Edit />
                    </IconButton>
                  )}
                {ability &&
                  ability.can(
                    ServicePermissionEvent.DELETE,
                    ServicePermissionService.SETTING_REASON_REFERRAL
                  ) && (
                    <IconButton
                      onClick={() =>
                        onDeleteReason({ ...reason, isDeleted: true })
                      }
                      size="small"
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  )}
              </div>
            </div>
          ))}
          <Box className="mt-4 flex justify-end">
            {data.reasons.length > 0 && (
              <Pagination
                color="primary"
                count={data.totalPages}
                page={page}
                onChange={(event, value) => onChangePage(value)}
              />
            )}
          </Box>
        </Box>
      </div>
      <SettingReasonRefer
        open={openSetting}
        reason={selectedReason}
        onClose={onCloseDialogSetting}
        onSubmit={onUpsertReason}
      />
    </div>
  );
}

export default ReasonRefer;
