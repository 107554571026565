import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import BarChartIcon from "@mui/icons-material/BarChart";
import TableView from "@mui/icons-material/TableView";
import ReportDiag4Branch from "./ReportDiag4Branch";
import ReportDiag4BranchTable from "./ReportDiag4BranchTable";
import { IReportResponse } from "@/types/report";
import { ReferralType } from "@/types/refers";

interface IContainerReportDiag4Branch {
  report: IReportResponse;
  referType: ReferralType;
  startDate: Date;
  endDate: Date;
}

export default function ContainerReportDiag4Branch({
  report,
  referType,
  startDate,
  endDate,
}: IContainerReportDiag4Branch) {
  const [currentFourBranchsType, setCurrentFourBranchsType] = useState<
    "chart" | "table"
  >("chart");

  return (
    <div className="mt-4">
      <div className="flex justify-end border border-b-0">
        <IconButton
          className="!m-1"
          onClick={() =>
            setCurrentFourBranchsType(
              currentFourBranchsType === "chart" ? "table" : "chart"
            )
          }
        >
          {currentFourBranchsType === "chart" ? (
            <BarChartIcon color="primary" />
          ) : (
            <TableView color="primary" />
          )}
        </IconButton>
      </div>

      {currentFourBranchsType === "chart" && report && (
        <div className="border p-4">
          <ReportDiag4Branch
            report={report}
            referType={referType}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      )}

      {currentFourBranchsType === "table" && report && (
        <div>
          <ReportDiag4BranchTable
            report={report}
            referType={referType}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      )}
    </div>
  );
}
