import { Box, Typography } from "@mui/material";
import React from "react";
import { shortHospName } from "../../../functions/FuncPerjer";

interface IVisitDetail {
  hospName: string;
  departmentName: string;
  spcltyName: string;
  vn: string;
  pttype: string;
  pttypeName: string;
  hospmainName: string;
  hospsubName: string;
  doctorName: string;
  hospmain: string;
  hospsub: string;
  hn: { hospCode: string; value: string }[];
  hospCode: string;
  an?: string;
}
function VisitDetail({
  hospName,
  departmentName,
  spcltyName,
  vn,
  pttype,
  pttypeName,
  hospmainName,
  hospsubName,
  doctorName,
  hospmain,
  hospsub,
  hn,
  hospCode,
  an,
}: IVisitDetail) {
  const hnVisit = () => {
    if (hn.length === 0) return "";
    const hnHospCode = hn.find((h) => h.hospCode === hospCode);
    if (!hnHospCode) return hn[0].value;
    return hnHospCode.value;
  };

  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box>
        <Box className="py-2 px-5 border-b-2 border-gray-200">
          <Typography color="#58595B">{shortHospName(hospName)}</Typography>
          <Typography color="#58595B">{`แผนก / ห้องตรวจ: ${
            departmentName || spcltyName || "ไม่ระบุ"
          }`}</Typography>
          <Typography color="#58595B">{`HN: ${hnVisit()}`}</Typography>
          <Typography color="#58595B">{`VN: ${vn || "-"}`}</Typography>
          {an && <Typography color="#58595B">{`AN: ${an}`}</Typography>}
        </Box>
        <Box className="py-2 px-5 border-b-2 border-gray-200">
          <Typography
            variant="body2"
            color="#58595B"
          >{`สิทธิการรักษาในวันนี้ : ${
            pttype || pttypeName ? `${pttype} ${pttypeName}` : "ไม่ระบุ"
          }`}</Typography>
          <Typography variant="body2" color="#58595B">{`สถานพยาบาลหลัก : ${
            hospmain || "ไม่ระบุ"
          } ${shortHospName(hospmainName)}`}</Typography>
          <Typography variant="body2" color="#58595B">{`สถานพยาบาลรอง : ${
            hospsub || "ไม่ระบุ"
          } ${shortHospName(hospsubName)}`}</Typography>
        </Box>
        <Box className="py-1 px-5">
          <Typography color="#58595B">{`แพทย์ผู้ทำการรักษา : ${
            doctorName || "ไม่ระบุ"
          }`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default VisitDetail;
