import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TSchedule } from "../../types/schedule";

const fetchSchedules = async (
  token: string,
  doctorId: string,
  doctorHospCode: string,
  clinicId: string,
  startDate: Date,
  endDate: Date,
  type: "MAKE_APPOINTMENT" | "SCHEDULE" | "VERIFY"
): Promise<TSchedule[]> => {
  const url = getApiUrl();
  const resp = await axios.get(
    `${url}/v1/schedule?doctorId=${doctorId}&doctorHospCode=${doctorHospCode}&startDate=${startDate}&endDate=${endDate}&clinicId=${clinicId}&type=${type}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};
export default fetchSchedules;
