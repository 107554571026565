import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";
import ListboxComponent from "../../Utility/ListBoxComponents";
import { TClinic } from "../../../types/clinic";

type TDropdownClinic = {
  clinics: TClinic[];
  handleSeletedClinic(clinic: TClinic): void;
};
function DropdownClinic({ clinics, handleSeletedClinic }: TDropdownClinic) {
  return (
    <FormControl className="flex-auto w-full bg-white mx-2">
      <Autocomplete
        disableListWrap
        ListboxComponent={ListboxComponent}
        options={clinics}
        onChange={(option, value) => value && handleSeletedClinic(value)}
        getOptionLabel={(option) => `${option.clinicName}`}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            fullWidth
            label="คลินิก"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default DropdownClinic;
