import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { IDoctor } from "../../types/clinic";

type IFetchUserHospParams = {
  token: string;
  doctorsInClinic: string[];
};
const fetchUserHospital = async ({
  token,
  doctorsInClinic,
}: IFetchUserHospParams): Promise<IDoctor[]> => {
  const url = getApiUrl();
  const result = await axios.post(
    `${url}/v1/clinic/user/hospital`,
    { doctorsInClinic },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return result.data;
};

export default fetchUserHospital;
