import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";

// style
import { TableRow, TableCell, Container } from "./styles";

// actions
import fetchCategories from "../../actions/fetchCategories";

// api
import DeleteCategory from "../../api/Billings/DeleteCategory";
import { useTranslation } from "react-i18next";
import CalculateBillingModal from "./CalculateBilling";

function Settings() {
  const { t } = useTranslation("admin");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const { appData } = useSelector((state) => state);
  const {
    idToken,
    loginData: { hospCode },
    categories,
  } = appData;
  const dispatch = useDispatch();
  const history = useHistory();
  const fetchData = async (idToken, hospCode) => {
    dispatch(fetchCategories({ idToken, hospCode }));
  };
  useEffect(() => {
    fetchData(idToken, hospCode);
  }, [dispatch, idToken, hospCode]);

  const handleDelete = async (body) => {
    setLoading(true);
    await DeleteCategory(body);
    fetchData(body.idToken, body.hospCode);
    setLoading(false);
    setModal(false);
  };
  const onClickDelete = () => {
    setModal(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [calculateAnchor, setCalculateAnchor] = React.useState(null);

  const [selected, setSelected] = useState(null);

  const open = Boolean(anchorEl);

  const calculateOpen = Boolean(calculateAnchor);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelected(row);
  };

  const handleClickEdit = () => {
    history.push(`/settings/edit-category/${selected?._id}`, {
      category: selected,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCalculate = (event) => {
    setCalculateAnchor(event.currentTarget);
  };

  const handleCloseCalculate = () => {
    setCalculateAnchor(null);
  };

  return (
    <Container className="mt-20 p-6">
      <div className="header my-2 mx-4 mb-4 mt-4">
        <div style={{ color: "#3f98D2" }} className=" font-bold text-2xl">
          {t("settings.setting")}
        </div>
        <div>
          <Button
            variant="contained"
            color="inherit"
            onClick={handleClickCalculate}
            style={{
              padding: "8px 22px 8px 22px",
              borderRadius: 64,
              color: "#3F98D2",
              margin: "4px",
              // backgroundColor: "#3F98D2",
            }}
          >
            คำนวณ billing ใหม่
          </Button>
          <Button
            onClick={() => history.push("/settings/edit-category")}
            variant="contained"
            color="primary"
            size="small"
            style={{
              padding: "8px 22px 8px 22px",
              borderRadius: 64,
              backgroundColor: "#3F98D2",
            }}
          >
            {t("settings.create")}
          </Button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ชื่อสิทธิ์หลัก</TableCell>
              <TableCell>รหัสสิทธิ์ pttype</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {categories &&
              categories.map((row) => (
                <TableRow key={row._id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.pttype && row.pttype.join(", ")}</TableCell>
                  <TableCell align="right">
                    <span className="menu-wrapper">
                      <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => handleClick(e, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleClickEdit}>แก้ไข</MenuItem>
                        <MenuItem onClick={onClickDelete}>ลบ</MenuItem>
                      </Menu>
                    </span>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle>ลบรายการ {selected?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>ยืนยันที่จะลบ ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setModal(false)}
            color="primary"
          >
            ยกเลิก
          </Button>
          <Button
            disabled={loading}
            onClick={() =>
              handleDelete({ idToken, hospCode, categoryId: selected?._id })
            }
            color="primary"
          >
            ยืนยัน
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
      <CalculateBillingModal
        open={calculateOpen}
        handleClose={handleCloseCalculate}
        token={idToken}
      />
    </Container>
  );
}

export default Settings;
