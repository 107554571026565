import { combineReducers } from "redux";
import { createReducer } from "redux-orm";
import appProperty from "./appProperty";
import appData from "./appData";
import invoicesReducer from "./invoicesReducer";

import orm from "../models/index";

export const rootReducer = combineReducers({
  orm: createReducer(orm),
  appProperty,
  appData,
  invoices: invoicesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
