import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import editUser from "../../actions/UserManagement/editUser.action";
import { RootState } from "@/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

interface IResetPasswordDialog {
  open: boolean;
}

const ResetPasswordDialog = ({ open }: IResetPasswordDialog) => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const { appData } = useSelector((state: RootState) => state);
  const { idToken, loginData } = appData;

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordCondition, setPasswordCondition] = useState({
    match: true,
    length: true,
  });

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordCondition({ ...passwordCondition, match: false });
      return;
    }

    if (newPassword.length < 6 || confirmPassword.length < 6) {
      setPasswordCondition({ ...passwordCondition, length: false });
      return;
    }

    setLoading(true);
    setPasswordCondition({ match: true, length: true });

    const body = {
      username: loginData.username,
      password: newPassword,
    };

    dispatch(editUser({ idToken, body }));

    setLoading(false);
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>{t("welcome.reset_password")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("welcome.reset_password_title")}
        </DialogContentText>
        <TextField
          required
          margin="dense"
          id="newPassword"
          label={t("welcome.password")}
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <TextField
          required
          margin="dense"
          id="confirmPassword"
          label={t("welcome.confirm_password")}
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {(!passwordCondition.match || !passwordCondition.length) && (
          <Typography color="error">
            {t("welcome.password_mismatch")}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          startIcon={loading ? <SaveIcon /> : ""}
          onClick={handleResetPassword}
          color="primary"
          variant="contained"
        >
          {t("welcome.reset_password")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPasswordDialog;
