import React from "react";
import { Box, Typography } from "@mui/material";

interface IVisitNoteProps {
  visitNotes: string;
}
function VisitNote({ visitNotes = "" }: IVisitNoteProps) {
  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box
        sx={{ borderBottom: "1px solid #D4D9DF", boxSizing: "border-box" }}
        height="40px"
        padding="5px 20px"
      >
        <Typography variant="h6" color="#58595B">
          Visit Note
        </Typography>
      </Box>
      <Box paddingX={3} paddingY={2}>
        <Typography variant="caption">{visitNotes}</Typography>
      </Box>
    </Box>
  );
}

export default VisitNote;
