import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TClinic } from "../../types/clinic";

const updateClinic = async (token: string, id: string, data: TClinic) => {
  const url = getApiUrl();
  await axios.put(`${url}/v1/clinic/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export default updateClinic;
