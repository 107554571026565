import { createStore, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise-middleware";

import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { rootReducer } from "../reducers";

export default function configureStore() {
  const initialState = {};
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(promiseMiddleware))
  );
  return store;
}
