import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default ({
  idToken,
  page,
  limit = 5,
}: {
  idToken: string;
  page: number;
  limit?: number;
}) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/roles`;

  return axios
    .get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
      params: {
        page,
        limit,
      },
    })
    .then((res) => {
      dispatch = {
        permissionManagement: {
          roles: res.data,
        },
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
