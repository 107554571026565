import { Box, Typography } from "@mui/material";
import React from "react";
import { IDoctorNote } from "@/types/visit";
import DoctorNoteItem from "./DoctorNoteItem";

interface IDoctorNoteProps {
  doctorNotes: IDoctorNote[];
}
function DoctorNote({ doctorNotes }: IDoctorNoteProps) {
  const doctorNoteItem = () =>
    doctorNotes.map((doctorNote) => (
      <DoctorNoteItem key={doctorNote.bid} {...doctorNote} />
    ));
  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box
        sx={{ borderBottom: "1px solid #D4D9DF", boxSizing: "border-box" }}
        height="40px"
        padding="5px 20px"
      >
        <Typography variant="h6" color="#58595B">
          Doctor Note
        </Typography>
      </Box>
      <Box>
        {Array.isArray(doctorNotes) &&
          doctorNotes?.length > 0 &&
          doctorNoteItem()}
      </Box>
    </Box>
  );
}

export default DoctorNote;
