import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default ({ idToken, roleId }: { idToken: string; roleId: string }) => {
  const url = `${getApiUrl()}/v1/roles/changeStatus`;

  const body = {
    roleId,
  };

  return axios.put(url, body, {
    headers: { Authorization: `Bearer ${idToken}` },
  });
};
