import React, { useState } from "react";
import {
  Stack,
  FormControl,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { IReferral } from "../../../types/refers";
import { TSchedule } from "../../../types/schedule";

interface IAppointmentForm {
  day: Date | null;
  token: string;
  referral: IReferral;
  schedule: TSchedule;
  clinicNote: string;
  onSubmit(
    scheduleId: string,
    referId: string,
    cid: string,
    clinicId: string
  ): Promise<void>;
}
function AppointmentForm({
  day,
  referral,
  schedule,
  onSubmit,
  clinicNote,
}: IAppointmentForm) {
  const [reason, setReason] = useState<string>(referral.data.reason || "");

  return (
    <Stack spacing={2}>
      <Typography>วันที่ {day?.toLocaleDateString("th-TH")}</Typography>
      <FormControl className="rounded-xl w-96 mt-10">
        <TextField
          value={referral.data.cid || ""}
          label="เลขบัตรประชาชน"
          disabled
          // onChange={}
          InputProps={{ sx: { borderRadius: 4, bgcolor: "white" } }}
        />
      </FormControl>
      <FormControl className="rounded-xl w-96 mt-10">
        <TextField
          value={reason}
          label="เหตุผลในการส่งตัว"
          onChange={(e) => setReason(e.target.value)}
          InputProps={{ sx: { borderRadius: 4, bgcolor: "white" } }}
        />
      </FormControl>
      <Typography color="error">หมายเหตุ: {clinicNote || ""}</Typography>
      <Button
        onClick={() =>
          onSubmit(
            schedule._id,
            referral._id,
            referral.data.cid,
            schedule.clinicId
          )
        }
        sx={{ borderRadius: 2, my: 2 }}
        variant="outlined"
      >
        ยืนยันนัดหมาย
      </Button>
    </Stack>
  );
}

export default AppointmentForm;
