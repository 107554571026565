import React, { useState } from "react";
import dayjs from "dayjs";
// @mui
import {
  Stack,
  Button,
  Typography,
  IconButton,
  MenuItem,
  Popover,
} from "@mui/material";
// utils
import {
  ArrowLeft,
  ArrowRight,
  CalendarToday,
  Search,
  // Settings,
} from "@mui/icons-material";

// ----------------------------------------------------------------------timeGridDay

const VIEW_OPTIONS: { value: string; label: string; icon: string }[] = [
  { value: "dayGridMonth", label: "เดือน", icon: "ic:round-view-module" },
  { value: "timeGridWeek", label: "สัปดาห์", icon: "ic:round-view-module" },
  { value: "timeGridDay", label: "วัน", icon: "ic:round-view-module" },
  // { value: "listWeek", label: "Agenda", icon: "ic:round-view-agenda" },
];
interface ICalendarToolbar {
  onToday(): void;
  onChangeView(view: string): void;
  hiddenChangeView: boolean;
  onNextDate(): void;
  onPrevDate(): void;
  date: Date;
  view: string;
  children: React.ReactNode;
  onSearch(): void;
  selectedHospCode?: string;
}
function CalendarToolbar({
  onChangeView,
  children,
  date,
  view,
  onNextDate,
  onPrevDate,
  hiddenChangeView,
  onSearch,
  selectedHospCode,
}: ICalendarToolbar) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const fDate = (date: Date) => dayjs(date).format("DD MMM YYYY");
  const selectedItem = VIEW_OPTIONS.filter((item) => item.value === view)[0];
  const openPopover = Boolean(anchorEl);
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pb: 2.5 }}
      >
        <Stack className="w-full">{children}</Stack>

        <Stack
          direction="row"
          spacing={1}
          className="w-full items-center justify-end"
        >
          <Button
            variant="contained"
            onClick={onSearch}
            disabled={!selectedHospCode}
            sx={{
              bgcolor: "white",
              color: "gray",
              borderRadius: "20px",
              p: 2,
              ":hover": {
                backgroundColor: "#fff",
                color: "#132043",
                border: "0.5px solid",
              },
            }}
          >
            <Search />
          </Button>
          {!hiddenChangeView && (
            <>
              <Button
                variant="contained"
                onClick={handleOpenPopover}
                sx={{
                  bgcolor: "white",
                  color: "gray",
                  borderRadius: "20px",
                  p: 2,
                  ":hover": {
                    backgroundColor: "#fff",
                    color: "#132043",
                    border: "0.5px solid",
                  },
                }}
              >
                {selectedItem.label}
              </Button>
              <Popover
                open={openPopover}
                onClose={handleClosePopover}
                sx={{ width: 160 }}
                anchorEl={anchorEl}
              >
                {VIEW_OPTIONS.map((viewOption) => (
                  <MenuItem
                    key={viewOption.value}
                    onClick={() => {
                      // handleClosePopover();
                      onChangeView(viewOption.value);
                      handleClosePopover();
                    }}
                    sx={{
                      ...(viewOption.value === view && {
                        bgcolor: "action.selected",
                      }),
                    }}
                  >
                    {viewOption.label}
                  </MenuItem>
                ))}
              </Popover>
            </>
          )}

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            className="bg-white px-2 py-2 rounded-2xl border-2"
          >
            <IconButton onClick={onPrevDate}>
              <ArrowLeft className="bg-gray-200 rounded-full" />
            </IconButton>

            <Typography className="text-gray-400 flex">
              <CalendarToday fontSize="small" className="mx-1" />
              {fDate(date)}
            </Typography>

            <IconButton onClick={onNextDate}>
              <ArrowRight className="bg-gray-200 rounded-full" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

export default CalendarToolbar;
