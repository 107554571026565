export const INVOICES_LIST = "INVOICES_LIST";
export const INVOICES_LIST_FULFILLED = "INVOICES_LIST_FULFILLED";
export const INVOICES_DETAIL = "INVOICES_DETAIL";
export const INVOICES_DETAIL_FULFILLED = "INVOICES_DETAIL_FULFILLED";
export const INVOICES_CONFIRM = "INVOICES_CONFIRM";
export const INVOICES_CONFIRM_FULFILLED = "INVOICES_CONFIRM_FULFILLED";
export const INVOICES_ACCEPT = "INVOICES_ACCEPT";
export const INVOICES_ACCEPT_FULFILLED = "INVOICES_ACCEPT_FULFILLED";
export const INVOICES_REJECT = "INVOICES_REJECT";
export const INVOICES_REJECT_FULFILLED = "INVOICES_REJECT_FULFILLED";
export const INVOICES_CANCEL = "INVOICES_CANCEL";
export const INVOICES_CANCEL_FULFILLED = "INVOICES_CANCEL_FULFILLED";
