import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import resetPassword from "../../api/resetPassword.service";
import logout from "../../actions/User/logout.action";
import { useDispatch } from "react-redux";

const useQuery = () => new URLSearchParams(useLocation().search);

const ResetPassword = () => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const history = useHistory();

  const email = query.get("email") || "";
  const token = query.get("token") || "";

  const logoutApp = () => {
    const payload = {
      loginData: null,
      loginStatus: false,
      idToken: "",
      FetchingStatus: false,
      previousUser: null,
    };

    localStorage.removeItem("expireDate");
    localStorage.setItem("Token", "");

    dispatch(logout(payload));
    history.push("/");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("รหัสผ่านไม่ตรงกัน");
      return;
    }

    setError("");
    setLoading(true);

    try {
      await resetPassword(email, token, password);
      setMessage("รีเซ็ตรหัสผ่านสำเร็จ กำลังนำคุณไปยังหน้าล็อกอิน...");
      setTimeout(() => {
        logoutApp();
      }, 2000);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Paper
        sx={{
          p: 4,
          width: "100%",
          maxWidth: 500,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h4" align="center" sx={{ fontWeight: 600, mb: 3 }}>
          รีเซ็ตรหัสผ่าน
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            label="รหัสผ่านใหม่"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{
              mb: 2,
              "& .MuiInputBase-root": {
                padding: "12px 14px",
              },
            }}
          />
          <TextField
            label="ยืนยันรหัสผ่าน"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            sx={{
              mb: 2,
              "& .MuiInputBase-root": {
                padding: "12px 14px",
              },
            }}
          />

          {error && (
            <Typography color="error" align="center" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          {message && (
            <Typography color="primary" align="center" sx={{ mb: 2 }}>
              {message}
            </Typography>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 2,
              height: 50,
              fontSize: "16px",
              fontWeight: 600,
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "รีเซ็ตรหัสผ่าน"
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ResetPassword;
