import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Ability, AbilityTuple, Subject, MongoQuery } from "@casl/ability";
import { AnyObject } from "@casl/ability/dist/types/types";

// mui
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@mui/material";

// types
import { IMenuApps } from "./type";
import { menu } from "../../NavigationMain/menu";
import { ServicePermissionEvent } from "../../../constants/servicePermission";

import { useTranslation } from "react-i18next";

function MenuApps({ open, anchorEl, handleClose }: IMenuApps) {
  const history = useHistory();
  const { t } = useTranslation("common");
  const {
    appData: { permissionRules },
  }: any = useSelector((state) => state);

  const [ability, setAbility] =
    useState<Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>>();

  const cardItem = () => {
    return menu.map(
      (card, index) =>
        // if it has servicePermission then check permission
        ((ability &&
          card.servicePermission &&
          ability.can(ServicePermissionEvent.READ, card.servicePermission)) ||
          // if it hasn't servicePermission don't check permission and show munu normally
          !card.servicePermission) && (
          <Card
            key={index}
            className="w-44 h-auto text-center rounded-lg border-0"
            onClick={() => {
              handleClose();
              history.push(card.link);
            }}
          >
            <CardActionArea>
              <img
                src={card.pathImage}
                alt={card.text}
                className="w-14 h-14 mt-3 img-fluid ml-auto mr-auto"
              />
              <CardContent>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {t(card.text)}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        )
    );
  };

  useEffect(() => {
    if (permissionRules) {
      setAbility(new Ability(permissionRules));
    }
  }, [permissionRules]);

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose()}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem>
        <Box className="grid grid-cols-3 gap-2">{cardItem()}</Box>
      </MenuItem>
    </Menu>
  );
}
export default MenuApps;
