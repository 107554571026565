import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default async ({ idToken }: { idToken: string }) => {
  try {
    const response = await axios.get(
      `${getApiUrl()}/v1/hospital/hospitalListForBilling`,
      {
        headers: { authorization: `Bearer ${idToken}` },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
