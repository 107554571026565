import React from "react";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { IUserPermission } from "../NavigationMain/permission/type";
import { useTranslation } from "react-i18next";

function CardItem({ description, text, pathImage, link }: IUserPermission) {
  const { t } = useTranslation("common");

  const history = useHistory();
  return (
    <Card
      key={`${text}-${description}`}
      className="w-32 h-32 text-center !rounded-3xl flex justify-center items-center
      xs:w-44 xs:h-44"
      onClick={() => history.push(link)}
    >
      <CardActionArea>
        <img
          src={pathImage}
          alt={text}
          className="w-8 mt-5 img-fluid mx-auto
          xs:w-14 xs:mt-3"
        />
        <CardContent>
          <Typography gutterBottom variant="subtitle1" component="div">
            {t(text)}
          </Typography>
          <Typography
            variant="caption"
            className="hidden
            xs:block"
          >
            {t(description)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default CardItem;
