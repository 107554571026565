import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";

// mui
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
// styles
import "../../../../styles/refers/ReferRejectDialog.scss";
import { useSelector } from "react-redux";
import fetchReasonRefer from "../../../../api/reasonRefer/fetchReasonRefer";
import { Radio } from "@mui/material";

export default function ReferRejectDialog(props) {
  const { handleSubmit, control } = useForm();

  const { open, cid, ptname, hospName, onClose, onSubmit } = props;
  const {
    appData: { idToken },
  } = useSelector((state) => state);
  const [isOther, setIsOther] = useState(false);
  const [data, setData] = useState({
    totalDocs: 0,
    totalPages: 0,
    reasons: [],
  });
  const [selectedReason, setSelectedReason] = useState(null);
  const submit = ({ reason }) => {
    onSubmit(isOther ? reason : selectedReason.reason);
  };

  const onFetchReason = async (token) => {
    const result = await fetchReasonRefer(token);
    setData(result);
  };
  const selectOther = (checked) => {
    setIsOther(checked);
    setSelectedReason(null);
  };
  const onSelectChoice = (reason) => {
    setSelectedReason(reason);
    if (isOther) {
      setIsOther(false);
    }
  };
  useEffect(() => {
    onFetchReason(idToken);
  }, [open]);
  return (
    <Dialog
      fullWidth
      id="refer-reject-dialog"
      maxWidth="sm"
      open={open}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit((e) => submit(e))}>
        <DialogTitle>
          <WarningIcon className="bg-red-50 p-1 rounded-full text-red-500 mr-2 !w-8 !h-8" />
          ยืนยันการปฏิเสธ Refer
          <div className="float-right">
            <IconButton onClick={() => onClose()} size="medium">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          {cid && <DialogContentText>CID : {cid}</DialogContentText>}
          {ptname && <DialogContentText>ผู้ป่วย : {ptname}</DialogContentText>}
          {hospName && <DialogContentText>จาก : {hospName}</DialogContentText>}
          {data.reasons.map((reason, index) => (
            <div key={index} className="flex justify-between">
              <div className="flex">
                <Radio
                  checked={selectedReason?._id === reason._id}
                  onChange={(_, checked) => checked && onSelectChoice(reason)}
                />
                <h4 className="mt-2">{reason?.reason}</h4>
              </div>
            </div>
          ))}
          <DialogContentText>
            <div className="flex w-96">
              <Radio
                inputProps={{ "aria-label": "Checkbox demo" }}
                checked={isOther}
                onChange={(_, checked) => checked && selectOther(checked)}
              />
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    margin="dense"
                    label="อื่นๆ โปรดระบุ"
                    variant="outlined"
                    rows={2}
                    disabled={!isOther}
                  />
                }
                control={control}
                name="reason"
              />
            </div>
          </DialogContentText>
        </DialogContent>

        <DialogActions className="flex flex-col !px-5">
          <Button
            fullWidth
            type="submit"
            size="small"
            className="!bg-red-500 !text-white
          hover:!bg-red-600"
          >
            ยืนยันการปฏิเสธ Refer
          </Button>

          <Button
            fullWidth
            size="small"
            className="!mt-3 !text-sky-600
            hover:!bg-transparent"
            onClick={() => onClose()}
          >
            ยกเลิก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

ReferRejectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  cid: PropTypes.string.isRequired,
  ptname: PropTypes.string.isRequired,
  hospName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
