import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default async (body) => {
  const { idToken, hospCode, categoryId } = body;

  try {
    // routes สำหรับแก้ไข category and itemRules(i can fix)
    const result = await axios.put(
      `${getApiUrl()}/v1/billing/settings/${hospCode}/${categoryId}`,
      body,
      { headers: { Authorization: `Bearer ${idToken}` } }
    );

    return { result };
  } catch (e) {
    throw new Error(e);
  }
};
