import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export default async ({ idToken, body }: { idToken: string; body: object }) => {
  const url = `${getApiUrl()}/user`;

  try {
    const response = await axios.put(url, body, {
      headers: { Authorization: `Bearer ${idToken}` },
    });

    return {
      loginData: response.data.data,
      FetchingStatus: false,
      status: response.status,
      data: response.data,
    };
  } catch (error: any) {
    return {
      FetchingStatus: false,
      status: error.response ? error.response.status : 500,
      error: error.response
        ? error.response.data
        : "โปรดตรวจสอบความถูกต้องอีกครั้ง",
    };
  }
};
