import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// mui
import { TextField, CircularProgress, Autocomplete } from "@mui/material";

// actions
import fetchCategories from "../../../actions/fetchCategories";
import { IAutocompleteRule } from "../type";

function AutocompleteRule({
  onChangeRules,
  isProvider = true,
  hospCode = "",
}: IAutocompleteRule) {
  const { categories, fetchStatus } = useFetchCategory(isProvider, hospCode);

  return (
    <Autocomplete
      disableListWrap
      loading={categories?.length === 0 && !fetchStatus}
      options={Array.isArray(categories) ? categories : []}
      onChange={(option, value) => onChangeRules(value)}
      getOptionLabel={(option) => `${option.hospCode}: ${option.name}  `}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="medium"
          label={categories?.length > 0 ? "กลุ่มสิทธิ์" : "ไม่มีกลุ่มสิทธิ์"}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {categories?.length === 0 && !fetchStatus ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

const useFetchCategory = (isProvider: boolean, hospCode: string) => {
  const dispatch = useDispatch();
  const { appData }: any = useSelector((state) => state);
  const { idToken, categories, fetchStatus } = appData;
  useEffect(() => {
    if (hospCode) {
      dispatch(fetchCategories({ idToken, hospCode, isProvider }));
    }
  }, [dispatch, idToken, hospCode, isProvider]);
  return { categories, fetchStatus };
};
export default AutocompleteRule;
