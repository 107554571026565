import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";
import { IFilterOptions } from "@/types/commons";
import { IVisit } from "@/types/visit";
import { MONTHS, VisitFilterRadioValue } from "../../../constants/commons";
import FilterDateTypeRadio from "./FilterDateTypeRadio";
import { useTranslation } from "react-i18next";

interface IFilterDialog {
  isOpen: boolean;
  hospitalMenu: IVisit[];
  onOpen: () => void;
  onClose: () => void;
  onApplyFilters: (filters: IFilterOptions) => void;
}

const FilterDialog: React.FC<IFilterDialog> = ({
  isOpen,
  hospitalMenu,
  onOpen,
  onClose,
  onApplyFilters,
}) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 21 }, (_, index) => currentYear - index);

  const { t, i18n } = useTranslation("patientemr");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMonth, setSelectedMonth] = useState<string>("all");
  const [selectedYear, setSelectedYear] = useState<string>("all");
  const [selectedEndMonth, setSelectedEndMonth] = useState<string>("all");
  const [selectedEndYear, setSelectedEndYear] = useState<string>("all");
  const [selectedHospCode, setSelectedHospCode] = useState<string>("all");
  const [filterDateType, setFilterDateType] = useState<VisitFilterRadioValue>(
    VisitFilterRadioValue.MONTH
  );

  const handleMonthChange = (event: SelectChangeEvent) => {
    if (event.target.value !== "all" && selectedYear === "all") {
      setSelectedYear(currentYear.toString());
    }

    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    if (event.target.value === "all") {
      setSelectedMonth("all");
    }

    setSelectedYear(event.target.value);
  };

  const handleEndMonthChange = (event: SelectChangeEvent) => {
    setSelectedEndMonth(event.target.value);
  };

  const handleEndYearChange = (event: SelectChangeEvent) => {
    setSelectedEndYear(event.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const handleApplyFilters = () => {
    const currentFilter: IFilterOptions = {
      filterDateType,
      month: selectedMonth,
      year: selectedYear,
      endMonth:
        filterDateType === VisitFilterRadioValue.MONTH
          ? "all"
          : selectedEndMonth,
      endYear:
        filterDateType === VisitFilterRadioValue.MONTH
          ? "all"
          : selectedEndYear,
      hospCode: selectedHospCode,
    };

    onApplyFilters(currentFilter);
    handleClose();
  };

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target;
    setSelectedHospCode(`${value}`);
  };

  const handleRadioChange = (value: VisitFilterRadioValue) => {
    setFilterDateType(value);
  };

  const truncatedHospitalName = ({ hospital }: { hospital: IVisit }) => {
    const MAX_CHARS = 50;

    if (hospital.hospName) {
      if (hospital.hospName.length > MAX_CHARS) {
        return `${hospital.hospName.slice(0, MAX_CHARS)}...`;
      } else {
        return hospital.hospName;
      }
    } else {
      return hospital.hospCode;
    }
  };

  const setDefaultDateRange = () => {
    if (filterDateType === VisitFilterRadioValue.MONTH) {
      setSelectedMonth(new Date().getMonth().toString());
      setSelectedYear(currentYear.toString());
    } else {
      setSelectedMonth(new Date().getMonth().toString());
      setSelectedYear(currentYear.toString());
      setSelectedEndMonth(new Date().getMonth().toString());
      setSelectedEndYear(currentYear.toString());
    }
  };

  useEffect(() => {
    setDefaultDateRange();
  }, [filterDateType]);

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <FilterListIcon />
      </IconButton>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          container
          gap={2}
          justifyContent={"center"}
          width={300}
          paddingX={2}
          paddingY={3}
        >
          <Grid item xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel>{t("visit_list.hospital")}</InputLabel>
              <Select
                size="small"
                label={t("visit_list.hospital")}
                value={selectedHospCode}
                onChange={handleChange}
              >
                <MenuItem value="all">{t("visit_list.all")}</MenuItem>
                {hospitalMenu.map((hospital) => (
                  <MenuItem key={hospital._id} value={hospital.hospCode}>
                    {truncatedHospitalName({ hospital })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs
            display={"flex"}
            justifyContent={"flex-end"}
            marginBottom={-0.5}
            marginTop={1}
          >
            <FilterDateTypeRadio
              onRadioChange={handleRadioChange}
              filterDateType={filterDateType}
            />
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <FormControl size="small" fullWidth>
                <InputLabel>{t("visit_list.month")}</InputLabel>
                <Select
                  size="small"
                  label={t("visit_list.month")}
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  {filterDateType !== VisitFilterRadioValue.CUSTOM && (
                    <MenuItem value="all">{t("visit_list.all")}</MenuItem>
                  )}
                  {MONTHS[i18n.language === "en" ? "english" : "thai"].map(
                    (month, index) => (
                      <MenuItem
                        disabled={
                          filterDateType === VisitFilterRadioValue.CUSTOM &&
                          selectedYear === selectedEndYear &&
                          index > +selectedEndMonth
                        }
                        key={index}
                        value={index.toString()}
                      >
                        {month}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>{t("visit_list.year")}</InputLabel>
                <Select
                  size="small"
                  label={t("visit_list.year")}
                  value={selectedYear}
                  onChange={handleYearChange}
                >
                  {filterDateType !== VisitFilterRadioValue.CUSTOM && (
                    <MenuItem value="all">{t("visit_list.all")}</MenuItem>
                  )}
                  {years.map((year) => (
                    <MenuItem
                      disabled={
                        filterDateType === VisitFilterRadioValue.CUSTOM &&
                        year > +selectedEndYear
                      }
                      key={year}
                      value={year.toString()}
                    >
                      {i18n.language === "en" ? year : year + 543}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {filterDateType === VisitFilterRadioValue.CUSTOM && (
            <>
              <Typography textAlign={"center"} variant="body2">
                {t("visit_list.to")}
              </Typography>

              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <FormControl size="small" fullWidth>
                    <InputLabel>{t("visit_list.month")}</InputLabel>
                    <Select
                      size="small"
                      label={t("visit_list.month")}
                      value={selectedEndMonth}
                      onChange={handleEndMonthChange}
                    >
                      {MONTHS[i18n.language === "en" ? "english" : "thai"].map(
                        (month, index) => (
                          <MenuItem
                            disabled={
                              selectedYear === selectedEndYear &&
                              index < +selectedMonth
                            }
                            key={index}
                            value={index.toString()}
                          >
                            {month}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>{t("visit_list.year")}</InputLabel>
                    <Select
                      size="small"
                      label={t("visit_list.year")}
                      value={selectedEndYear}
                      onChange={handleEndYearChange}
                    >
                      {years.map((year) => (
                        <MenuItem
                          disabled={year < +selectedYear}
                          key={year}
                          value={year.toString()}
                        >
                          {i18n.language === "en" ? year : year + 543}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Button
              size="small"
              fullWidth
              variant="contained"
              onClick={handleApplyFilters}
            >
              {t("visit_list.search")}
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
};

export default FilterDialog;
