import React, { useEffect, useState } from "react";
// @mui
import { Stack } from "@mui/material";
import { IDoctor, TClinic } from "../../../types/clinic";
import TDepartment from "../../../types/department";
import fetchDepartments from "../../../api/SettingAppointment/fetchDepartments";
import fetchClinics from "../../../api/SettingAppointment/fetchClinics";
import fetchDoctors from "../../../api/SettingAppointment/fetchDoctors";
import DropdownDepartment from "../../SettingAppointment/components/DropdownDepartment";
import DropdownClinic from "../../SettingAppointment/components/DropdownClinic";
import DropdownDoctor from "../../SettingAppointment/components/DropdownDoctor";

type TMyHospFilter = {
  token: string;
  hospCode: string;
  onSelectedDoctor(doctor: IDoctor): void;
  onSelectedClinic(clinc: TClinic): void;
  // onSearch(): void;
};
function MyHospFilter(props: TMyHospFilter) {
  const { onSelectedDoctor, token, hospCode, onSelectedClinic } = props;
  const [departments, setDepartments] = useState<TDepartment[]>([]);
  const [clinics, setClinics] = useState<TClinic[]>([]);
  const [doctors, setDoctors] = useState<IDoctor[]>([]);
  const onFetchDepartments = async (token: string, hospCode: string) => {
    try {
      const resp = await fetchDepartments(token, hospCode);
      setDepartments(resp);
    } catch (error) {
      console.log("fetch department error", error);
    }
  };

  const onFetchClinics = async (
    token: string,
    departId: string,
    hospCode: string
  ) => {
    try {
      const resp = await fetchClinics(token, departId, hospCode);
      setClinics(resp);
    } catch (error) {
      console.log("fetch department error", error);
    }
  };

  const onFetchDoctors = async (token: string, doctorId: string[]) => {
    try {
      const resp = await fetchDoctors({ token, doctorId });
      setDoctors(resp);
    } catch (error) {
      console.log("fetch department error", error);
    }
  };

  const onSelectedDepartment = (department: TDepartment) => {
    if (!department || !department._id) return;
    onFetchClinics(token, department._id, department.hospCode);
  };

  const handleSelectedClinic = (clinic: TClinic) => {
    onSelectedClinic(clinic);
    onFetchDoctors(token, clinic.doctorId);
  };
  useEffect(() => {
    onFetchDepartments(token, hospCode);
  }, [token, hospCode]);
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <DropdownDepartment
        departments={departments}
        onSelectedDepartment={onSelectedDepartment}
      />

      <DropdownClinic
        clinics={clinics}
        handleSeletedClinic={handleSelectedClinic}
      />
      <DropdownDoctor doctors={doctors} onSelectedDoctor={onSelectedDoctor} />
    </Stack>
  );
}

export default MyHospFilter;
