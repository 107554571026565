export const PROCESSING = "PROCESSING";
export const PROCESSING_EDITED = "PROCESSING_EDITED";
export const PENDING = "PENDING";
export const DEST_ACCEPTED = "DEST_ACCEPTED";
export const SOURCE_ACCEPTED = "SOURCE_ACCEPTED";
export const REJECTED = "REJECTED";
export const EDITED = "EDITED";
export const PROVIDER_SUBMITTED_FOR_REVIEW = "PROVIDER_SUBMITTED_FOR_REVIEW";
export const ACCEPTED = "ACCEPTED";
export const PAYER_EDITED = "PAYER_EDITED";
export const PROVIDER_EDITED = "PROVIDER_EDITED";
export const PENDING_WITH_EDIT = "PENDING_WITH_EDIT";
export const PAYER_REOPENED = "PAYER_REOPENED";
