import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { IReferral } from "@/types/refers";
import VisitReferralItem from "../../../components/PatientEmr/VisitsReferrals/VisitReferralItem";
import { Box, Typography } from "@mui/material";

interface IReferCompareDialogProps {
  open: boolean;
  oldReferral: IReferral;
  newReferral: IReferral;
  onClose(): void;
}

const ReferCompareDialog = ({
  open,
  oldReferral,
  newReferral,
  onClose,
}: IReferCompareDialogProps) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <Slide
        direction="left"
        in={open}
        timeout={400}
        mountOnEnter
        unmountOnExit
      >
        <DialogContent className="bg-gray-100 py-4">
          <Button variant="outlined" onClick={onClose}>
            กลับ
          </Button>
          <Box display={"flex"} gap={2}>
            <Box flex={1}>
              <Typography variant="h6" className="text-blue-400" align="center">
                รายการเก่า
              </Typography>
              <VisitReferralItem referral={oldReferral} disableMenu={true} />
            </Box>
            <Box flex={1}>
              <Typography
                variant="h6"
                className="text-green-400"
                align="center"
              >
                รายการใหม่
              </Typography>
              <VisitReferralItem referral={newReferral} disableMenu={true} />
            </Box>
          </Box>
        </DialogContent>
      </Slide>
    </Dialog>
  );
};

export default ReferCompareDialog;
