import React from "react";
import { useForm, Controller } from "react-hook-form";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";

import { TextField } from "@mui/material";
import { IReasonRefer } from "@/types/reasonRefer";

interface ISettingReasonRefer {
  open: boolean;
  reason: IReasonRefer | null;
  onClose(): void;
  onSubmit(reason: string): void;
}
function SettingReasonRefer(props: ISettingReasonRefer) {
  const { open, reason, onClose, onSubmit } = props;
  const { handleSubmit, control } = useForm();
  const submit = ({ reasonRefer }: any) => {
    onSubmit(reasonRefer);
  };
  return (
    <Dialog
      fullWidth
      id="refer-reject-dialog"
      maxWidth="xs"
      open={open}
      onClose={() => onClose()}
    >
      <form onSubmit={handleSubmit((e) => submit(e))}>
        <DialogTitle>
          {reason
            ? "แก้ไขเหตุผลในการส่งต่อผู้ป่วย"
            : "สร้างเหตุผลในการส่งต่อผู้ป่วย"}
        </DialogTitle>

        <DialogContent>
          <Controller
            as={
              <TextField
                fullWidth
                multiline
                margin="dense"
                label="เหตุผลในการส่งต่อผู้ป่วย"
                variant="outlined"
                rows={3}
              />
            }
            control={control}
            name="reasonRefer"
            defaultValue={reason?.reason || ""}
          />
        </DialogContent>

        <DialogActions className="flex flex-col !px-5">
          <Button
            fullWidth
            type="submit"
            size="small"
            className="!bg-green-600 !text-white
          hover:!bg-green-500"
          >
            ยืนยัน
          </Button>

          <Button
            fullWidth
            size="small"
            className="!mt-3 !text-sky-600
            hover:!bg-transparent"
            onClick={() => onClose()}
          >
            ยกเลิก
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default SettingReasonRefer;
