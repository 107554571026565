import styled from "styled-components";

// mui
import MTPaper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";

export const HospitalFormContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: initial;
`;

export const Form = styled(MTPaper)`
  width: 500px;
  padding: 16px;
  margin: 4px;
  .title {
    margin: 16px 0 24px 0;
  }
  .form {
    display: flex;
    flex-direction: column;
    .input {
      margin: 8px 0;
    }
    .divider {
      margin: 16px 0 !important;
    }
    .billing {
      display: flex;
      justify-content: space-between;
    }
    .billing-item {
      margin-right: 8px;
    }
    .special {
      text-align: end;
      margin-right: 5px;
    }
    .action {
      display: flex;
      justify-content: flex-end;
      margin: 16px 0;
    }
    .cancel {
      margin-right: 16px;
    }
    .checkbox {
      margin-left: 0;
    }
  }
`;

export const useStyles = makeStyles(() => ({
  chipWrapper: {
    textAlign: "left",
  },
  chip: {
    marginRight: 8,
    marginTop: 4,
  },
}));
