import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import { Ability } from "@casl/ability";

// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
// import FilterListIcon from "@mui/icons-material/FilterList"

// style
import { Container } from "./UserManagement.style";
import { SearchInput } from "./UserManagement.style";

// apis
import DeleteUser from "../../../api/UserManagement/DeleteUser";

// actions
import { fetchUsers } from "../../../actions/UserManagement";

// components
// import Settings from "../../Settings/Settings";

// types
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../../constants/servicePermission";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function UserManagement() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { appData } = useSelector((state) => state);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(20);

  const [selectedRowData, setSelectedRowData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [inputValue, setInputValue] = useState("");

  const { idToken, users, permissionRules } = appData;

  const { t } = useTranslation("admin");
  const check = (
    permissionRules,
    servicePermissionEvent,
    servicePermissionService
  ) => {
    if (permissionRules) {
      const ability = new Ability(permissionRules);
      if (ability.can(servicePermissionEvent, servicePermissionService)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const handleClick = (event, tableMeta) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(tableMeta.rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearch = () => {
    const query = {
      idToken,
      filterText: inputValue,
    };
    handleFetchUsers(query);
  };

  const handleTextChange = (event) => {
    setInputValue(event.target.value);
    handleSearch();
  };

  const resetSearch = (tableState) => {
    const query = {
      idToken,
      filterList: tableState?.filterList,
    };
    setInputValue("");
    handleFetchUsers(query);
  };

  // const [anchorFilter, setAnchorFilter] = React.useState(null);

  // const handleClickFilter = (event) => {
  //   setAnchorFilter(event.currentTarget);
  // };

  // const handleCloseFilter = () => {
  //   setAnchorFilter(null);
  // };

  // const openFilter = Boolean(anchorFilter);

  const handleFetchUsers = ({
    page,
    limit,
    sortColumn,
    sortType,
    filterText,
    filterList,
  }) => {
    dispatch(
      fetchUsers({
        idToken,
        page,
        limit,
        sortColumn,
        sortType,
        filterText,
        filterList,
      })
    );
  };

  const handleDelete = async () => {
    setLoading(true);
    await DeleteUser({ idToken, username: modal.username });
    handleFetchUsers({});
    setLoading(false);
    setModal(false);
  };

  const canCreateUser = () => {
    return (
      check(
        permissionRules,
        ServicePermissionEvent.CREATE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.ANY_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.CREATE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.OWN_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.CREATE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.CHILD_HOSPITAL_USER
      )
    );
  };

  const canEditUser = () => {
    return (
      check(
        permissionRules,
        ServicePermissionEvent.UPDATE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.ANY_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.UPDATE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.OWN_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.UPDATE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.CHILD_HOSPITAL_USER
      )
    );
  };

  const canDeleteUser = () => {
    return (
      check(
        permissionRules,
        ServicePermissionEvent.DELETE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.ANY_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.DELETE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.OWN_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.DELETE,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.CHILD_HOSPITAL_USER
      )
    );
  };

  const canAccessUserList = () => {
    return (
      check(
        permissionRules,
        ServicePermissionEvent.READ,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.ANY_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.READ,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.OWN_HOSPITAL_USER
      ) ||
      check(
        permissionRules,
        ServicePermissionEvent.READ,
        ServicePermissionService.MANAGEMENT.USER_MANAGEMENT.CHILD_HOSPITAL_USER
      )
    );
  };

  useEffect(() => {
    handleFetchUsers({});
  }, []);

  const columns = [
    {
      name: "hospCode",
      label: "รหัส รพ.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "username",
      label: "ชื่อผู้ใช้",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "role",
      label: "สิทธิ์",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "status",
      options: {
        customBodyRender: (value) => (
          <div
            className={`border rounded w-fit p-1 ${
              value === "ACTIVE"
                ? "border-sky-300 bg-sky-50 text-sky-500"
                : value === "WAITING"
                ? "border-orange-300 bg-orange-50 text-orange-400"
                : "border-gray"
            }`}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => (
          <div className="button-wrapper">
            <IconButton
              disabled={!canEditUser() && !canDeleteUser()}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, tableMeta)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {canEditUser() && (
                <MenuItem
                  onClick={() =>
                    selectedRowData &&
                    history.push(
                      `/user-management/add-edit-user/${selectedRowData[1]}`,
                      {
                        user: users?.docs?.find(
                          (item) => item.username === selectedRowData[1]
                        ),
                      }
                    )
                  }
                >
                  Edit user
                </MenuItem>
              )}
              {canDeleteUser() && (
                <MenuItem
                  onClick={() =>
                    selectedRowData &&
                    setModal({ username: selectedRowData[1] })
                  }
                >
                  Delete User
                </MenuItem>
              )}
            </Menu>
          </div>
        ),
      },
    },
  ];

  const options = {
    filter: false,
    search: false,
    filterType: "dropdown",
    responsive: "scroll",
    serverSide: true,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: [20, 50, 100],
    count: users?.totalDocs,
    selectableRows: false,
    download: false,
    print: false,
    viewColumns: false,
    customToolbar: () => (
      <div>
        <SearchInput
          variant="outlined"
          placeholder="Search user by name,code"
          value={inputValue}
          onChange={handleTextChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: inputValue && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={resetSearch}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    ),
    onTableChange: (action, tableState) => {
      const query = {
        idToken,
        page: tableState.page + 1,
        limit: tableState?.rowsPerPage,
        sortColumn: tableState?.sortOrder?.name,
        sortType: tableState?.sortOrder?.direction,
        filterText: tableState?.searchText,
        filterList: tableState?.filterList,
      };

      switch (action) {
        case "changePage":
          handleFetchUsers(query);
          break;
        case "sort":
          handleFetchUsers(query);
          break;
        case "search":
          handleFetchUsers(query);
          break;
        case "filterChange":
        case "resetFilters":
          handleFetchUsers(query);
          break;
        case "changeRowsPerPage":
          setRowPerPage(tableState?.rowsPerPage);
          handleFetchUsers(query);
          break;
        default:
          break;
      }
    },
  };

  return (
    <Container className="mt-20">
      <Paper>
        <Box sx={{ width: "100%" }}>
          <div>
            {canAccessUserList() ? (
              <React.Fragment>
                <Box className="flex p-4 justify-center">
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontWeight: 700,
                      fontSize: "18px",
                      fontFamily: "Helvetica Neue",
                    }}
                  >
                    {t("management.usermanage")}
                  </Typography>
                </Box>
                <MUIDataTable
                  title={
                    canCreateUser() && (
                      <div className="table-title">
                        <Button
                          variant="outlined"
                          onClick={() =>
                            history.push("/user-management/add-edit-user")
                          }
                          style={{
                            padding: "12px 20px",
                            border: "1px solid",
                            borderRadius: 64,
                            fontSize: 14,
                            fontWeight: 700,
                            color: "#3F98D2",
                            height: 40,
                            marginRight: 24,
                          }}
                        >
                          <AddCircleIcon /> Create New User
                        </Button>
                      </div>
                    )
                  }
                  data={users?.docs?.map((user) => ({
                    ...user,
                    role: user?.role?.role,
                  }))}
                  columns={columns}
                  options={options}
                />
              </React.Fragment>
            ) : (
              history.push("/")
            )}
          </div>
        </Box>
        <Dialog open={modal} onClose={() => setModal(false)}>
          <DialogTitle>ลบผู้ใช้ {modal.username}</DialogTitle>
          <DialogContent>
            <DialogContentText>ยืนยันที่จะลบ ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              onClick={() => setModal(false)}
              color="primary"
            >
              ยกเลิก
            </Button>
            <Button disabled={loading} onClick={handleDelete} color="primary">
              ยืนยัน
            </Button>
          </DialogActions>
          {loading && <LinearProgress />}
        </Dialog>
      </Paper>
    </Container>
  );
}
