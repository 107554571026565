import axios from "axios";
import { getApiUrl } from "../functions/Commons";

export default async (email: string) => {
  try {
    const url = `${getApiUrl()}/user/reset-password`;
    const response = await axios.post(url, { email });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(
        error.response.data.message || "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"
      );
    }
    throw new Error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง");
  }
};
