import { Box, Typography } from "@mui/material";
import React from "react";
import { IDoctorNote } from "@/types/visit";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

function DoctorNoteItem({
  doctorNoteText,
  noteDateTime,
  doctorName,
}: IDoctorNote) {
  return (
    <Box className="bg-red-200 mx-4 my-4 px-2 py-2 rounded-lg">
      <Box className="border-b-2 border-red-300">
        {doctorNoteText.split("\r").map((item, index) => (
          <Typography className="text-red-900" key={index}>
            {item}
          </Typography>
        ))}
      </Box>
      <Box className="grid grid-cols-2 my-1">
        <Box>
          <Typography className="text-red-900">{`วันที่บันทึก : ${dateToLocalDateString(
            noteDateTime
          )}`}</Typography>
        </Box>
        <Box>
          <Typography className="text-red-900">{`แพทย์ :${doctorName}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default DoctorNoteItem;
