import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";

interface IAccordion {
  title: string;
  word: string;
  isExpand: boolean;
  onChange(event: React.SyntheticEvent<Element, Event>): void;
}
function AccordionReferral({ title, word, isExpand, onChange }: IAccordion) {
  const splitWord = () =>
    word.split("\r").map((item, index) => (
      <div key={index}>
        <h6>{`${index + 1}. ${item}`}</h6>
      </div>
    ));
  return (
    <Box className="my-3">
      <Accordion
        onChange={(event: React.SyntheticEvent<Element, Event>) =>
          onChange(event)
        }
        expanded={isExpand}
        sx={{ background: "#F3F4F6" }}
      >
        <AccordionSummary expandIcon={<ExpandMore />} className="m-0">
          <Box className="flex items-center m-0">
            <Typography variant="h6" color="#52A4CD" className="m-0">
              {title}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{splitWord()}</AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AccordionReferral;
