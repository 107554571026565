import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export default async ({ idToken }: { idToken: string }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/user/resendOTP`;

  return axios
    .post(url, {}, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
