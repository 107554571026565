import { ReferralType } from "../types/refers";
import { IReportResponse } from "../types/report";

export function getReportFromReferType({
  referType,
  report,
}: {
  referType: ReferralType;
  report: IReportResponse;
}) {
  const encounterReport =
    referType === ReferralType.REFER_IN
      ? report.fromHospital
      : report.toHospital;

  return encounterReport;
}

export function calculateReportHeight({
  categoriesLength,
}: {
  categoriesLength: number;
}) {
  return categoriesLength * 40 + 100;
}
