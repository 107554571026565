import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export const deleteSampleData = async (token: string, dateTime: string) => {
  const url = getApiUrl();
  let routePath = `${url}/v1/sample`;
  if (dateTime !== "") {
    routePath = `${url}/v1/sample?startDay=${dateTime}`;
  }
  await axios.delete(routePath, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
