import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

const deleteDepartment = async (token: string, id: string) => {
  const url = getApiUrl();
  await axios.delete(`${url}/v1/department/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export default deleteDepartment;
