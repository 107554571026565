import pdfMake from "pdfmake/build/pdfmake";
import bahttext from "./BAHTTEXT";
import pdfFonts from "../fonts/vsf_fonts";
import { hospDataFromHospCode } from "../../functions/FuncPerjer";
import { dataUrl } from "../dataUrl";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
};
function thaiNumber(num) {
  const array = {
    1: "๑",
    2: "๒",
    3: "๓",
    4: "๔",
    5: "๕",
    6: "๖",
    7: "๗",
    8: "๘",
    9: "๙",
    0: "๐",
  };
  let str = num.toString();

  Object.entries(array).forEach(([arabic, thai]) => {
    str = str.split(arabic).join(thai);
  });

  return str;
}

function printUC(data, toHospName, datepdf, fromHospCode) {
  const thismonth = thaiNumber(
    new Date().toLocaleDateString("th-TH", { month: "long", year: "numeric" })
  );
  const billmonthandyear = thaiNumber(
    datepdf.toLocaleDateString("th-TH", { month: "long", year: "numeric" })
  );
  const totalpatient = thaiNumber(data.totalBills);
  const finalprice = thaiNumber(
    Number(data.finalTotal)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
  );
  const bahttexteiei = bahttext({ num: data.finalTotal });

  const fromHospData = hospDataFromHospCode(fromHospCode);
  const docDefinition = {
    content: [
      {
        columns: [
          {
            text: `\n\nที่  สป ๐๐๓๒.${fromHospData?.headerNumber}/`,
            style: "header",
          },
          {
            image: dataUrl(),
            width: 80,
            height: 80,
          },
          [
            { text: `\n\n${fromHospData?.hospName || ""}`, style: "tab" },
            { text: fromHospData?.address[0] || "", style: "tab" },
            { text: fromHospData?.address[1] || "", style: "tab" },
          ],
        ],
      },
      {
        text: thismonth,
        style: "date",
      },
      {
        text: "เรื่อง ขอรับเงินค่าบริการทางการแพทย์ผู้ป่วยนอก สิทธิประกันสุขภาพถ้วนหน้า ",
        style: "subject",
      },
      { text: `เรียน ผู้อำนวยการ${toHospName}`, style: "to" },
      {
        text: [
          { text: ".                ", color: "white" },
          {
            text: `${fromHospData?.hospName} ขอเบิกเงินค่ารักษาพยาบาลผู้ป่วยโครงการบัตรประกันสุขภาพถ้วนหน้า ของ ${toHospName} ที่มารับบริการ ณ ${fromHospData?.hospName} ประจำเดือน ${billmonthandyear} จำนวน ${totalpatient} ราย เป็นจำนวนเงิน ${finalprice} บาท (${bahttexteiei})`,
          },
        ],
      },
      {
        text: [
          { text: ".                ", color: "white" },
          {
            text: `โปรดสั่งจ่ายค่ารักษาพยาบาลผ่าน ${
              fromHospData?.bank?.uc?.bankName || ""
            } ชื่อบัญชี "${fromHospData?.bank?.uc?.accountName || ""}" เลขที่ ${
              fromHospData?.bank?.uc?.accountNumber || ""
            }`,
          },
        ],
      },
      {
        text: [
          { text: ".                ", color: "white" },
          { text: "จึงเรียนมาเพื่อโปรดทราบและพิจารณาดำเนินการต่อไป" },
        ],
      },
      {
        columns: [
          {},
          [
            { text: "\n\nขอแสดงความนับถือ", style: "main4" },
            {
              text: `\n\n(${fromHospData?.signature?.name || ""})`,
              style: "main5",
            },
            { text: fromHospData?.signature?.position || "", style: "main6" },
            {
              text: fromHospData?.signature?.subPosition || "",
              style: "main7",
            },
          ],
        ],
      },
      {
        stack: [
          `\n\n\n\n\n\n${fromHospData?.footer?.name || ""}`,
          {
            text:
              fromHospData?.footer?.tel && `โทร. ${fromHospData?.footer?.tel}`,
          },
          {
            text:
              fromHospData?.footer?.fax &&
              `โทรสาร. ${fromHospData?.footer?.fax}`,
          },
          {
            text:
              fromHospData?.footer?.copy &&
              `สำเนาส่ง ${fromHospData?.footer?.copy}`,
          },
        ],
        style: "footer",
      },
    ],
    defaultStyle: {
      font: "THSarabunNew",
      fontSize: 16,
    },
    styles: {
      header: { alignment: "left" },
      date: { alignment: "right", margin: [0, 0, 122, 0] },
      tab: { margin: [60, 0, 0, 0] },
      main4: { alignment: "center" },
      main5: { alignment: "center" },
      main6: { alignment: "center" },
      main7: { alignment: "center" },
    },
  };
  pdfMake.createPdf(docDefinition).open();
}

export default printUC;
