import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

interface IEditBillItems {
  editedBillItemList: {
    billItemId: string;
    latestFinalPrice: number;
    message: string;
  }[];
  visitId: string;
  idToken: string;
  categoryId: string;
  visitHospCode: string;
}

const editBillItems = async ({
  editedBillItemList,
  visitId,
  idToken,
  categoryId,
  visitHospCode,
}: IEditBillItems) => {
  const url = `${getApiUrl()}/v1/billings/editBillItem`;

  try {
    const response = await axios.put(
      url,
      { editedBillItemList, visitId, categoryId, visitHospCode },
      { headers: { Authorization: `Bearer ${idToken}` } }
    );

    return {
      ...response.data,
      message: "แก้ไขบิลไอเท็มเรียบร้อย",
      stausMessage: 200,
    };
  } catch (error) {
    return {
      message: "แก้ไขบิลไอเท็มล้มเหลว",
      stausMessage: 400,
    };
  }
};

export default editBillItems;
