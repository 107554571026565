import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export const generateData = async (
  token: string,
  fromHospCode: string,
  toHospCode: string,
  hospName: string,
  visitDateTime: Date,
  totalGen: number
) => {
  const url = getApiUrl();
  const body = {
    fromHospCode,
    toHospCode,
    hospName,
    visitDateTime,
    totalGen,
  };
  await axios.post(`${url}/v1/sample/`, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
