import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// mui
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

class AppBackdrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    const { appData } = this.props;

    if (appData && appData.FetchingStatus) {
      if (open !== true) {
        setTimeout(() => {
          this.handleOpen();
        }, 100);
      }
    } else if (open === true) {
      setTimeout(() => {
        this.handleClose();
      }, 500);
    }

    return (
      <div>
        <Backdrop open={open} className="app-backdrop">
          <CircularProgress
            color={
              localStorage.getItem("theme") === "dark" ? "inherit" : "secondary"
            }
            size={80}
          />
        </Backdrop>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appData: state.appData,
  };
}

AppBackdrop.propTypes = {
  appData: PropTypes.shape({
    FetchingStatus: PropTypes.bool,
  }).isRequired,
};

export default connect(mapStateToProps)(AppBackdrop);
