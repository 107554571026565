import React, { useState } from "react";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Tooltip,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import LabReportItem from "./LabReportItem";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

function LabResultItem({ labResult }: any) {
  const { labReportData, labHeadData } = labResult;

  const labReportItem = (labReport: Array<any>) =>
    labReport.map((report, reportIndex: number) => (
      <LabReportItem
        key={reportIndex}
        labItemsNameRef={report?.labItemsNameRef}
        labOrderResult={report?.labOrderResult}
        labItemsNormalValueRef={report?.labItemsNormalValueRef}
      />
    ));

  const [isExpanded, setIsExpanded] = useState(false);
  const onChange = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <Box className="my-2">
      <Accordion
        sx={{ background: "#F5FAFD", borderRadius: "8px" }}
        onChange={onChange}
        expanded={isExpanded}
      >
        <AccordionSummary expandIcon={<ArrowDropDown />}>
          <Box display={"flex"} alignItems={"center"}>
            {labHeadData?.confirmReport && (
              <Box className="mx-2">
                <Tooltip
                  title={`Confirm Report : ${labHeadData.confirmReport}`}
                >
                  {labHeadData.confirmReport === "N" ? (
                    <NewReleasesIcon color="error" fontSize="small" />
                  ) : (
                    <VerifiedIcon color="success" fontSize="small" />
                  )}
                </Tooltip>
              </Box>
            )}
            <Typography
              color="#52A4CD"
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {`Lab ${labHeadData?.formName || "-"} (${dateToLocalDateString(
                labHeadData?.orderDateTime
              )})`}
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Box>
            {/* <Box className="mx-8 my-3">
              <Typography variant="body2" color="#555555">{`Lab Report: ${
                labHeadData?.labOrderNumber || "-"
              } | Confirm Report: ${
                labHeadData?.confirmReport || "-"
              }`}</Typography>
              <Typography variant="body2" color="#555555">{`Lab Form: ${
                labHeadData?.formName || "-"
              }`}</Typography>
              <Typography
                variant="body2"
                color="#555555"
              >{`Order Date: ${dateToLocalDateString(
                labHeadData?.orderDateTime
              )}`}</Typography>
            </Box> */}
            <div className="grid grid-cols-3 bg-gray-100 px-5 py-5">
              <div>
                <Typography
                  variant="subtitle2"
                  color="rgba(0, 0, 0, 0.55)"
                  style={{ wordWrap: "break-word" }}
                >
                  LAB NAME
                </Typography>
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  color="rgba(0, 0, 0, 0.55)"
                  style={{ wordWrap: "break-word" }}
                >
                  RESULT
                </Typography>
              </div>
              <div>
                <Typography
                  variant="subtitle2"
                  color="rgba(0, 0, 0, 0.55)"
                  style={{ wordWrap: "break-word" }}
                >
                  RESULT NORMAL
                </Typography>
              </div>
            </div>
            {labReportData?.length > 0 && labReportItem(labReportData)}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default LabResultItem;
