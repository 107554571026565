import { AlertColor } from "@mui/material";
import axios from "axios";
import { getApiUrl } from "../../../functions/Commons";

interface ISourceAttachment {
  contentType: string;
  base64Data: string;
  description: string;
  file: File;
}
interface IResponseAttactment {
  status: AlertColor;
  message: string;
}
const uploadFileToStorage = async (
  sourceAttachments: ISourceAttachment[],
  referralDocId: string,
  token: string
): Promise<IResponseAttactment> => {
  try {
    const filetypes = /image/;
    const formData = new FormData();
    sourceAttachments
      .filter((attachment) => filetypes.test(attachment.contentType))
      .forEach((sources) => {
        formData.append("images", sources.file);
        formData.append("descriptionAttachment", sources.description);
      });
    sourceAttachments
      .filter((attachment) => !filetypes.test(attachment.contentType))
      .forEach((sources) => {
        formData.append("others", sources.file);
        formData.append("descriptionAttachmentPDF", sources.description);
      });
    formData.append("referralDocId", referralDocId);
    const url = `${getApiUrl()}/v1/refer/storage/upload`;
    await axios.post(url, formData, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return {
      status: "success",
      message: "attachment success",
    };
  } catch (error: any) {
    console.log(error);
    return {
      status: "error",
      message: error.message,
    };
  }
};
export default uploadFileToStorage;
