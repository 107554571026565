import React from "react";
import { IReportResponse } from "@/types/report";
import Box from "@mui/material/Box";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import ReportDistrict from "./ReportDistrict";

interface IContainerReportDistrict {
  startDate: Date;
  endDate: Date;
  report: IReportResponse;
}

export default function ContainerReportDistrict({
  startDate,
  endDate,
  report,
}: IContainerReportDistrict) {
  return report && report.districtICD ? (
    <Box>
      <Swiper
        style={{ height: "100%" }}
        modules={[Pagination, A11y]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {Object.keys(report.districtICD).map((key) => {
          return (
            <SwiperSlide>
              <ReportDistrict
                districtName={key}
                report={report}
                startDate={startDate}
                endDate={endDate}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  ) : null;
}
