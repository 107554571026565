import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import TDepartment from "../../types/department";

const fetchDepartments = async (
  token: string,
  hospCode?: string
): Promise<TDepartment[]> => {
  const url = getApiUrl();
  let departmentURL = `${url}/v1/department`;
  if (hospCode) {
    departmentURL += `?hospCode=${hospCode}`;
  }
  const result = await axios.get(departmentURL, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result.data;
};

export default fetchDepartments;
