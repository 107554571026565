import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// mui
import {
  InputAdornment,
  FormControl,
  OutlinedInput,
  IconButton,
  AppBar,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { Search, Close } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

// json
import reportJson from "./report.json";
import alertJson from "./alert.json";

// types
import { IReportJson } from "./type";

interface IAlert {
  eventdatetime: string;
  refer_status: string;
  refer_icd: string;
  refer_icdname: string;
  refer_fromhospcode: string;
  refer_tohospcode: string;
}
function PopulateHealthHome() {
  const history = useHistory();
  const mainMap = reportJson.find((report) => report.category === "Main Map");
  const [reports, setResports] = useState<IReportJson[]>(reportJson);
  const [search, setSearch] = useState<string>("");
  const [isSeaching, setIsSearching] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<IAlert[]>([]);
  const [colorShow, setColorShow] = useState<boolean>(false);
  const [isInfectiousExpanded, setIsInfectiousExpanded] = useState(false);
  const [isNoncommunicableExpanded, setIsNoncommunicableExpanded] =
    useState(false);
  const [isOncologyExpanded, setIsOncologyExpanded] = useState(false);
  const [infectiousReport, setInfectiousReport] = useState<IReportJson[]>([]);
  const [noncommunicableReport, setNoncommunicableReport] = useState<
    IReportJson[]
  >([]);
  const [oncologyReport, setOncologyReport] = useState<IReportJson[]>([]);

  useEffect(() => {
    setTimeout(() => {
      randomAlert();
    }, 6000);
  }, [alerts]);

  useEffect(() => {
    setTimeout(() => {
      setColorShow(!colorShow);
    }, 1000);
  }, [colorShow]);

  useEffect(() => {
    setInfectiousReport(
      reports.filter(
        (report) => report.category === "Infectious diseases report"
      )
    );

    setNoncommunicableReport(
      reports.filter(
        (report) => report.category === "Non-communicable disease report"
      )
    );

    setOncologyReport(
      reports.filter((report) => report.category === "Oncology report")
    );
  }, []);

  const categoryMap = (reports: IReportJson[]) => {
    return (
      <div
        className="my-6
        md:grid md:grid-cols-3 md:gap-4"
      >
        {reports.map((report, index) => (
          <div
            key={index}
            className="mt-3 rounded-lg bg-white h-52 flex flex-col items-center pt-2 shadow-md
              lg:mt-0"
            onClick={() => history.push(`/report-link/detail/${report.id}`)}
          >
            <div className="h-full">
              <iframe src={report.thumbnail} width="100%" height="100%" />
            </div>
            <p
              className="text-sm text-gray-600 text-center
                md:text-base"
            >
              {report.title}
            </p>
          </div>
        ))}
      </div>
    );
  };
  const randomAlert = () => {
    const index = Math.floor(Math.random() * alertJson.length);
    const alert: IAlert = alertJson[index];
    setAlerts((alerts) => (alerts.length >= 2 ? [alert] : [...alerts, alert]));
  };
  const onSearch = () => {
    if (search !== "") {
      setIsSearching(true);
      setResports((reports) =>
        reports.filter(
          (report) =>
            report.title.toUpperCase().indexOf(search.toUpperCase()) > -1
        )
      );
    }
  };
  const onClose = () => {
    setIsSearching(false);
    setSearch("");
    setResports(reportJson);
  };

  return (
    <>
      <AppBar id="report-navbar" position="static" className="mt-16 !bg-white">
        <Toolbar variant="dense" className="py-4">
          <div className="flex flex-grow items-center">
            <h5
              className=" text-gray-700 mr-8 font-bold
              lg:text-xl"
            >
              Samut prakan Smart Healthcare
            </h5>
          </div>
        </Toolbar>
      </AppBar>
      <div
        className="bg-gradient-to-b from-slate-200 to-slate-100 p-2
        lg:p-6"
      >
        <div
          className="
          lg:grid lg:grid-cols-3 lg:gap-4"
        >
          {/* col 1 */}
          <div className="bg-neutral-50 rounded-2xl col-span-2 p-4 shadow-md">
            <FormControl fullWidth size="small">
              <OutlinedInput
                placeholder="Search report name"
                value={search}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setSearch(event.target.value)
                }
                startAdornment={
                  <InputAdornment position="start">
                    {isSeaching ? (
                      <IconButton onClick={() => onClose()}>
                        <Close />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => onSearch()}>
                        <Search />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                className="bg-zinc-100 !rounded-full"
              />
            </FormControl>

            <div className="grid grid-flow-row auto-rows-max my-2">
              <div className="mt-2">
                <b className="text-gray-600">Infectious diseases reports</b>
                {isInfectiousExpanded
                  ? categoryMap(infectiousReport)
                  : categoryMap(infectiousReport.slice(0, 6))}

                {infectiousReport.length > 6 && !isInfectiousExpanded && (
                  <div className="flex justify-center">
                    <ExpandCircleDownIcon
                      fontSize="large"
                      className="text-sky-700 shadow-lg rounded-full shadow-sky-700/50 cursor-pointer animate-bounce"
                      onClick={() => setIsInfectiousExpanded(true)}
                    />
                  </div>
                )}
              </div>
              <div>
                <b className="text-gray-600">
                  Non-communicable disease reports
                </b>
                {isNoncommunicableExpanded
                  ? categoryMap(noncommunicableReport)
                  : categoryMap(noncommunicableReport.slice(0, 6))}

                {noncommunicableReport.length > 6 &&
                  !isNoncommunicableExpanded && (
                    <div className="flex justify-center">
                      <ExpandCircleDownIcon
                        fontSize="large"
                        className="text-sky-700 shadow-lg rounded-full shadow-sky-700/50 cursor-pointer animate-bounce"
                        onClick={() => setIsNoncommunicableExpanded(true)}
                      />
                    </div>
                  )}
              </div>
              <div>
                <b className="text-gray-600">Oncology reports</b>
                {isOncologyExpanded
                  ? categoryMap(oncologyReport)
                  : categoryMap(oncologyReport.slice(0, 6))}

                {oncologyReport.length > 6 && !isOncologyExpanded && (
                  <div className="flex justify-center">
                    <ExpandCircleDownIcon
                      fontSize="large"
                      className="text-sky-700 shadow-lg rounded-full shadow-sky-700/50 cursor-pointer animate-bounce"
                      onClick={() => setIsOncologyExpanded(true)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* col 2 */}
          <div>
            {/* map */}
            <div
              className="mt-3 h-64 shadow-md
              lg:mt-0"
            >
              <iframe
                src={mainMap?.thumbnail}
                className="h-full w-full rounded-xl"
              />
            </div>

            <div className="bg-white rounded-xl mt-4 p-4 h-64 shadow-md">
              <div>
                <div className="border w-16 flex justify-center items-center rounded-xl">
                  <FiberManualRecordIcon className="!text-sm mr-1 text-green-500" />
                  Live
                </div>
              </div>

              {alerts.map((alert, index) => (
                <div key={index} className="my-2 mx-2">
                  <div className="flex justify-end">
                    <p
                      className="text-sm border border-amber-500 text-amber-500 bg-amber-50 rounded-md px-2
                      md:text-base"
                    >
                      {alert.refer_status}
                    </p>
                  </div>

                  <p
                    className="text-sm text-stone-500
                    md:text-base"
                  >{`${alert.refer_icd} - ${alert.refer_icdname}`}</p>

                  <p
                    className="text-sm text-stone-500
                    md:text-base"
                  >{`from ${alert.refer_fromhospcode} to ${alert.refer_tohospcode}`}</p>
                </div>
              ))}
            </div>

            <div className="mt-4 h-80 bg-white rounded-xl shadow-md">
              <iframe
                src="https://ever-outbreak-map.herokuapp.com/map2.html"
                className="h-full w-full rounded-xl"
                allowFullScreen
              />
            </div>
            <div className="mt-4 h-80 bg-white rounded-xl shadow-md relative overflow-scroll">
              <iframe
                style={{ minWidth: "750px" }}
                src="https://ever-outbreak-map.herokuapp.com/graph.html"
                className="h-full w-full rounded-xl absolute top-0 right-0 bottom-0 left-0"
                allowFullScreen
              />
            </div>
            <div className="mt-4 h-80 shadow-md min-w-min relative overflow-scroll rounded-xl">
              <iframe
                style={{ minWidth: "650px", minHeight: "500px" }}
                src="https://cy2neo.ai-nonprod.everapp.io/"
                className="h-full w-full rounded-xl absolute top-0 right-0 bottom-0 left-0"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopulateHealthHome;
