import React from "react";

// mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

// functions
import {
  DateTimeNow,
  ThaiDateTime,
  diffMinutes,
} from "../../functions/FuncDateTimes";

// styles
import "../../styles/onlineHospital/OnlineHospitalCard.scss";

interface IOnlineHospitalCard {
  title: string;
  cardIndex: number;
  gatewayVersion: string;
  lastActive?: string;
}

export default function OnlineHospitalCard({
  title,
  cardIndex,
  gatewayVersion,
  lastActive,
}: IOnlineHospitalCard) {
  const df = diffMinutes(lastActive, DateTimeNow());

  return (
    <Card id="online-hospital-card" variant="outlined" className="!rounded-xl">
      <CardHeader
        title={title}
        avatar={
          <Avatar aria-label="recipe" className="refer-type-icon-1">
            {cardIndex}
          </Avatar>
        }
        action={
          <div className="text-sm font-bold mt-3 mx-2">
            {df > 60 && df !== 999999 && (
              <>
                <FiberManualRecordIcon className="!text-sm mr-1 text-red-500" />
                Offline
              </>
            )}
            {df <= 10 && (
              <>
                <FiberManualRecordIcon className="!text-sm mr-1 text-green-500" />
                Online
              </>
            )}
          </div>
        }
      />
      <CardContent>
        <Typography variant="body1" component="p">
          Gateway version : {gatewayVersion}
        </Typography>

        <Typography variant="body1" component="p" className="text-sm">
          Active : {ThaiDateTime(lastActive)}
        </Typography>
        <Typography variant="body1" component="p">
          Diff : {df} Minutes
        </Typography>
      </CardContent>
    </Card>
  );
}
