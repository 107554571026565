import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {
  Table,
  TableBody,
  TableHead,
  Grid,
  Button,
  TablePagination,
  Paper,
  Box,
} from "@mui/material";
import { Container, TableRow, TableCell, Status } from "./styles";
import AutocompleteRule from "./container/AutocompleteRule";
import AutocompleteHospital from "./container/AutocompleteHospital";
import { actionInvoicesList } from "../../actions/InvoiceAction";
import { getInvoiceStatusTranslate } from "../../functions/FuncPerjer";
import { ICategoryRule, IHospital, IInvoiceDoc } from "./type";

function InvoiceList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rules, setRules] = useState<ICategoryRule | null>(null);
  const [hospital, setHospital] = useState<IHospital | null>(null);
  const { appData, invoices }: any = useSelector((state) => state);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const {
    idToken,
    loginData: { hospCode },
  } = appData;
  const isProvider = history.location.pathname.indexOf("/to") !== -1;

  const fetchInvoiceList = () => {
    // provider === hospcode ของคนที่ล็อกอิน (เรียกเก็บ)
    // payer === hospcode ของ รพ.ที่เราเลือก (เรียกเก็บ)
    // ถ้าเป็นตามจ่ายจะสลับกัน
    // hospCode ก็ต้องเป็นของ รพ.เรียกเก็บ
    const params = {
      idToken,
      categoryId: rules?.id,
      provider: isProvider ? hospCode : hospital?.hospCode,
      payer: isProvider ? hospital?.hospCode : hospCode,
      page: page + 1,
      limit: rowsPerPage,
    };
    dispatch(actionInvoicesList(params));
  };
  useEffect(() => {
    if (hospital && rules) {
      fetchInvoiceList();
    }
  }, [idToken, hospCode, hospital, rules, page, rowsPerPage]);
  useEffect(() => {
    setHospital(null);
    setRules(null);
  }, [history.location]);

  const onChangeRules = (value: ICategoryRule) => {
    setRules(value);
  };
  const onChangeHospital = (value: IHospital) => {
    setHospital(value);
  };
  const onClicktoDetail = (
    id: string,
    categoryId: string,
    payer: string,
    provider: string
  ) => {
    if (id && isProvider) {
      history.push(
        `/billing-charge/to/detail/${provider}/${payer}/${id}/${categoryId}`
      );
    }
    if (id && !isProvider) {
      history.push(
        `/billing-charge/from/detail/${provider}/${payer}/${id}/${categoryId}`
      );
    }
    if (!id && categoryId) {
      history.push(
        `/billing-charge/none/detail/${provider}/${payer}/${categoryId}`
      );
    }
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };
  return (
    <Container className="!mt-20">
      <Box className="header" display={"flex"} alignItems={"center"}>
        <EventNoteIcon />
        <h5 className="title">{isProvider ? "เรียกเก็บ" : "ตามจ่าย"}</h5>
      </Box>
      {isProvider && (
        <Grid container spacing={2} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/create-invoice")}
          >
            สร้าง invoice
          </Button>
        </Grid>
      )}
      <Grid container spacing={1} justifyContent="center" marginBottom={4}>
        <Grid item xs={4}>
          <AutocompleteRule
            onChangeRules={onChangeRules}
            isProvider={isProvider}
            hospCode={hospCode}
          />
        </Grid>
        <Grid item xs={4}>
          <AutocompleteHospital onChangeHospital={onChangeHospital} />
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">เลข invoice</TableCell>
            <TableCell align="center">เดือน</TableCell>
            <TableCell align="right">จำนวนผู้ป่วย</TableCell>
            <TableCell align="right">ยอดเงินตามจริง (฿)</TableCell>
            <TableCell align="right">ยอดที่คำนวณ (฿)</TableCell>
            <TableCell align="right">ยอดที่เรียกเก็บ (฿)</TableCell>
            <TableCell align="right">สถานะ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hospital && rules && invoices.docs && invoices?.docs?.length > 0 ? (
            invoices?.docs?.map((row: IInvoiceDoc) => (
              <TableRow
                key={row.id || row.categoryId}
                onClick={() =>
                  onClicktoDetail(
                    row.id,
                    row.categoryId,
                    row?.payer,
                    row?.provider
                  )
                }
              >
                <TableCell align="center">
                  {row.id || "ยังไม่ได้ออกเลข invoice"}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                  {row.createdAt
                    ? new Date(row.createdAt)?.toLocaleDateString("th-TH", {
                        month: "short",
                        year: "numeric",
                      })
                    : " "}
                </TableCell>
                <TableCell align="right">
                  {row?.totalBills?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  {row?.actualTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  {row?.calculatedTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  {row?.finalTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  <Status color={getInvoiceStatusTranslate(row.status)?.color}>
                    {getInvoiceStatusTranslate(row?.status)?.text}
                  </Status>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center" component="th" scope="row">
                ไม่พบข้อมูล
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={invoices?.totalDocs || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default InvoiceList;
