import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default ({
  idToken,
  roleId,
  event,
  service,
}: {
  idToken: string;
  roleId: string;
  event: string;
  service: string;
}) => {
  const url = `${getApiUrl()}/v1/servicePermission/changeStatus`;

  const body = {
    roleId,
    event,
    service,
  };

  return axios.put(url, body, {
    headers: { Authorization: `Bearer ${idToken}` },
  });
};
