import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "dayjs/locale/th";
import { Grid, Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DatePicker from "@mui/lab/DatePicker";
import { CreateInvoiceContainer, Content } from "./styles";
import ConfirmModal from "./container/ConfirmModal";
import { actionConfirmData } from "../../actions/InvoiceAction";
import { ICategoryRule, IHospital } from "./type";
import AutocompleteHospital from "./container/AutocompleteHospital";
import AutocompleteRule from "./container/AutocompleteRule";

function CreateInvoice() {
  const dispatch = useDispatch();
  const { appData, invoices }: any = useSelector((state) => state);
  const { idToken, hospCode } = appData;
  const [selectedDate, setSelectedDate] = useState<Date | any>(new Date());
  const [open, setOpen] = useState(false);
  const [hospital, setHospital] = useState<IHospital | null>(null);
  const [rule, setRule] = useState<ICategoryRule | null>(null);

  const onCloseModal = () => {
    setOpen(false);
  };
  const onChangeHospital = (value: IHospital) => {
    setHospital(value);
  };
  const onChangeRules = (value: ICategoryRule) => {
    setRule(value);
  };
  const convertDate = (date: Date | null) => date?.toISOString().split("T")[0];
  const onCreateInvoice = () => {
    const query = {
      idToken,
      payer: hospital?.hospCode,
      date: convertDate(new Date(selectedDate?.$d || selectedDate)),
      categoryId: rule?._id,
    };
    dispatch(actionConfirmData(query));
    setOpen(true);
  };

  return (
    <CreateInvoiceContainer>
      <Content>
        <Grid container justifyContent="center" spacing={2} maxWidth={550}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom component="div">
              สร้าง Invoice
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DatePicker
              views={["year", "month"]}
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              InputProps={{
                endAdornment: <CalendarTodayIcon className="text-gray-500" />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteHospital onChangeHospital={onChangeHospital} />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteRule
              onChangeRules={onChangeRules}
              isProvider
              hospCode={hospCode}
            />
          </Grid>
          <Grid container justifyContent="flex-end" marginTop={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onCreateInvoice}
              disabled={!rule || !hospital}
            >
              สร้างอินวอยซ์
            </Button>
          </Grid>
        </Grid>
      </Content>
      {open && invoices.hospCode && rule && hospital && (
        <ConfirmModal
          open={open}
          onCloseModal={onCloseModal}
          date={selectedDate?.$d || selectedDate}
          hospital={hospital}
          category={rule || "ประกันสังคม"}
          detail={invoices}
          token={idToken}
          providerHospCode={hospCode}
        />
      )}
    </CreateInvoiceContainer>
  );
}

export default CreateInvoice;
