import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

const deleteClinic = async (token: string, id: string) => {
  const url = getApiUrl();
  await axios.delete(`${url}/v1/clinic/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export default deleteClinic;
