import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useState } from "react";

interface IModalDeleteData {
  open: boolean;
  onClose(): void;
  onDelete(date?: string): void;
  dateList: string[];
  successStatus: boolean | null;
  onChangeStatusNull(): void;
}

export const ModalDeleteData = ({
  open,
  onClose,
  onDelete,
  dateList,
  successStatus,
  onChangeStatusNull,
}: IModalDeleteData) => {
  const [selectDate, setSelectDate] = useState("");
  const [loading, setLoading] = useState(false);

  const onLoading = (status: boolean) => setLoading(status);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "500px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="grid grid-cols-2 gap-4">
          <h2 className="text-red-600 my-3">ลบข้อมูลตัวอย่าง</h2>
          {successStatus !== null && (
            <h2
              className={
                successStatus ? "text-green-600 my-3" : "text-red-500 my-3"
              }
            >
              {successStatus
                ? "ลบข้อมูลตัวอย่างเรียบร้อยแล้ว"
                : "ลบข้อมูลตัวอย่างล้มเหลว"}
            </h2>
          )}
        </div>
        <div className="w-96">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              วันที่มีการส่งต่อผู้ป่วย(ตัวอย่าง)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectDate}
              label="date"
              onChange={(event: SelectChangeEvent) => {
                setSelectDate(event.target.value as string);
                onChangeStatusNull();
              }}
            >
              {dateList.map((date) => (
                <MenuItem key={date} value={date}>
                  {new Date(date).toLocaleDateString("th-TH")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="flex justify-center my-4">
          <Button
            disabled={loading}
            onClick={() => {
              onLoading(true);
              try {
                onDelete(selectDate);
                setSelectDate("");
                onLoading(false);
              } catch (error) {
                onLoading(false);
              }
            }}
            variant="outlined"
            color="error"
          >
            delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
