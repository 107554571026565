import { Box, Typography } from "@mui/material";
import React from "react";
import { IDental } from "@/types/visit";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

function DentalItem({
  tmname,
  tcount,
  rcount,
  scount,
  qtyCount,
  icd,
  icd9,
  doctorName,
  visitDateTime,
  note,
  dentalNote,
}: IDental) {
  return (
    <Box className="mx-4 my-2 px-4 py-2 bg-cyan-50 rounded-lg">
      <Box>
        <Typography className="text-cyan-800">{`List : ${
          tmname || "-"
        }`}</Typography>
      </Box>
      <Box className="grid grid-cols-4">
        <Typography className="text-cyan-800">{`ผลงาน : ${
          tcount || "-"
        } ซี่`}</Typography>
        <Typography className="text-cyan-800">{`${
          scount || "0"
        } ด้าน`}</Typography>
        <Typography className="text-cyan-800">{`${
          rcount || "0"
        } ราก`}</Typography>
        <Typography className="text-cyan-800">{`${
          qtyCount || "0"
        } ชิ้น`}</Typography>
      </Box>
      <Box className="grid grid-cols-2">
        <Typography className="text-cyan-800">{`ICD10 : ${
          icd || "-"
        }`}</Typography>
        <Typography className="text-cyan-800">{`ICD9 : ${
          icd9 || "-"
        }`}</Typography>
      </Box>
      <Box className="grid grid-cols-2">
        <Typography className="text-cyan-800">{`Doctor : ${
          doctorName || "-"
        }`}</Typography>
        <Typography className="text-cyan-800">{`Date : ${dateToLocalDateString(
          visitDateTime
        )}`}</Typography>
      </Box>
      <Box>
        <Typography className="text-cyan-800">{`หมายเหตุ : ${
          note || "ไม่ระบุ"
        }`}</Typography>
      </Box>
      <Box>
        <Typography className="text-cyan-800">{`Note  : ${
          dentalNote || "ไม่ระบุ"
        }`}</Typography>
      </Box>
    </Box>
  );
}

export default DentalItem;
