import axios from "axios";
import { getApiUrl } from "../functions/Commons";
import { IReferral } from "../types/refers";

type IFetchDoctorParams = {
  token: string;
  referId: string;
};
const fetchReferral = async ({
  token,
  referId,
}: IFetchDoctorParams): Promise<IReferral> => {
  const url = getApiUrl();
  const result = await axios.get(`${url}/v1/refer/${referId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return result.data;
};

export default fetchReferral;
