import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { ILinkListItemProps } from "./type";
import { useTranslation } from "react-i18next";

function LinkListItem({ text, Icon, link, toggleDrawer }: ILinkListItemProps) {
  const history = useHistory();
  const { t } = useTranslation("common");
  const [PageLocation, setPageLocation] = useState("");

  const replaceLocation = (st: string) => {
    const re = /-/gi;
    return st.replace(re, "");
  };

  const setPageLocationFc = () => {
    setPageLocation(history.location.pathname);
  };
  useEffect(() => {
    setPageLocationFc();
  });
  return (
    <ListItem
      button
      component={NavLink}
      to={link.toString()}
      exact
      className={`my-2 ${
        replaceLocation(PageLocation).indexOf(
          replaceLocation(link.toString())
        ) === 0 && "ListItem-active"
      } `}
      activeClassName="ListItem-active"
      onClick={toggleDrawer("left", false)}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={t(text)} />
    </ListItem>
  );
}

export default LinkListItem;
