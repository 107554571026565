import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export const fetchItemRules = async (body) => {
  const { idToken, categoryId, hospCode } = body;
  try {
    const result = await axios.get(
      `${getApiUrl()}/v1/billing/settings/${hospCode}/${categoryId}`,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return result.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteItemRule = async (body) => {
  const { idToken, itemId } = body;
  try {
    const result = await axios.delete(
      `${getApiUrl()}/v1/billing/settings/itemrules/${itemId}`,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return result.data;
  } catch (error) {
    throw new Error(error);
  }
};
