import { APPDATA } from "../../constants/appData";
import updateRole from "../../api/PermissionManagement/updateRole.service";

export default ({
  idToken,
  roleId,
  roleName,
}: {
  idToken: string;
  roleId: string;
  roleName: string;
}) => ({
  type: APPDATA,
  payload: updateRole({ idToken, roleId, roleName }),
});
