import axios from "axios";

// functions
import { getApiUrl } from "../functions/Commons";

export default () => {
  let dispatch = {};
  const url = `${getApiUrl()}/frontendconfig`;

  return axios
    .get(url)
    .then((res) => {
      dispatch = {
        customerConfig: res.data,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
