import React from "react";
import { Box, Typography } from "@mui/material";
import { IDrug } from "@/types/visit";
import { ICustomerConfig } from "@/types/customerConfig";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

interface IDrugItem extends IDrug {
  customerConfig: ICustomerConfig;
}
function DrugItem({
  drugNondugFullName,
  sumPrice,
  rxDateTime,
  drugUsage,
  qty,
  customerConfig,
}: IDrugItem) {
  return (
    <Box className="bg-gray-50 rounded-lg m-2 p-3 flex flex-col justify-between">
      <Box className="flex justify-between gap-2">
        <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
          {drugNondugFullName}
        </Typography>
        {sumPrice &&
          !customerConfig?.disabledFeatures?.includes("showDrugPrice") && (
            <Typography
              sx={{ fontWeight: "bold", fontSize: "12px" }}
            >{`฿${sumPrice}`}</Typography>
          )}
      </Box>

      <Box marginTop={2}>
        <Box>
          <Typography sx={{ fontSize: "12px" }}>{drugUsage}</Typography>
        </Box>
        <Box className="flex justify-between gap-2">
          {rxDateTime ? (
            <Typography
              sx={{ fontSize: "10px", color: "rgba(0, 0, 0, 0.45)" }}
            >{`Log. ${dateToLocalDateString(rxDateTime)}`}</Typography>
          ) : (
            <></>
          )}
          <Typography
            sx={{ fontWeight: "bold", fontSize: "12px" }}
          >{`จำนวน ${qty}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default DrugItem;
