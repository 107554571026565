import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";
import { IVisit } from "@/types/visit";
interface IFetchConfirmVisit {
  totalVisits: number;
  limit: number;
  page: number;
  pagingCounter: 1;
  totalPages: number;
  visits: IVisit[];
}
export const CalculateBill = async (
  monthDate: Date,
  token: string,
  page = 1,
  limit = 2
): Promise<IFetchConfirmVisit> => {
  const currentMonth = new Date(monthDate);
  const startDate = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1
  );
  const endDate = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0
  );

  const result = await axios.get(
    `${getApiUrl()}/v1/billings//comfirm/visits?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  console.log(result.data);
  return result.data;
};
