import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { IPaginateReason } from "../../components/ReasonRefer";

const fetchReasonRefer = async (
  token: string,
  page = 1,
  limit = 20
): Promise<IPaginateReason> => {
  const url = `${getApiUrl()}/v1/reason?page=${page}&limit=${limit}`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export default fetchReasonRefer;
