import styled from "styled-components";

const HospitalManagement = "";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -40px;
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .button {
    flex: 1;
    margin: 0 5px;
    max-width: 100px;
  }
  .button-add {
    margin: 0 5px;
  }
  .table-title {
    text-align: left;
  }
  .MuiTableRow-root:nth-child(even) {
    background-color: #f9f9f9;
  }
  .MuiPaper-root {
    width: 100%;
    max-width: 1200px;
  }
  .MuiTableCell-root {
    padding: 4px 50px;
  }
`;

export { HospitalManagement, Container };
