import { Typography } from "@mui/material";
import React from "react";
import { IDiagnosis } from "@/types/visit";

interface IDiagnosisItem {
  diagItem: IDiagnosis;
  index: number;
}
function DiagnosisItem({ diagItem, index }: IDiagnosisItem) {
  return (
    <Typography variant="caption">{`${index}.  ${diagItem.icd10} : ${
      diagItem.icd10Name || "ไม่ระบุ"
    } (${diagItem.diagTypeName})`}</Typography>
  );
}

export default DiagnosisItem;
