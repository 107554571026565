import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { ThaiDate } from "../../functions/FuncDateTimes";
import { ReferralType } from "../../types/refers";
import { IReportResponse } from "@/types/report";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

interface IReportDecease {
  startDate: Date;
  endDate: Date;
  referType: ReferralType;
  report: IReportResponse;
}

export default function ReportDecease({
  startDate,
  endDate,
  referType,
  report,
}: IReportDecease) {
  const [categories, setCategories] = useState<string[]>();
  const [series, setSeries] = useState<number[]>();

  const options: ApexOptions = {
    chart: {
      type: "donut",
    },
    labels: categories,
    stroke: {
      colors: ["#fff"],
    },
    fill: {
      opacity: 0.8,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    title: {
      text: `ผู้เสียชีวิต | ${ThaiDate(startDate)} - ${ThaiDate(endDate)}`,
    },
    dataLabels: {
      formatter(val: any, opts: any) {
        return `${opts.w.config.series[opts.seriesIndex]} คน`;
      },
    },
  };

  const formatCategories = () => {
    const encounterReport =
      referType === ReferralType.REFER_IN
        ? report.referInPatientDecrease
        : report.referOutPatientDecrease;

    const listOfHospitalName = Object.keys(encounterReport).map((item) =>
      item.length > 50 ? item.substring(0, 50) + "..." : item
    );

    setCategories(listOfHospitalName);
  };

  const formatSeries = () => {
    const encounterReport =
      referType === ReferralType.REFER_IN
        ? report.referInPatientDecrease
        : report.referOutPatientDecrease;

    const deceaseAmount = Object.values(encounterReport).map(
      (item) => item[0].total
    );

    setSeries(deceaseAmount);
  };

  useEffect(() => {
    formatCategories();
    formatSeries();
  }, []);

  return categories && categories.length > 0 && series && series.length > 0 ? (
    <Box className="border" padding={2}>
      <ApexCharts options={options} series={series} type="donut" height={300} />
    </Box>
  ) : (
    <Box
      className="border"
      padding={2}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      minHeight={300}
    >
      <Typography variant="h6">ไม่พบรายงานผู้เสียชีวิต</Typography>
    </Box>
  );
}
