import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

const doctorDayoffSchedule = async (token: string, scheduleId: string) => {
  const url = getApiUrl();
  await axios.put(
    `${url}/v1/schedule/dayoff/${scheduleId}`,
    { dayOff: true },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export default doctorDayoffSchedule;
