import { useState } from "react";
import dayjs from "dayjs";
import { TSchedule } from "../../../types/schedule";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import updateSchedule from "../../../api/AppointmentOPD/updateSchedule";
import DialogChoiceConfirm from "./DialogChoiceConfirm";
import { Close } from "@mui/icons-material";
import doctorDayoffSchedule from "../../../api/AppointmentOPD/doctorDayoff";
type TEditSchedule = {
  token: string;
  schedule: TSchedule;
  onSetEventNull(): void;
  onFetchSchedule(): Promise<void>;
};
const CHOICE_OPTIONS_UPDATE = [
  { value: "id", label: "แค่กิจกรรมนี้" },
  { value: "timeId", label: "ทุกกิจกรรมในเวลานี้" },
];
const CHOICE_OPTIONS_DELETE = [
  ...CHOICE_OPTIONS_UPDATE,
  // { value: "roundId", label: "ทุกกิจกรรมในรอบนี้" },
];
function EditSchedule({
  schedule,
  token,
  onFetchSchedule,
  onSetEventNull,
}: TEditSchedule) {
  const [open, setOpen] = useState(false);
  const [choices, setChoices] = useState<{ value: string; label: string }[]>(
    []
  );
  const [totalAppointment, setTotalAppointment] = useState<number>(
    Number(schedule.totalAppointment) || 0
  );
  const fDate = (date: string | Date, format: string) => {
    return dayjs(date).format(format);
  };
  const [date, setDate] = useState<string>(fDate(schedule.start, "YYYY-MM-DD"));
  const [start, setStart] = useState<string>(fDate(schedule.start, "HH:mm"));
  const [end, setEnd] = useState<string>(fDate(schedule.end, "HH:mm"));
  const [isClickDelete, setIsClickDelete] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");

  const onHandleDialog = (status: boolean) => setOpen(status);

  const onClickUpdate = () => {
    setChoices(CHOICE_OPTIONS_UPDATE);
    onHandleDialog(true);
    setIsClickDelete(false);
    setTitle("แก้ไขข้อมูลกิจกรรม");
  };

  const onClickDelete = () => {
    setChoices(CHOICE_OPTIONS_DELETE);
    setIsClickDelete(true);
    onHandleDialog(true);
    setTitle("ลบข้อมูลกิจกรรม");
  };

  const onClickDayoff = () => {
    setChoices([]);
    setIsClickDelete(false);
    onHandleDialog(true);
    setTitle("แพทย์ลาหยุด");
  };

  const onHandleEditSchedule = async (value: string, isDeleted: boolean) => {
    if (["timeId", "roundId", "id"].includes(value)) {
      const data = {
        ...schedule,
        start: new Date(`${date},${start}`).toISOString(),
        end: new Date(`${date},${end}`).toISOString(),
        totalAppointment,
        isDeleted,
      };
      // let id = undefined;
      let timeId = undefined;
      let roundId = undefined;
      // if (value === "id") id = schedule._id;
      if (value === "timeId") timeId = schedule.timeId;
      if (value === "roundId") roundId = schedule.roundId;
      await updateSchedule(token, data, timeId, roundId);
    } else {
      await doctorDayoffSchedule(token, schedule._id);
    }
    await onFetchSchedule();
  };

  return (
    <Dialog
      open={Boolean(schedule)}
      onClose={onSetEventNull}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Typography>
            {fDate(schedule?.start, "DD/MM/YYYY HH:mm")} -{" "}
            {fDate(schedule?.end, "HH:mm")}
          </Typography>
          <IconButton onClick={onSetEventNull}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <InputLabel id="demo-simple-select-label">จำนวนนัดหมาย</InputLabel>
          <TextField
            type="number"
            value={totalAppointment}
            fullWidth
            onChange={(e) => setTotalAppointment(Number(e.target.value))}
          />
          <InputLabel id="demo-simple-select-label">วัน / เวลา</InputLabel>
          <TextField
            type="date"
            value={date}
            fullWidth
            onChange={(e) => setDate(e.target.value)}
          />

          <Box display="flex" width="100%" gap={1} mt={2}>
            <TextField
              type="time"
              value={start}
              fullWidth
              onChange={(e) => setStart(e.target.value)}
            />
            <TextField
              type="time"
              value={end}
              fullWidth
              onChange={(e) => setEnd(e.target.value)}
            />
          </Box>

          <DialogChoiceConfirm
            open={open}
            onClose={onHandleDialog}
            onConfirm={onHandleEditSchedule}
            isDeleted={isClickDelete}
            choices={choices}
            title={title}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={onClickUpdate}
          color="warning"
          variant="contained"
        >
          แก้ไขข้อมูลกิจกรรม
        </Button>
        <Button
          fullWidth
          onClick={onClickDelete}
          color="error"
          variant="contained"
        >
          ลบข้อมูลกิจกรรม
        </Button>
        <Button
          fullWidth
          onClick={onClickDayoff}
          color="error"
          variant="contained"
        >
          แพทย์ลา
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSchedule;
