import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { getReferHistoryId } from "../../../api/referHistory.service";
import { IReferHistory } from "../../../types/referHistory";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { DocumentScannerOutlined } from "@mui/icons-material";
import { DateTimeToStrDatetime } from "../../../functions/FuncDateTimes";
import Slide from "@mui/material/Slide";
import ReferCompareDialog from "./ReferCompareDialog";
import SearchIcon from "@mui/icons-material/Search";
import { IReferral } from "../../../types/refers";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { ReferHistoryStatus } from "../../../constants/referHistory";

interface IReferHistoryDialog {
  referral: IReferral;
  open: boolean;
  onClose(): void;
}

const ReferHistoryDialog = ({
  referral,
  open,
  onClose,
}: IReferHistoryDialog) => {
  const { appData } = useSelector((state: RootState) => state);
  const { idToken } = appData;

  const [referCompareOpen, setReferCompareOpen] = useState(false);
  const histories = useFetchReferHistories(referral.bid, idToken);
  const [newReferral, setNewReferral] = useState<IReferral>();
  const [oldReferral, setOldReferral] = useState<IReferral>();

  const handleOpenReferCompare = (history: IReferHistory) => {
    const { version = 1 } = history;

    const newReferral = histories.find(
      (history) => history.version === version + 1
    );

    setNewReferral(newReferral || referral);
    setOldReferral(history);
    setReferCompareOpen(true);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open && !referCompareOpen}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="refer-dialog-attach bg-transparent"
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <Slide
          direction="right"
          in={open && !referCompareOpen}
          timeout={400}
          mountOnEnter
          unmountOnExit
        >
          <DialogContent className="bg-gray-100 py-4">
            {histories.length > 0 && (
              <p className="flex justify-center text-xl text-blue-400 my-4">
                ประวัติการเปลี่ยนแปลงข้อมูลรีเฟอร์
              </p>
            )}
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
              position="alternate"
            >
              {histories.length > 0 ? (
                histories?.map((history) => (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot color="primary">
                        <DocumentScannerOutlined />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Card sx={{ borderRadius: "10px" }}>
                        <div className="mx-4 my-3">
                          <p className="flex justify-end text-sm text-gray-500">
                            {DateTimeToStrDatetime(history.createdAt)}
                          </p>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <div>
                              <p className="flex justify-start">
                                {history.historyStatus.name}
                              </p>
                              <p className="flex justify-start text-xs text-gray-400">
                                {history.updatedBy}
                              </p>
                            </div>
                            {history.historyStatus.status ===
                              ReferHistoryStatus.UPDATE.status && (
                              <IconButton
                                onClick={() => handleOpenReferCompare(history)}
                              >
                                <SearchIcon />
                              </IconButton>
                            )}
                          </Box>
                        </div>
                      </Card>
                    </TimelineContent>
                  </TimelineItem>
                ))
              ) : (
                <Box>
                  <Typography>ไม่พบประวัติ</Typography>
                </Box>
              )}
            </Timeline>
          </DialogContent>
        </Slide>
      </Dialog>

      {oldReferral && newReferral && (
        <ReferCompareDialog
          open={referCompareOpen}
          oldReferral={oldReferral}
          newReferral={newReferral}
          onClose={() => setReferCompareOpen(false)}
        />
      )}
    </>
  );
};
const useFetchReferHistories = (referBid: string, idToken: string) => {
  const [histories, setHistories] = useState<IReferHistory[]>([]);
  const getAllPatients = useCallback(async () => {
    const data = await getReferHistoryId(idToken, referBid);

    setHistories(data);
  }, [referBid]);

  useEffect(() => {
    getAllPatients();
  }, [getAllPatients]);

  return histories;
};

export default ReferHistoryDialog;
