import React from "react";
import { Typography, Box } from "@mui/material";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

function OperationItem({
  requestOperationDateTime,
  operationDiagText,
  operationItemName,
}: {
  requestOperationDateTime: string;
  operationDiagText: string;
  operationItemName: string;
}) {
  return (
    <Box className="grid grid-cols-2 bg-slate-50 px-5 py-2 rounded-lg my-2">
      <div>
        <Typography className="text-gray-600">{`วันทีเวลาสั่งทำหัตถการ : ${dateToLocalDateString(
          requestOperationDateTime
        )}`}</Typography>
        <Typography className="text-gray-600">{`ชื่อรายการหัตถการ : ${
          operationItemName || "ไม่ระบุ"
        }`}</Typography>
        <Typography className="text-gray-600">{`วินิจฉัยหัสถการ : ${
          operationDiagText || "-"
        }`}</Typography>
      </div>
    </Box>
  );
}

export default OperationItem;
