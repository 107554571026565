import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import LabResultItem from "./LabResultItem";

function LabResult({ labResults }: any) {
  const labResultItem = (labResultsParms: any[]) =>
    labResultsParms.map((lab: any, index: number) => (
      <LabResultItem key={lab._id} labResult={lab} index={index + 1} />
    ));

  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-lg border-solid border-gray border-2 my-4 "
    >
      <Accordion expanded={true}>
        <AccordionSummary className="!cursor-default">
          <Box className="flex items-center ">
            <Typography variant="h6" color="#58595B">
              LAB
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {labResults?.length > 0 && labResultItem(labResults)}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default LabResult;
