import React from "react";
import {
  ListItem,
  Avatar,
  Menu,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { ExitToApp as ExitToAppIcon, AccountCircle } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IMenuInfo } from "./type";
import logout from "../../../actions/User/logout.action";
import { useTranslation } from "react-i18next";

function MenuInfo({ anchorEl, handleClose, loginData, fullName }: IMenuInfo) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("menu");

  const logoutApp = (previousUser: any) => {
    const payload = {
      loginData: null,
      loginStatus: false,
      idToken: "",
      FetchingStatus: false,
      previousUser,
    };

    handleClose();

    localStorage.removeItem("expireDate");
    localStorage.setItem("Token", "");

    dispatch(logout(payload));
    history.push("/");
  };

  return (
    <Menu
      id="profile-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => handleClose()}
      className="profile-menu"
    >
      <div className="profile-menu">
        <List>
          <ListItem button>
            <ListItemIcon>
              <Avatar>{fullName?.firstName?.charAt(0)}</Avatar>
            </ListItemIcon>
            <ListItemText
              primary={`${fullName?.firstName} ${fullName?.lastName}`}
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            onClick={() => {
              handleClose();
              history.push(
                `/user-management/add-edit-user/${loginData?.username}`,
                {
                  user: loginData,
                }
              );
            }}
          >
            <ListItemIcon>
              <AccountCircle color="disabled" />
            </ListItemIcon>
            <ListItemText primary={t("profile.user")} />
          </ListItem>
          <ListItem button onClick={() => logoutApp(loginData?.username)}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="ออกจากระบบ" />
          </ListItem>
        </List>
      </div>
    </Menu>
  );
}

export default MenuInfo;
