import { Autocomplete, FormControl, TextField } from "@mui/material";
import React from "react";
import TDepartment from "../../../types/department";
import ListboxComponent from "../../Utility/ListBoxComponents";

type TDropdownDepartment = {
  departments: TDepartment[];
  onSelectedDepartment(department: TDepartment): void;
};
function DropdownDepartment({
  departments,
  onSelectedDepartment,
}: TDropdownDepartment) {
  return (
    <FormControl className="bg-white flex-auto w-full">
      <Autocomplete
        disableListWrap
        ListboxComponent={ListboxComponent}
        options={departments}
        onChange={(option, value) => value && onSelectedDepartment(value)}
        getOptionLabel={(option) => `${option.departName}`}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            fullWidth
            label="แผนก"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default DropdownDepartment;
