import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { IVisit } from "@/types/visit";
import VisitItem from "./VisitItem";
import FilterDialog from "./FilterDialog";
import { IFilterOptions } from "@/types/commons";
import { VisitFilterRadioValue } from "../../../constants/commons";
import { useTranslation } from "react-i18next";

interface IVisitList {
  visits: IVisit[];
  onSelectVisit(visitParams: IVisit): void;
  cid: string;
  idVisit: string;
}

function VisitList({ visits, onSelectVisit, cid, idVisit }: IVisitList) {
  const { t } = useTranslation("patientemr");

  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({
    filterDateType: VisitFilterRadioValue.MONTH,
    hospCode: "all",
    month: "all",
    year: "all",
    endMonth: "all",
    endYear: "all",
  });
  const [hospitalMenu, setHospitalMenu] = useState<IVisit[]>([]);
  const [visitDatas, setVisitDatas] = useState<IVisit[]>(visits);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);

  const visitItem = (visitsParams: IVisit[]) =>
    visitsParams.map((visit) => (
      <VisitItem
        visitItem={visit}
        key={visit._id}
        onSelectVisit={onSelectVisit}
        idVisit={idVisit}
      />
    ));

  const filterHospital = () => {
    if (hospitalMenu.length === 0) {
      const visitsHospitals: IVisit[] = [];

      visits.forEach((visit) => {
        const checkExist = visitsHospitals.find(
          (menu) => menu.hospCode === visit.hospCode && menu.cid === cid
        );
        if (!checkExist) {
          visitsHospitals.push(visit);
        }
      });

      setHospitalMenu(visitsHospitals);
    }
  };

  const filterVisits = () => {
    let filteredVisits = visits;

    if (filterOptions.hospCode !== "all") {
      // Filter by hospCode
      filteredVisits = filteredVisits.filter(
        (visit) => visit.hospCode === filterOptions.hospCode
      );
    }

    if (filterOptions.filterDateType === VisitFilterRadioValue.MONTH) {
      if (filterOptions.year !== "all" && filterOptions.month === "all") {
        // Filter by year
        filteredVisits = filteredVisits.filter((visit) => {
          const visitYear = new Date(visit.visitDateTime).getFullYear();
          return visitYear === parseInt(filterOptions.year, 10);
        });
      }

      if (filterOptions.month !== "all") {
        // Filter by month and year
        filteredVisits = filteredVisits.filter((visit) => {
          const visitDate = new Date(visit.visitDateTime);
          const visitMonth = visitDate.getMonth();
          const visitYear = visitDate.getFullYear();

          return (
            visitMonth === +filterOptions.month &&
            visitYear === +filterOptions.year
          );
        });
      }
    } else {
      // Filter by date range
      filteredVisits = filteredVisits.filter((visit) => {
        const visitDate = new Date(visit.visitDateTime);
        const visitMonth = visitDate.getMonth();
        const visitYear = visitDate.getFullYear();

        return (
          visitMonth >= +filterOptions.month &&
          visitYear >= +filterOptions.year &&
          visitMonth <= +filterOptions.endMonth &&
          visitYear <= +filterOptions.endYear
        );
      });
    }

    setVisitDatas(filteredVisits);
  };

  useEffect(() => {
    if (Array.isArray(visits) && visits.length > 0) {
      filterHospital();
      filterVisits();
    }
  }, [visits, filterOptions]);

  return (
    <Box
      borderRadius={4}
      border={"2px solid #e0e0e0"}
      sx={{ backgroundColor: "white", "@media print": { display: "none" } }}
    >
      <Box display={"flex"} justifyContent={"flex-end"} padding={1}>
        <FilterDialog
          isOpen={isFilterDialogOpen}
          hospitalMenu={hospitalMenu}
          onClose={() => setIsFilterDialogOpen(false)}
          onOpen={() => setIsFilterDialogOpen(true)}
          onApplyFilters={(filters: IFilterOptions) =>
            setFilterOptions(filters)
          }
        />
      </Box>

      <Box
        maxHeight={"580px"}
        overflow={"scroll"}
        sx={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}
      >
        {Array.isArray(visitDatas) && visitDatas.length > 0 ? (
          visitItem(visitDatas)
        ) : (
          <Box
            padding={2}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="body1">{t("visit_list.not_found")}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default VisitList;
