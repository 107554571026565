import React from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { ThaiDate } from "../../functions/FuncDateTimes";
import { calculateReportHeight } from "../../functions/report";
import Box from "@mui/material/Box";

interface IReportQty {
  categories: string[];
  series: { name: string; data: number[] }[];
  startDate: Date;
  endDate: Date;
  colors: string[];
}

export default function ReportQty({
  categories,
  series,
  startDate,
  endDate,
  colors,
}: IReportQty) {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    title: {
      text: `สถิติรีเฟอร์ | ${ThaiDate(startDate)} - ${ThaiDate(endDate)}`,
    },
    xaxis: {
      categories,
      labels: {
        formatter(val: any) {
          if (series[0]?.data.some((item) => item > 1)) {
            return `${val} ครั้ง`;
          }
          if (val === 0) {
            return `${val} ครั้ง`;
          }
          return `${val?.toFixed(1)} ครั้ง`;
        },
      },
    },
    yaxis: {
      labels: {
        align: "left",
      },
    },
    tooltip: {
      y: {
        formatter(val: any) {
          return `${val} ครั้ง`;
        },
      },
    },
    legend: {
      position: "top",
    },
    colors,
  };

  return (
    <Box maxHeight={400} sx={{ overflow: "scroll" }}>
      <ApexCharts
        options={options}
        series={series}
        type="bar"
        height={calculateReportHeight({
          categoriesLength: categories.length,
        })}
      />
    </Box>
  );
}
