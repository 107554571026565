import React from "react";
import { IconButton, Alert, AlertTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import { IAlertMessage } from "../type";

function AlertMessage(props: IAlertMessage) {
  const { onCloseMessage, title, message, severity } = props;
  return (
    <Alert
      className="mt-2"
      severity={severity}
      variant="filled"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onCloseMessage}
        >
          <Close fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
}

export default AlertMessage;
