import React, { useEffect } from "react";
import { Route, withRouter, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NavigationMain from "../components/NavigationMain/NavigationMain";
import ContainerMain from "../components/ContainerMain";
import logout from "../actions/User/logout.action";
import { isResetPassword } from "../functions/Commons";

function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { appData } = useSelector((state) => state);
  const { loginData } = appData;
  const expireDate = localStorage.getItem("expireDate");
  const checkExpire = new Date() >= new Date(expireDate);

  function logoutApp(previousUser) {
    const payload = {
      loginData: null,
      loginStatus: false,
      idToken: "",
      FetchingStatus: false,
      previousUser,
    };

    localStorage.removeItem("expireDate");
    localStorage.setItem("Token", "");

    dispatch(logout(payload));
    history.push("/");
  }

  useEffect(() => {
    const isExpired = !expireDate || checkExpire;
    const isNotResetPassword = !isResetPassword();

    if (isExpired && isNotResetPassword) {
      logoutApp(loginData?.username);
    }
  }, [checkExpire, expireDate, isResetPassword, loginData]);

  return (
    <div className="bg-gray-100">
      {!isResetPassword() && (
        <Route render={(props) => <NavigationMain {...props} />} />
      )}
      <Route render={(props) => <ContainerMain {...props} />} />
    </div>
  );
}

export default withRouter(Index);
