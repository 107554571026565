import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export const fetchSampleVisit = async (startDate: Date, token: string) => {
  try {
    const url = getApiUrl();
    const response = await axios.get(`${url}/v1/sample/visit/${startDate}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};
