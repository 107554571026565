import DatePicker from "@mui/lab/DatePicker";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@mui/system";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import React, { useState, useEffect } from "react";
import { fetchHospitalEver } from "../../api/sampleData/fetchHospitalEver";
import { IHospital } from "@/types/hospital";

interface IModalCreateData {
  open: boolean;
  onClose(): void;
  token: string;
  successStatus: boolean | null;
  onChangeStatusNull(): void;
  onGenerate(
    fromHospCode: string,
    toHospCode: string,
    hospName: string,
    referDate: Date,
    totalGen: number
  ): void;
}

function ModalCreateData({
  open,
  onClose,
  token,
  onGenerate,
  successStatus,
  onChangeStatusNull,
}: IModalCreateData) {
  const [totalGen, setTotalGen] = useState("10");
  const [hospitalEver, setHospitalEver] = useState<IHospital[]>([]);
  const [fromHospCode, setFromHospCode] = useState("");
  const [toHospCode, setToHospCode] = useState("");
  const [referDate, setReferDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState(false);
  const getHospitalEver = async () => {
    const hospitals = await fetchHospitalEver(token);
    setHospitalEver(hospitals);
  };
  useEffect(() => {
    getHospitalEver();
  }, [token]);
  const selectFromHospital = (event: SelectChangeEvent) => {
    setFromHospCode(event.target.value);
    onChangeStatusNull();
  };
  const selectToHospital = (event: SelectChangeEvent) => {
    setToHospCode(event.target.value);
    onChangeStatusNull();
  };
  const onChangeReferDate = (date: Date) => {
    setReferDate(date);
    onChangeStatusNull();
  };

  const onLoading = (status: boolean) => setLoading(status);
  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "600px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="grid grid-cols-2 gap-4">
            <h2 className="text-sky-500 my-3">สร้างข้อมูลตัวอย่าง</h2>
            {successStatus !== null && (
              <h2
                className={
                  successStatus ? "text-green-600 my-3" : "text-red-500 my-3"
                }
              >
                {successStatus
                  ? "สร้างข้อมูลตัวอย่างเรียบร้อยแล้ว"
                  : "สร้างข้อมูลตัวอย่างล้มเหลว"}
              </h2>
            )}
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  จากโรงพยาบาล
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={fromHospCode}
                  label="total-data-gen"
                  onChange={selectFromHospital}
                >
                  {hospitalEver.map((hospital) => (
                    <MenuItem
                      key={hospital.hospCode}
                      value={hospital.hospCode}
                      disabled={hospital.hospCode === toHospCode}
                    >{`${hospital.hospCode} - ${hospital.hospName}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  ถึงโรงพยาบาล
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={toHospCode}
                  label="total-data-gen"
                  onChange={selectToHospital}
                >
                  {hospitalEver.map((hospital) => (
                    <MenuItem
                      key={hospital.hospCode}
                      value={hospital.hospCode}
                      disabled={hospital.hospCode === fromHospCode}
                    >{`${hospital.hospCode} - ${hospital.hospName}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 my-2">
            <DatePicker
              views={["day"]}
              value={referDate}
              inputFormat="DD/MM/YYYY"
              onChange={(date) => onChangeReferDate(date || new Date())}
              renderInput={(params) => (
                <TextField {...params} className="!rounded-full" />
              )}
              InputProps={{
                endAdornment: <CalendarTodayIcon className="text-gray-500" />,
              }}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">จำนวนเอกสาร</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={totalGen}
                label="total-data-gen"
                onChange={(event: SelectChangeEvent) =>
                  setTotalGen(event.target.value)
                }
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex justify-center">
            <Button
              disabled={fromHospCode === "" || toHospCode === "" || loading}
              variant="outlined"
              color="info"
              onClick={() => {
                onLoading(true);
                try {
                  const hospitalFind = hospitalEver.find(
                    (hospital) => hospital.hospCode === fromHospCode
                  );
                  onGenerate(
                    fromHospCode,
                    toHospCode,
                    hospitalFind?.hospName || "",
                    referDate,
                    parseInt(totalGen)
                  );
                  onLoading(false);
                } catch (error) {
                  onLoading(false);
                }
              }}
            >
              generate
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ModalCreateData;
