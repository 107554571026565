import React, { useState, useEffect } from "react";
import { fetchSamplePatient } from "../../api/sampleData/fetchSamplePatients";
import { IPatient } from "@/types/patient";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
interface IPatientTable {
  date: string;
  token: string;
}
function PatientTable({ date, token }: IPatientTable) {
  const [patients, setPatients] = useState<IPatient[]>([]);
  const getPatientData = async () => {
    const patientsResp = await fetchSamplePatient(new Date(date), token);
    setPatients(patientsResp);
  };
  useEffect(() => {
    if (date) {
      getPatientData();
    }
  }, [date]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">รหัสบัตรประชาชน</TableCell>
            <TableCell>ชื่อ นามสกุลผู้ป่วย</TableCell>
            <TableCell>ที่อยู่</TableCell>
            <TableCell>วันที่สร้าง</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.length > 0 ? (
            patients.map((patient) => (
              <TableRow
                key={patient._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{patient.cid}</TableCell>
                <TableCell component="th" scope="row">
                  {`${patient.data.fullname.title} ${patient.data.fullname.firstName} ${patient.data.fullname.lastName}`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {`${patient.data.address.houseNumber} ${patient.data.address.ampur} ${patient.data.address.changwat} ${patient.data.address.country}`}
                </TableCell>
                <TableCell align="left">
                  {new Date(patient.createdAt).toLocaleDateString("th-TH")}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell component="th" scope="row" align="center">
                ไม่มีข้อมูล
              </TableCell>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PatientTable;
