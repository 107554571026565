import React from "react";
import Visit from "./VisitList";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { IVisit } from "@/types/visit";

interface IProps {
  visits: IVisit[];
  onSelectVisit(visitParams: IVisit): void;
  cid: string;
  idVisit: string;
  open: boolean;
  handleDialog(staus: boolean): void;
}
function DialogVisit({
  visits,
  idVisit,
  cid,
  onSelectVisit,
  open,
  handleDialog,
}: IProps) {
  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      onClose={() => handleDialog(false)}
      sx={{ display: { lg: "none", xs: "block" } }}
    >
      <DialogContent>
        <Visit
          visits={visits}
          onSelectVisit={onSelectVisit}
          cid={cid}
          idVisit={idVisit}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DialogVisit;
