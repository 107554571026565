import React, { useState } from "react";
import TDepartment from "../../../types/department";
import {
  Button,
  Dialog,
  DialogActions,
  Box,
  Tab,
  Typography,
  TextField,
  InputLabel,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DropdownDepartment from "./DropdownDepartment";

type TModalCreate = {
  open: boolean;
  onHandleModal(status: boolean): void;
  onCreateDepartment(departName: string): void;
  onCreateClinic(
    clinicName: string,
    department: TDepartment,
    note: string
  ): void;
  departments: TDepartment[];
};
function ModalCreate({
  open,
  onHandleModal,
  departments,
  onCreateDepartment,
  onCreateClinic,
}: TModalCreate) {
  const [value, setValue] = React.useState("1");
  const [departName, setDepartName] = useState<string>("");
  const [clinicName, setClinicName] = useState<string>("");
  const [note, setNote] = useState<string>("");

  const [selectDepartment, setSelectDepartment] = useState<
    TDepartment | undefined
  >();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onSelectedDepartment = (department: TDepartment) =>
    setSelectDepartment(department);

  return (
    <Dialog
      open={open}
      onClose={() => onHandleModal(false)}
      maxWidth="sm"
      fullWidth
    >
      <Box sx={{ width: "100%", typography: "body1", display: "flex" }}>
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="แผนก" value="1" />
              <Tab label="คลินิก" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box>
              <InputLabel id="demo-simple-select-label">สร้างแผนก</InputLabel>
              <TextField
                value={departName}
                onChange={(e) => setDepartName(e.target.value)}
              />
              <Box className="mt-2">
                <Button
                  onClick={() => onCreateDepartment(departName)}
                  variant="contained"
                  fullWidth
                >
                  บันทึก
                </Button>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            {departments?.length > 0 && (
              <>
                <InputLabel id="demo-simple-select-label">เลือกแผนก</InputLabel>
                <DropdownDepartment
                  departments={departments}
                  onSelectedDepartment={onSelectedDepartment}
                />

                <InputLabel id="demo-simple-select-label">
                  ชื่อคลินิก
                </InputLabel>
                <TextField
                  value={clinicName}
                  onChange={(e) => setClinicName(e.target.value)}
                />
                <InputLabel id="demo-simple-select-label">หมายเหตุ</InputLabel>
                <TextField
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <Box>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 1 }}
                    onClick={() => {
                      if (selectDepartment) {
                        onCreateClinic(clinicName, selectDepartment, note);
                      }
                    }}
                  >
                    บันทึก
                  </Button>
                </Box>
              </>
            )}
            {departments?.length === 0 && (
              <Typography>ไม่มีรายการแผนก</Typography>
            )}
          </TabPanel>
        </TabContext>
      </Box>
      <DialogActions>
        <Button onClick={() => onHandleModal(false)}>ปิด</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalCreate;
