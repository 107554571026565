import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default async (body) => {
  const { idToken } = body;
  try {
    // สำหรับสร้าง category (done)
    const result = await axios.post(
      `${getApiUrl()}/v1/billing/settings`,
      body,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return { result };
  } catch (e) {
    throw new Error(e);
  }
};
