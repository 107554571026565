import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { ThaiDate } from "../../functions/FuncDateTimes";
import { IReportResponse } from "@/types/report";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface IReportProvince {
  startDate: Date;
  endDate: Date;
  report: IReportResponse;
}

export default function ReportProvince({
  startDate,
  endDate,
  report,
}: IReportProvince) {
  const [categories, setCategories] = useState<string[]>();
  const [series, setSeries] = useState<number[]>();

  const options: ApexOptions = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: categories,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    title: {
      text: `TOP 10 โรคในจังหวัด | ${ThaiDate(startDate)} - ${ThaiDate(
        endDate
      )}`,
    },
  };

  const formatCategories = () => {
    if (!report.provinceICD) {
      return;
    }

    const provinceName = Object.keys(report.provinceICD)[0];

    if (provinceName) {
      const cate = report.provinceICD[provinceName].map((item) =>
        item.label.length > 40
          ? item.label.substring(0, 40) + "..."
          : item.label
      );

      setCategories(cate);
    }
  };

  const formatSeries = () => {
    if (!report.provinceICD) {
      return;
    }

    const provinceName = Object.keys(report.provinceICD)[0];

    if (provinceName) {
      const series = report.provinceICD[provinceName].map((item) => item.total);

      setSeries(series);
    }
  };

  useEffect(() => {
    formatCategories();
    formatSeries();
  }, []);

  return categories && categories.length > 0 && series && series.length > 0 ? (
    <Box className="border" padding={2}>
      <ApexCharts options={options} series={series} type="pie" height={300} />
    </Box>
  ) : (
    <Box
      className="border"
      padding={2}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      minHeight={300}
    >
      <Typography variant="h6">ไม่พบรายงาน TOP 10 โรคในจังหวัด</Typography>
    </Box>
  );
}
