import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TSchedule } from "../../types/schedule";

const fetchScheduleById = async (
  token: string,
  scheduleId: string
): Promise<TSchedule> => {
  const url = getApiUrl();
  const resp = await axios.get(`${url}/v1/schedule/${scheduleId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return resp.data;
};
export default fetchScheduleById;
