import { Box, Typography } from "@mui/material";
import React from "react";
import { IVisitVital } from "@/types/visit";

function PhysicalExam({
  peHeent,
  peHeentText,
  peHeart,
  peHeartText,
  pe,
  peLung,
  peLungText,
  peAb,
  peAbText,
  peExt,
  peExtText,
  peNeuro,
  peNeuroText,
}: IVisitVital) {
  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box className="border-b-2 border-gray-100 h-10 px-6 py-1">
        <Typography variant="h6" color="#58595B">
          Physical Exam
        </Typography>
      </Box>
      <Box className="grid gap-2 px-6 py-3">
        <Box className="border-b-2 border-gray-100">
          <Box className="grid grid-cols-3">
            <Typography className="text-gray-500">HEENT</Typography>
            <Typography>{peHeent || "-"}</Typography>
            <Typography>{peHeentText || "-"}</Typography>
          </Box>
          <Box className="grid grid-cols-3">
            <Typography className="text-gray-500">HEART</Typography>
            <Typography>{peHeart || "-"}</Typography>
            <Typography>{peHeartText || "-"}</Typography>
          </Box>
          <Box className="grid grid-cols-3">
            <Typography className="text-gray-500">LUNG</Typography>
            <Typography>{peLung || "-"}</Typography>
            <Typography>{peLungText || "-"}</Typography>
          </Box>
          <Box className="grid grid-cols-3">
            <Typography className="text-gray-500">AB</Typography>
            <Typography>{peAb || "-"}</Typography>
            <Typography>{peAbText || "-"}</Typography>
          </Box>
          <Box className="grid grid-cols-3">
            <Typography className="text-gray-500">EXT</Typography>
            <Typography>{peExt || "-"}</Typography>
            <Typography>{peExtText || "-"}</Typography>
          </Box>
          <Box className="grid grid-cols-3">
            <Typography className="text-gray-500">NEURO</Typography>
            <Typography>{peNeuro || "-"}</Typography>
            <Typography>{peNeuroText || "-"}</Typography>
          </Box>
        </Box>
        <Box>
          <Typography className="text-gray-500">PE TEXT:</Typography>
          {pe
            ?.split("\r")
            .map((item2, index) => <div key={index}>{item2}</div>) || " - "}
        </Box>
      </Box>
    </Box>
  );
}

export default PhysicalExam;
