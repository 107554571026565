import axios from "axios";

// functions
import { getApiUrl } from "../functions/Commons";

export default (dataRequest) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      const AuthStr = "Bearer ".concat(dataRequest.idToken);
      const { bid } = dataRequest;
      const { reason } = dataRequest;

      const bodyData = {
        bid,
        rejectReason: reason,
      };

      axios
        .put(`${getApiUrl()}/v1/refer/reject`, bodyData, {
          headers: { Authorization: AuthStr },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = {
              referReject: {
                referRejectData: res.data.opResult,
              },
              FetchingStatus: false,
            };

            return resolve(data);
          }
          const data = {
            referReject: {
              referRejectData: res.data.opResult,
            },
            FetchingStatus: false,
          };

          return resolve(data);
        })
        .catch((err) => {
          console.log("AXIOS ERROR: error !");
          // return reject(err.message)
          return reject(err.response);
        });
    }, 200);
  });
