import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Ability } from "@casl/ability";
import { fetchDateReferral } from "../../api/sampleData/fetchDateRefer";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ModalCreateData from "./modalCreateData";
import TabPanel from "../Utility/TabPanel";
import PatientTable from "./patientTable";
import VisitTable from "./visitTable";
import ReferralTable from "./referralTable";
import { deleteSampleData } from "../../api/sampleData/deleteData";
import { ModalDeleteData } from "./modalDeleteData";
import { generateData } from "../../api/sampleData/generateData";
import {
  ServicePermissionEvent,
  ServicePermissionService,
} from "../../constants/servicePermission";
import { useHistory } from "react-router-dom";
function index() {
  const { appData }: any = useSelector((state) => state);
  const history = useHistory();
  const [dateList, setDateList] = useState<string[]>([]);
  const [selectDate, setSelectDate] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [successStatus, setSuccessStatus] = useState<boolean | null>(null);
  const [tabs, setTabs] = useState(0);
  const getDateRefer = async () => {
    if (!appData.idToken) return;
    const dateRefer = await fetchDateReferral(appData.idToken);
    setDateList(dateRefer);
  };
  const checkPermission = () => {
    if (appData.permissionRules) {
      const ability = new Ability(appData.permissionRules);
      const canRead = ability.can(
        ServicePermissionEvent.READ,
        ServicePermissionService.SAMPLE_DATA
      );
      const canUpdate = ability.can(
        ServicePermissionEvent.UPDATE,
        ServicePermissionService.SAMPLE_DATA
      );
      const canDelete = ability.can(
        ServicePermissionEvent.DELETE,
        ServicePermissionService.SAMPLE_DATA
      );
      const canCreate = ability.can(
        ServicePermissionEvent.CREATE,
        ServicePermissionService.SAMPLE_DATA
      );
      if (canRead && canUpdate && canCreate && canDelete) {
        getDateRefer();
        return;
      }
    }
  };
  useEffect(() => {
    checkPermission();
    return () => history.push("/");
  }, [appData]);
  const onModalCreate = () => {
    onChangeStatusNull();
    setOpenCreate(!openCreate);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabs(newValue);
  };
  const onModalDelete = () => {
    onChangeStatusNull();
    setOpenDelete(!openDelete);
  };
  const onGenerate = async (
    fromHospCode: string,
    toHospCode: string,
    hospName: string,
    referDate: Date,
    totalGen: number
  ) => {
    try {
      await generateData(
        appData?.idToken,
        fromHospCode,
        toHospCode,
        hospName,
        referDate,
        totalGen
      );
      await getDateRefer();
      setSuccessStatus(true);
    } catch (error) {
      setSuccessStatus(false);
    }
  };
  const onDeleteData = async (date: string) => {
    try {
      await deleteSampleData(appData.idToken, date);
      await getDateRefer();
      setSelectDate("");
      setSuccessStatus(true);
    } catch (error) {
      setSuccessStatus(false);
    }
  };
  const onChangeStatusNull = () => setSuccessStatus(null);

  return (
    <div className="grid mt-20 mx-9 bg-white px-5 py-6">
      <h1 className="text-sky-600 my-3">Manage Sample Data</h1>
      <div className="grid sm:grid-cols-2 my-4 md:grid-cols-3">
        <div>
          <Button onClick={onModalCreate}>generate data</Button>
          <Button onClick={onModalDelete} color="error">
            Delete
          </Button>
        </div>
        <div className="w-96">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              วันที่มีการส่งต่อผู้ป่วย(ตัวอย่าง)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectDate}
              label="date"
              onChange={(event: SelectChangeEvent) =>
                setSelectDate(event.target.value as string)
              }
            >
              {dateList.map((date, index) => (
                <MenuItem key={index} value={date}>
                  {new Date(date).toLocaleDateString("th-TH")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={tabs}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="SAMPLE PATIENT" />
            <Tab label="SAMPLE VISIT" />
            <Tab label="SAMPLE REFERRAL" />
          </Tabs>
        </Box>
        <TabPanel value={tabs} index={0}>
          <PatientTable token={appData?.idToken} date={selectDate} />
        </TabPanel>
        <TabPanel value={tabs} index={1}>
          <VisitTable token={appData?.idToken} date={selectDate} />
        </TabPanel>
        <TabPanel value={tabs} index={2}>
          <ReferralTable token={appData?.idToken} date={selectDate} />
        </TabPanel>
      </Box>
      <ModalCreateData
        open={openCreate}
        token={appData?.idToken}
        onClose={onModalCreate}
        onGenerate={onGenerate}
        successStatus={successStatus}
        onChangeStatusNull={onChangeStatusNull}
      />
      <ModalDeleteData
        open={openDelete}
        onClose={onModalDelete}
        dateList={dateList}
        onDelete={onDeleteData}
        successStatus={successStatus}
        onChangeStatusNull={onChangeStatusNull}
      />
    </div>
  );
}

export default index;
