// NOTE: If you edit something in this file, don't forget to update the constant in the frontend as well.
// (packages/schema/v10/constants/ReferHistoryStatus.js)

export const ReferHistoryStatus = {
  UPDATE: {
    status: "UPDATE",
    name: "เปลี่ยนแปลงข้อมูลรีเฟอร์",
  },
  COMPLETE: {
    status: "COMPLETE",
    name: "ยอมรับรีเฟอร์",
  },
  REJECTED: {
    status: "REJECTED",
    name: "ปฏิเสธรีเฟอร์",
  },
  IMG_UPLOAD: {
    status: "IMG_UPLOAD",
    name: "อัพโหลดรูปภาพ",
  },
  IMG_DELETE: {
    status: "IMG_DELETE",
    name: "ลบรูปภาพ",
  },
};
