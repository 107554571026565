import React, { useEffect, useState } from "react";
import { ReferralType } from "../../types/refers";
import ReportQty from "./ReportQty";
import ReportPercent from "./ReportPercent";
import { IReportResponse, IReportBarStackedSeries } from "../../types/report";
import Box from "@mui/material/Box";
import { getReportFromReferType } from "../../functions/report";
import _ from "lodash";
import Typography from "@mui/material/Typography";

interface IContainerReportQtyAndPercent {
  report: IReportResponse;
  referType: ReferralType;
  startDate: Date;
  endDate: Date;
}

export default function ContainerReportQtyAndPercent({
  report,
  referType,
  startDate,
  endDate,
}: IContainerReportQtyAndPercent) {
  const [categories, setCategories] = useState<string[]>();
  const [reportQtySeries, setReportQtySeries] = useState<
    IReportBarStackedSeries[]
  >([]);
  const [reportPercentSeries, setReportPercentSeries] = useState<
    IReportBarStackedSeries[]
  >([]);

  const formatCategories = () => {
    const encounterReport = getReportFromReferType({ report, referType });
    const listOfHospitalName = Object.keys(encounterReport);

    setCategories(listOfHospitalName);
  };

  const formatSeries = () => {
    const encounterReport = getReportFromReferType({ report, referType });
    const formattedData = _.chain(encounterReport)
      .flatMap((hospitalData) => hospitalData)
      .groupBy("label")
      .map((groupedData, label) => ({
        name: label,
        data: _.map(groupedData, "total"),
      }))
      .value();

    const maxLength = Math.max(
      ...formattedData.map((item) => item.data.length)
    );
    formattedData.forEach((item) => {
      while (item.data.length < maxLength) {
        item.data.push(0);
      }
    });

    setReportQtySeries(formattedData);
    setReportPercentSeries(formattedData);
  };

  useEffect(() => {
    formatCategories();
    formatSeries();
  }, []);

  const colors = ["#f59e0b", "#40c057", "#e03131"];

  return categories && categories.length > 0 ? (
    <Box className="grid grid-cols-2 mt-5 gap-4">
      {reportQtySeries.length > 0 ? (
        <Box className="border" padding={2}>
          <ReportQty
            startDate={startDate}
            endDate={endDate}
            categories={categories}
            series={reportQtySeries}
            colors={colors}
          />
        </Box>
      ) : (
        <Box
          className="border"
          padding={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h6">ไม่พบรายงานสถิติรีเฟอร์</Typography>
        </Box>
      )}

      {reportPercentSeries.length > 0 ? (
        <Box className="border p-4">
          <ReportPercent
            startDate={startDate}
            endDate={endDate}
            categories={categories}
            series={reportPercentSeries}
            colors={colors}
          />
        </Box>
      ) : (
        <Box
          className="border"
          padding={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h6">ไม่พบรายงานเปอร์เซ็นรีเฟอร์</Typography>
        </Box>
      )}
    </Box>
  ) : null;
}
