import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Grid,
  Hidden,
  Typography,
} from "@mui/material";
import React from "react";
import { IPatientDetail } from "./type";
import patientImage from "../../assets/images/hospital-patient.png";
import patientFImage from "../../assets/images/hospital-patient-f.png";

const PatientDetail = ({
  patient,
  hospmain,
  hospCodeProvider,
}: IPatientDetail) => {
  const {
    citizenship,
    religion,
    race,
    contactInfo,
    emergencyInfo,
    address,
    fullname,
    drugAllergies,
  } = patient.data;
  const { ampur, houseNumber, changwat, moopart, road, tambon } = address;
  const { title, firstName, lastName } = fullname;
  const { mobileNumber } = contactInfo;
  const { familyName } = emergencyInfo;
  function calculateAge(dob: string) {
    if (!dob || new Date(dob).toString() === "Invalid Date") return "";
    const age =
      new Date().getMonth() > new Date(dob).getMonth()
        ? new Date().getFullYear() - new Date(dob).getFullYear()
        : new Date().getFullYear() - new Date(dob).getFullYear() - 1;

    return age;
  }
  function filterHN(
    Hn: { hospCode: string; value: string; _id: string }[] = [],
    hospCodeFilter = ""
  ) {
    if (!hospCodeFilter) return "";
    const valueHn: { hospCode: string; value: string; _id: string }[] =
      Hn.filter((item) => item?.hospCode === hospCodeFilter);

    return valueHn[0]?.value || "-";
  }
  function dateToLocalDateString(date: string) {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return (
    <div>
      <Card className="patient-data patient-data-primary">
        <CardContent className="patient-data patient-data-primary">
          <Grid container>
            <Grid item xs={3} md={1}>
              {patient?.data?.sex === "1" ? (
                <div className="rounded img-thumbnail img-fluid">
                  <img src={patientImage} alt="patient" width={400} />
                </div>
              ) : (
                <div className="rounded img-thumbnail img-fluid">
                  <img src={patientFImage} alt="patient-f" width={400} />
                </div>
              )}
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={7}>
              <Grid container>
                <Grid item xs={7}>
                  <h5>{`CID: ${patient?.cid}`}</h5>
                  <h5>{`ชื่อ-สกุล: ${title} ${firstName} ${lastName}`}</h5>
                  <h6>{`HN (รพ. ตามจ่าย): ${filterHN(
                    patient?.hn,
                    hospmain
                  )}`}</h6>
                  <h6>{`HN (รพ. เรียกเก็บ): ${filterHN(
                    patient?.hn,
                    hospCodeProvider
                  )}`}</h6>
                  <h6>{`วันเกิด: ${dateToLocalDateString(
                    patient?.data?.DOB
                  )} | อายุ ${calculateAge(patient?.data?.DOB)} ปี`}</h6>
                </Grid>
                <Grid item xs={5}>
                  <Hidden lgDown>
                    <h6>{`ที่อยู่ ${houseNumber || "-"} หมู่ ${
                      moopart || "-"
                    } ถนน ${road || "-"} อำเภอ/เขต ${ampur || "-"} ตำบล/แขวง ${
                      tambon || "-"
                    } จังหวัด ${changwat || "-"}`}</h6>
                    <h6>{`เชื้อชาติ ${citizenship || "-"}  สัญชาติ ${
                      race || "-"
                    }  ศาสนา ${religion || "-"}`}</h6>
                    <h6>{`เบอร์โทร: ${mobileNumber || "-"}`}</h6>
                    <h6>{`ผู้ติดต่อ: ${familyName || "-"}`}</h6>
                    <h6>{`เบอร์โทรผู้ติดต่อ: ${
                      emergencyInfo.mobileNumber || "-"
                    }`}</h6>
                    <h6>{`ที่อยู่ผู้ติดต่อ: ${
                      emergencyInfo?.address?.text || "-"
                    }`}</h6>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <h5
                style={
                  patient?.data?.condition?.ccPersistDisease
                    ? { color: "yellow" }
                    : { color: "white" }
                }
              >
                {`โรคประจำตัว/สภาพกาย ${
                  patient?.data?.condition?.ccPersistDisease || ""
                } `}
              </h5>
            </Grid>
            <Grid item xs={12}>
              <h5
                style={
                  Array.isArray(drugAllergies) && drugAllergies?.length > 0
                    ? { color: "yellow" }
                    : { color: "white" }
                }
              >
                <div className="float-left mr-1">แพ้ยา </div>
                {Array.isArray(drugAllergies) &&
                  drugAllergies?.map((item, index) => (
                    <div className="float-left mr-1" key={item?._id || index}>
                      {item?.genericname} ,
                    </div>
                  ))}
              </h5>
            </Grid>
          </Grid>

          <Hidden mdUp>
            <Grid>
              <Accordion className="bg-white text-black w-full">
                <AccordionSummary
                  expandIcon={<ExpandMore className="app-text-color" />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>ประวัติเพิ่มเติม</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item xs={12} md={12} lg={12}>
                      <div>
                        {`ที่อยู่ ${houseNumber || "-"} หมู่ ${
                          moopart || "-"
                        } ถนน ${road || "-"} อำเภอ/เขต ${
                          ampur || "-"
                        } ตำบล/แขวง ${tambon || "-"} จังหวัด ${
                          changwat || "-"
                        }`}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div>{`เชื้อชาติ ${citizenship || "-"}`}</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div>{`สัญชาติ ${race || "-"} `}</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div>{`ศาสนา ${religion || "-"}`}</div>
                    </Grid>
                    <Grid item xs={12}>
                      {`เบอร์โทร: ${mobileNumber || "-"}`}
                    </Grid>
                    <Grid item xs={12}>
                      {`ผู้ติดต่อ: ${familyName || "-"}`}
                    </Grid>
                    <Grid item xs={12}>
                      {`เบอร์โทรผู้ติดต่อ: ${
                        emergencyInfo.mobileNumber || "-"
                      }`}
                    </Grid>
                    <Grid item xs={12}>
                      {`ที่อยู่ผู้ติดต่อ: ${
                        emergencyInfo?.address?.text || "-"
                      }`}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Hidden>
        </CardContent>
      </Card>
    </div>
  );
};

export default PatientDetail;
