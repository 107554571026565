import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Box,
  TextField,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import TDepartment from "../../../types/department";

type TModalEdit = {
  token: string;
  open: boolean;
  onHandleModal(status: boolean): void;
  onEditDepartment(
    token: string,
    department: TDepartment,
    departName: string
  ): void;
  department: TDepartment;
};
function EditDepartmentModal({
  open,
  onHandleModal,
  department,
  token,
  onEditDepartment,
}: TModalEdit) {
  const [departName, setDepartName] = useState<string>(
    department.departName || ""
  );

  return (
    <Dialog
      open={open}
      onClose={() => onHandleModal(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>แก้ไขแผนก {department.departName} </DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            value={departName}
            onChange={(e) => setDepartName(e.target.value)}
          />
        </Box>
        <Box className="m-2">
          <Button
            onClick={() => onEditDepartment(token, department, departName)}
            variant="contained"
            color="warning"
          >
            แก้ไขแผนก
          </Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onHandleModal(false)}>ปิด</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditDepartmentModal;
