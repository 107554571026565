import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../fonts/vsf_fonts";
import { checkboxUrl } from "../dataUrl";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
};

export const printReferral = ({ patient, visit, referrals }) => {
  function dateToLocalDateString(date) {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  function calculateAge(dob) {
    if (!dob || new Date(dob).toString() === "Invalid Date") return "";
    const age =
      new Date().getMonth() > new Date(dob).getMonth()
        ? new Date().getFullYear() - new Date(dob).getFullYear()
        : new Date().getFullYear() - new Date(dob).getFullYear() - 1;
    return age;
  }

  function getAddress(patient) {
    if (!patient.data.address) {
      return "";
    }

    const address = patient.data.address;

    if (address.text) {
      return address.text;
    }

    const houseNumber = `${address.houseNumber} `;
    const moopart = address.moopart ? `หมู่ ${address.moopart} ` : "";
    const road = address.road ? `ถนน ${address.road} ` : "";
    const tambon = address.tambon ? `ตำบล ${address.tambon} ` : "";
    const ampur = address.ampur ? `อำเภอ ${address.ampur} ` : "";
    const changwat = address.changwat ? `จังหวัด ${address.changwat} ` : "";
    const areaCode = address.areaCode;

    return houseNumber + moopart + road + tambon + ampur + changwat + areaCode;
  }

  var docDefinition = {
    content:
      referrals?.length > 0
        ? referrals.map((referral) => [
            {
              text: "แบบส่งต่อสำหรับผู้ป่วยไปรับการตรวจหรือรักษาต่อ",
              style: "header",
              alignment: "center",
            },
            {
              columns: [
                {
                  text: [
                    { text: `จุดส่ง `, bold: true },
                    referral.data.referPoint,
                  ],
                  alignment: "left",
                },
                {
                  text: `วันที่ ${dateToLocalDateString(
                    referral.data.referDateTime?.toString()
                  )}`,
                  alignment: "right",
                },
              ],
            },
            {
              columns: [
                {
                  text: [
                    { text: `เลขที่ `, bold: true },
                    referral.data.referNumber,
                  ],
                  alignment: "left",
                },
                { text: `( โทรศัพท์ 038-996022 ต่อ 23 )`, alignment: "right" },
              ],
            },
            {
              text: [
                { text: `จาก `, bold: true },
                referral.fromHospital?.hospName,
              ],
            },
            {
              text: [
                { text: `ถึง `, bold: true },
                referral.toHospital?.hospName,
              ],
            },
            {
              columns: [
                {
                  text: `พร้อมหนังสือนี้ ขอส่งผู้ป่วยชื่อ ${patient?.data?.fullname?.firstName} ${patient?.data?.fullname?.lastName}`,
                  margin: [12, 0, 0, 0],
                },
                {
                  columns: [
                    { text: "เพศ", margin: [0, 0, 5, 0] },
                    {
                      image: checkboxUrl(),
                      width: 18,
                      height: 14,
                      margin: [0, 0, 5, 0],
                    },
                    { text: "ชาย", margin: [0, 0, 5, 0] },
                    {
                      image: checkboxUrl(),
                      width: 18,
                      height: 14,
                      margin: [0, 0, 5, 0],
                    },
                    { text: "หญิง", margin: [0, 0, 0, 0] },
                  ],
                  width: "auto",
                },
                {
                  text: `อายุ ${calculateAge(patient?.data?.DOB)} ปี`,
                  margin: [20, 0, 0, 0],
                },
              ],
            },
            {
              text: `hn ${referral.data.hn || "-"}   an ${
                referral.data.an || "-"
              }   โทรศัพท์ ${patient.data.contactInfo.mobileNumber || "-"}`,
            },
            { text: `ที่อยู่ ${getAddress(patient)}` },
            {
              text: [
                { text: `มาเพื่อโปรด `, bold: true },
                "..............................................................................................................................................................................................................................................",
              ],
              margin: [12, 0, 0, 0],
            },
            {
              text: [{ text: `CC: `, bold: true }, visit.visitVital.cc],
              margin: [12, 0, 0, 0],
            },
            {
              text: `1. ประวัติการป่วยปัจจุบัน`,
              bold: true,
            },
            { text: visit.visitVital.pi },

            { text: "ระดับความรู้สึกตัว" },
            {
              columns: [
                {
                  text: `TEMP= ${visit?.visitVital?.temperature || "-"} °C`,
                  width: 120,
                },
                {
                  text: `PULSE= ${visit?.visitVital?.pulse || "-"} /min.`,
                  width: 120,
                },
                {
                  text: `RR= ${visit?.visitVital?.rr || "-"} /min`,
                  width: 120,
                },
                {
                  text: `BP= ${visit?.visitVital?.bps} / ${visit?.visitVital?.bpd} mmHg`,
                  width: 120,
                },
              ],
              style: "marginTop",
            },
            {
              columns: [
                {
                  text: `FBS= ${visit?.visitVital?.fbs || "-"}`,
                  width: 120,
                },
                {
                  text: `WAIST= ${visit?.visitVital?.waist || "-"}`,
                  width: 120,
                },
                {
                  text: `HEIGHT= ${visit?.visitVital?.height || "-"}`,
                  width: 120,
                },
                {
                  text: `BW= ${visit?.visitVital?.bw || "-"}`,
                  width: 120,
                },
              ],
            },
            {
              columns: [
                {
                  text: "HEENT",
                  width: 40,
                },
                {
                  text: visit?.visitVital?.peHeent || "-",
                  width: 200,
                },
                { text: visit?.visitVital?.peHeentText || "-", width: 200 },
              ],
            },
            {
              columns: [
                {
                  text: "HEART",
                  width: 40,
                },
                {
                  text: visit?.visitVital?.peHeart || "-",
                  width: 200,
                },
                { text: visit?.visitVital?.peHeartText || "-", width: 200 },
              ],
            },
            {
              columns: [
                {
                  text: "LUNG",
                  width: 40,
                },
                {
                  text: visit?.visitVital?.peLung || "-",
                  width: 200,
                },
                { text: visit?.visitVital?.peLungText || "-", width: 200 },
              ],
            },
            {
              columns: [
                {
                  text: "AB",
                  width: 40,
                },
                {
                  text: visit?.visitVital?.peAb || "-",
                  width: 200,
                },
                { text: visit?.visitVital?.peAbText || "-", width: 200 },
              ],
            },
            {
              columns: [
                {
                  text: "EXT",
                  width: 40,
                },
                {
                  text: visit?.visitVital?.peExt || "-",
                  width: 200,
                },
                { text: visit?.visitVital?.peExtText || "-", width: 200 },
              ],
            },
            {
              columns: [
                {
                  text: "NEURO",
                  width: 40,
                },
                {
                  text: visit?.visitVital?.peNeuro || "-",
                  width: 200,
                },
                { text: visit?.visitVital?.peNeuroText || "-", width: 200 },
              ],
            },
            {
              columns: [
                {
                  text: "PE",
                  width: 40,
                },
                {
                  text: visit?.visitVital?.pe || "-",
                  width: 200,
                },
              ],
            },
            {
              columns: [
                {
                  text: "Obs ER",
                  width: 40,
                },
                {
                  text: visit?.note || "-",
                  width: 200,
                },
              ],
            },

            { text: "2. ประวัติการป่วยในอดีต / แพ้ยา", bold: true },
            visit.diagnoses.map((diagItem) => ({
              text: `${diagItem.icd10}: ${diagItem.icd10Name} ${diagItem.diagTypeName}`,
            })),
            patient.data.drugAllergies.map((allergy) => ({
              text: allergy.genericname,
            })),

            { text: "3. ผลการตรวจชันสูตรที่สำคัญ", bold: true },
            {
              text: ".........................................................................................................................................................................................................................................................................",
              style: "marginTop",
            },
            {
              text: ".........................................................................................................................................................................................................................................................................",
              style: "marginTop",
            },

            {
              text: [
                { text: "4. การวินิจฉัยเบื้องต้น ", bold: true },
                referral.data.preDiagnosis,
              ],
            },
            {
              text: [
                { text: "การวินิจฉัยหลัก ", bold: true },
                `${referral.data.icd} ${referral.data.icdName}`,
              ],
              margin: [10, 0, 0, 0],
            },

            { text: "5. การรักษาที่ได้ให้ไว้แล้ว", bold: true },
            {
              text: ".........................................................................................................................................................................................................................................................................",
              style: "marginTop",
            },
            {
              text: ".........................................................................................................................................................................................................................................................................",
              style: "marginTop",
            },
            {
              text: ".........................................................................................................................................................................................................................................................................",
              style: "marginTop",
            },

            {
              text: [
                { text: "6. สาเหตุที่ส่ง ", bold: true },
                referral.data.reason,
              ],
            },

            {
              columns: [
                {
                  text: "7. รายละเอียดอื่น ๆ ที่แนบมา",
                  bold: true,
                  width: "auto",
                  margin: [0, 0, 10, 0],
                },
                {
                  width: "*",
                  columns: [
                    { image: checkboxUrl(), width: 18, height: 14 },
                    { text: "Film X - Ray", fontSize: 10 },
                    { image: checkboxUrl(), width: 18, height: 14 },
                    { text: "ผล CT Brain", fontSize: 10 },
                    { image: checkboxUrl(), width: 18, height: 14 },
                    { text: "ผล MRI", fontSize: 10 },
                    { image: checkboxUrl(), width: 18, height: 14 },
                    { text: "ผล Lab", fontSize: 10 },
                    { image: checkboxUrl(), width: 18, height: 14 },
                    { text: "ผล EKG", fontSize: 10 },
                    { image: checkboxUrl(), width: 18, height: 14 },
                    { text: "อื่น ๆ ....................", fontSize: 10 },
                  ],
                },
              ],
            },
            {
              text: `** เอกสารนี้ใช้ได้ตั้งแต่ วันที่ ${dateToLocalDateString(
                referral.data.referDateTime?.toString()
              )}   ถึงวันที่ .......................................................   ID .......................................................`,
              bold: true,
            },

            {
              text: [
                { text: "8. สิทธิการรักษา ", bold: true },
                visit.pttypeName,
              ],
            },
            {
              text: [{ text: "   เลขที่สิทธิ ", bold: true }, visit.pttypeno],
              margin: [10, 0, 0, 0],
            },

            {
              table: {
                widths: ["*", "auto"],
                body: [
                  [
                    "",
                    {
                      stack: [
                        { text: "ขอแสดงความนับถือ", alignment: "center" },
                        {
                          text: "ลงชื่อ ............................................................................",
                          alignment: "center",
                        },
                        {
                          text:
                            referral.data.doctorName ||
                            "( ............................................................ )",
                          alignment: "center",
                        },
                        {
                          text: "เลขประกอบวิชาชีพ ว.....................",
                          alignment: "center",
                        },
                      ],
                    },
                  ],
                ],
              },
              layout: "noBorders",
            },
          ])
        : [],

    defaultStyle: {
      font: "THSarabunNew",
      fontSize: 12,
    },
    styles: {
      header: {
        bold: true,
        alignment: "center",
        fontSize: 16,
        margin: [0, 0, 0, 16],
      },
      marginTop: { margin: [0, 4, 0, 0] },
    },
    pageSize: "A4",
    pageOrientation: "portrait",
  };
  pdfMake.createPdf(docDefinition).open();
};
