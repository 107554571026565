import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

const pullAppointment = async (
  token: string,
  scheduleId: string,
  referId: string
) => {
  const url = getApiUrl();
  await axios.put(
    `${url}/v1/schedule/pull/appointment/${scheduleId}/${referId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export default pullAppointment;
