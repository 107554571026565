import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TClinic } from "../../types/clinic";

const fetchClinics = async (
  token: string,
  departId: string,
  hospCode: string
): Promise<TClinic[]> => {
  const url = getApiUrl();
  const result = await axios.get(
    `${url}/v1/clinic/${departId}?hospCode=${hospCode}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return result.data;
};

export default fetchClinics;
