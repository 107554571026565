import styled from "styled-components";
import MTTableRow from "@mui/material/TableRow";
import MTTableCell from "@mui/material/TableCell";
import withStyles from "@mui/styles/withStyles";

export const TableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
  },
}))(MTTableCell);

export const TableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(MTTableRow);

export const Container = styled.div`
  text-align: initial;
  .export {
    margin-right: 8px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-wrapper {
    display: flex;
    justify-content: flex-end;
  }
`;
