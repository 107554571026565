import CryptoJS from "crypto-js";

export function StrEncrypt(st, key) {
  const key2 = `${key}cGVyamVy`;

  let Encrypted = "";

  if (st && st !== "") {
    const b64 = CryptoJS.AES.encrypt(st, key2).toString();
    const e64 = CryptoJS.enc.Base64.parse(b64);
    Encrypted = e64.toString(CryptoJS.enc.Hex);
  } else {
    Encrypted = "";
  }

  return Encrypted;
}

export function StrDecrypt(st, key) {
  const key2 = `${key}cGVyamVy`;

  let Decrypt = "";

  if (st && st !== "") {
    const reb64 = CryptoJS.enc.Hex.parse(st);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, key2);
    Decrypt = decrypt.toString(CryptoJS.enc.Utf8);
  } else {
    Decrypt = "";
  }

  return Decrypt;
}

export function shortHospName(str) {
  let result = "";

  if (str) {
    result = str
      .replace("โรงพยาบาล", "รพ.")
      .replace("ส่งเสริมสุขภาพตำบล", "สต.")
      .replace("สำนักงานสาธารณสุขจังหวัด", "สสจ.")
      .replace("สำนักงานสาธารณสุขอำเภอ", "สสอ.")
      .replace("โรงพยาบาลศูนย์", "รพศ.")
      .replace("โรงพยาบาลชุมชน", "รพช.")
      .replace("สถานีอนามัย", "สอ.")
      .replace("ศูนย์สุขภาพชุมชน", "ศสช.");
  }

  return result;
}
const INVOICE_STATUS = {
  STARTED: { text: "รอตรวจสอบ", color: "#F3950D" },
  ACCEPTED: { text: "ตรวจสอบแล้ว", color: "#116530" },
  PAYER_REJECTED: { text: "ปฏิเสธ", color: "#E64A19" },
  CANCELLED: { text: "ยกเลิก", color: "#CD1818" },
  NULL: { text: "-", color: null },
};

export const getInvoiceStatusTranslate = (invoiceStatus) =>
  invoiceStatus
    ? INVOICE_STATUS[invoiceStatus.toUpperCase()]
    : INVOICE_STATUS.NULL;

const BILLING_STATUSES = {
  PROCESSING: { text: "รอส่งเรียกเก็บ", color: "#F57F17" },
  DEST_ACCEPTED: { text: "ปลายทางตรวจแล้ว", color: "#64DD17" },
  SOURCE_ACCEPTED: { text: "ต้นทางตรวจแล้ว", color: "#64DD17" },
  REJECTED: { text: "ผู้จ่ายไม่ตกลงจ่าย", color: "#E64A19" },
  EDITED: { text: "แก้ไขแล้ว", color: "#00BFA5" },
  COMPLETE: { text: "จบการเรียกเก็บ", color: "#64DD17" },
  NULL: { text: "-", color: null },
  PENDING: { text: "รอส่งเรียกเก็บ", color: "#FFB830" },
  ACCEPTED: { text: "จบการเรียกเก็บ", color: "#57CC99" },
  PROVIDER_SUBMITTED_FOR_REVIEW: { text: "ส่งเรียกเก็บแล้ว", color: "#3D56B2" },
  PAYER_EDITED: { text: "ผู้ตามจ่ายเสนอแก้ไข", color: "#E02401" },
  PROVIDER_EDITED: { text: "ผู้เรียกเก็บเสนอแก้ไข", color: "#FFB830" },
  PENDING_WITH_EDIT: { text: "รอส่งเรียกเก็บ*", color: "#E59934" },
  PAYER_REOPENED: { text: "ผู้ตามจ่ายเปิดใหม่", color: "#6ECB63" },
};

export const getBillingStatusConfig = (billingStatus) =>
  billingStatus
    ? BILLING_STATUSES[billingStatus.toUpperCase()]
    : BILLING_STATUSES.NULL;

export const formatNum = (num) => Number(num).toFixed(2);

export const sameStatus = (billingStatusFromDb, compare) => {
  const result = billingStatusFromDb.toUpperCase() === compare;

  return result;
};

export const statusInArray = (billingStatusFromDb, compareArray) => {
  const result = compareArray.includes(billingStatusFromDb.toUpperCase());

  return result;
};

export const hospDataFromHospCode = (hospCode) => {
  switch (hospCode) {
    case "10663":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลระยอง",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    // TODO: change it when get ss and al infomation
    case "10829":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลแกลง",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    // TODO: change it when get ss and al infomation
    case "10827":
      return {
        headerNumber: "x",
        hospName:
          "โรงพยาบาลเฉลิมพระเกียรติสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี ระยอง",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    // TODO: change it when get ss and al infomation
    case "10828":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลบ้านฉาง",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    // TODO: change it when get ss and al infomation
    case "10830":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลวังจันทร์",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    case "10831":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลบ้านค่าย",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    case "10832":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลปลวกแดง",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    case "22734":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลเขาชะเมาเฉลิมพระเกียรติ ๘๐ พรรษา",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    case "23962":
      return {
        headerNumber: "x",
        hospName: "โรงพยาบาลนิคมพัฒนา",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
    default:
      return {
        headerNumber: "x",
        hospName: "xx",
        address: ["xx ถนน xxx อำเภอxxx", "จังหวัดxxx xxxxx"],
        bank: {
          uc: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          ss: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
          al: {
            bankName: "ธนาคารxxx สาขาxxx",
            accountName: "xxxxxxx",
            accountNumber: "xxx-x-xxxxx-x",
          },
        },
        signature: {
          name: "นาย xxx xxxx",
          position: "xxxxxx",
          subPosition: "xxxxx",
        },
        footer: {
          name: "ศูนย์ประกันสุขภาพ",
          tel: "x-xxxx-xxxx-x  ต่อ xxxx",
          fax: "x-xxxx-xxxx",
          copy: "ฝ่ายบัญชี",
        },
      };
  }
};

export const calculateAge = ({ birthday, age }) => {
  if (birthday) {
    // birthday is a date
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  if (age) {
    return age;
  }

  return "-";
};
