import { AlertColor } from "@mui/material";
import axios from "axios";

// functions
import { getApiUrl } from "../../../functions/Commons";

interface IDeleteFileStorage {
  token: string;
  imageId: string;
  fileName: string;
}
interface IResponseAttactment {
  status: AlertColor;
  message: string;
}
const DeleteFileToStorage = async ({
  token,
  imageId,
  fileName,
}: IDeleteFileStorage): Promise<IResponseAttactment> => {
  const url = `${getApiUrl()}/v1/refer/storage/delete`;
  try {
    await axios.put(
      url,
      { imageId, fileName },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return {
      status: "success",
      message: "remove attachment success",
    };
  } catch (error: any) {
    console.log(error);
    return {
      status: "error",
      message: error.message,
    };
  }
};

export default DeleteFileToStorage;
