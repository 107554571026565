import styled from "styled-components";
import MTPaper from "@mui/material/Paper";

export const Container = styled.div`
  text-align: initial;
  display: flex;
  justify-content: center;
`;

export const Form = styled(MTPaper)`
  .title {
    margin: 16px 0 24px 0;
  }
  .form {
    display: flex;
    flex-direction: column;
    .input {
      margin: 8px 0;
    }
    .divider {
      margin: 16px 0 !important;
    }
    .billing {
      display: flex;
      justify-content: space-between;
    }
    .billing-item {
      margin-right: 8px;
    }
    .special {
      text-align: end;
      margin-right: 5px;
    }
    .action {
      display: flex;
      justify-content: flex-end;
      margin: 16px 0;
    }
    .cancel {
      margin-right: 16px;
      border-radius: 8px;
      color: #3f98d2;
    }
    .confirm {
      border-radius: 8px;
      background: #3f98d2;
      color: #ffff;
    }
    .checkbox {
      margin-left: 0;
    }
  }
`;
