import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export const fetchBillPatient = async (params) => {
  const { visitId, idToken } = params;
  try {
    const response = await axios.get(
      `${getApiUrl()}/v1/billings/details?visitId=${visitId}`,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return response.data;
  } catch (error) {
    return {};
  }
};

export const editBillItem = async (body) => {
  const { idToken } = body;
  try {
    const response = await axios.put(
      `${getApiUrl()}/v1/billings/editBillItem`,
      body,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return {
      ...response.data,
      message: "แก้ไขบิลไอเท็มเรียบร้อย",
      stausMessage: 200,
    };
  } catch (error) {
    return {
      message: "แก้ไขบิลไอเท็มล้มเหลว",
      stausMessage: 400,
    };
  }
};

export const providerSubmit = async (body) => {
  const { idToken } = body;
  try {
    const response = await axios.put(
      `${getApiUrl()}/v1/billings/providerSubmitBillForReview`,
      body,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return {
      ...response.data,
      message: "ส่งเรียกเก็บเรียบร้อย",
      stausMessage: 200,
    };
  } catch (error) {
    return {
      message: "ส่งเรียกเก็บล้มเหลว",
      stausMessage: 400,
    };
  }
};

export const acceptBill = async (body) => {
  const { idToken } = body;
  try {
    const response = await axios.put(
      `${getApiUrl()}/v1/billings/accept`,
      body,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return {
      ...response.data,
      message: "จบการเรียกเก็บเรียบร้อย",
      stausMessage: 200,
    };
  } catch (error) {
    return {
      message: "จบการเรียกเก็บล้มเหลว",
      stausMessage: 400,
    };
  }
};

export const reopenBill = async (body) => {
  const { idToken } = body;
  try {
    const response = await axios.put(
      `${getApiUrl()}/v1/billings/reopen`,
      body,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return {
      ...response.data,
      message: "เปิดเคสใหม่เรียบร้อย",
      stausMessage: 200,
    };
  } catch (error) {
    return {
      message: "เปิดเคสใหม่ล้มเหลว",
      stausMessage: 400,
    };
  }
};
