import React from "react";
import { Typography } from "@mui/material";

type ILabReport = {
  labItemsNameRef: string;
  labOrderResult: string;
  labItemsNormalValueRef: string;
};
function LabReportItem({
  labItemsNameRef,
  labOrderResult,
  labItemsNormalValueRef,
}: ILabReport) {
  return (
    <div className="grid grid-cols-3 bg-white px-5 py-5">
      <div>
        <Typography
          variant="subtitle2"
          color="rgba(0, 0, 0, 0.55)"
          style={{ wordWrap: "break-word" }}
        >
          {labItemsNameRef || "-"}
        </Typography>
      </div>
      <div>
        <Typography
          variant="subtitle2"
          color="rgba(0, 0, 0, 0.55)"
          style={{ wordWrap: "break-word" }}
        >
          {labOrderResult || "-"}
        </Typography>
      </div>
      <div>
        <Typography
          variant="subtitle2"
          color="rgba(0, 0, 0, 0.55)"
          style={{ wordWrap: "break-word" }}
        >
          {labItemsNormalValueRef || "-"}
        </Typography>
      </div>
    </div>
  );
}

export default LabReportItem;
