import React from "react";
import { Box, Typography } from "@mui/material";
import XrayItem from "./XrayItem";
import dayjs from "dayjs";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

function groupXraysByMonth(xrays: any[]) {
  const groupedXrays: Record<string, any[]> = {};

  xrays.forEach((xray) => {
    xray.xrayReportData.forEach((report: any) => {
      const monthYear = dayjs(report.requestDateTime).format("YYYY-MM");
      if (!groupedXrays[monthYear]) {
        groupedXrays[monthYear] = [];
      }
      groupedXrays[monthYear].push({
        ...report,
      });
    });
  });

  const sortedGroupedXrays = Object.keys(groupedXrays)
    .sort((a, b) => dayjs(b).diff(dayjs(a)))
    .reduce((acc, key) => {
      acc[key] = groupedXrays[key];
      return acc;
    }, {} as Record<string, any[]>);

  return sortedGroupedXrays;
}

function Xrays({ xrays }: any) {
  const groupedXrays = groupXraysByMonth(xrays);

  return (
    <Box
      sx={{ maxHeight: "512px" }}
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box
        sx={{ borderBottom: "1px solid #D4D9DF", boxSizing: "border-box" }}
        height="40px"
        padding="5px 20px"
      >
        <Typography variant="h6" color="#58595B">
          X-Ray
        </Typography>
      </Box>
      <Box className="px-3 overflow-scroll">
        {Object.keys(groupedXrays).map((monthYear) => (
          <Box key={monthYear} className="mb-4">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", padding: "12px", color: "#58595B" }}
            >
              {dateToLocalDateString(monthYear, false)}
            </Typography>
            <div className="grid grid-cols-3 gap-2">
              {groupedXrays[monthYear].map((report) => (
                <XrayItem
                  key={report.requestDateTime}
                  xrayItemsName={report?.xrayItemsName}
                  requestDateTime={report?.requestDateTime}
                  reportDateTime={report?.reportDateTime}
                  reportText={report?.reportText}
                />
              ))}
            </div>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Xrays;
