import React, { useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DatePicker from "@mui/lab/DatePicker";
import { CalculateBill } from "../../api/Billings/CalculateBilling";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  LinearProgress,
  Box,
  DialogActions,
  Button,
} from "@mui/material";

interface ICalculateBillingModal {
  open: boolean;
  token: string;
  handleClose(): void;
}
function CalculateBillingModal({
  open,
  token,
  handleClose,
}: ICalculateBillingModal) {
  const [date, setDate] = useState<Date | null>(null);

  const [currentDoc, setCurrentDoc] = useState(0);

  const [totalDocs, setTotalDocs] = useState(0);

  const [loading, setLoading] = useState<boolean>(false);

  const onChangeDate = (date: Date) => {
    setDate(date);
  };

  const downloadBilling = async () => {
    try {
      if (!date) {
        throw Error("กรุณาตรวจสอบวันที่");
      }
      const result = await CalculateBill(date, token);
      if (result.totalVisits === 0) {
        throw Error("ไม่มีข้อมูลให้คำนวณประจำเดือนนี้");
      }
      setLoading(true);
      setTotalDocs(result.totalVisits);
      setCurrentDoc(result.page * result.limit);
      for (let page = result.page; page <= result.totalPages; page++) {
        if (page > 1) {
          // index 1 = รอบแรกซึ่งทำไปแล้ว
          await CalculateBill(date, token, page);
          if (page === result.totalPages) {
            setCurrentDoc(result.totalPages);
            setLoading(false);
          } else {
            setCurrentDoc(page * result.limit);
          }
        }
      }
    } catch (error) {
      alert(error);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading) {
          handleClose;
        }
      }}
      fullWidth
      maxWidth="sm"
      sx={{
        backdropFilter: "blur(5px) sepia(5%)",
        "& .MuiDialog-paper": {
          borderRadius: "20px",
        },
      }}
    >
      <DialogTitle sx={{ color: "#3f98D2" }}>คำนวณ billing</DialogTitle>
      <DialogContent>
        <DialogContentText>
          เลือกเดือนที่จะทำการคำนวณ billing ใหม่
        </DialogContentText>
        <div className="flex flex-grow items-center">
          <DatePicker
            views={["year", "month"]}
            value={date}
            inputFormat="DD/MM/YYYY"
            onChange={(date) => onChangeDate(date || new Date())}
            renderInput={(params) => (
              <TextField {...params} className="!rounded-full" />
            )}
            InputProps={{
              endAdornment: <CalendarTodayIcon className="text-gray-500" />,
            }}
          />
        </div>
        {loading && (
          <div>
            <p>
              กำลังคำนวณ {currentDoc} / {totalDocs}
            </p>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress color="primary" />
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => downloadBilling()}
          disabled={loading}
          color="primary"
        >
          เริ่มการคำนวณ
        </Button>
        <Button onClick={handleClose} disabled={loading} color="error">
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CalculateBillingModal;
