import React, { useState } from "react";
// mui
import { Modal, Grid, Button, TextField, Divider } from "@mui/material";
import { ContentModal } from "../../InvoiceCharge/styles";
import { IEditItemList, IModalBillItem } from "../type";

function ModalBillItem(props: IModalBillItem) {
  const { open, billItem, onCloseModal, onEditedBillItem } = props;

  const [latestFinalPrice, setLatestFinalPrice] = useState<string>("");
  const [mark, setMark] = useState("");

  const onConfirm = async () => {
    const editedBillItem: IEditItemList = {
      billItemId: billItem?._id,
      latestFinalPrice: parseFloat(latestFinalPrice),
      message: mark,
    };

    onEditedBillItem(editedBillItem);
  };

  const numberToLocalString = (number: number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  return (
    <div>
      <Modal open={open} onClose={onCloseModal}>
        <Grid container direction="column" alignItems="center">
          <ContentModal>
            <Grid container justifyContent="center">
              <Grid item xs={3}>
                <p>รหัส icode:</p>
              </Grid>
              <Grid item xs={9}>
                <strong>{billItem?.icode}</strong>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={3}>
                <p>รายการ:</p>
              </Grid>
              <Grid item xs={9}>
                <strong>{billItem?.drugNondugFullName}</strong>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={3}>
                <p>ราคาตามจริง:</p>
              </Grid>
              <Grid item xs={9}>
                <strong>{`${numberToLocalString(
                  billItem?.actualPrice
                )} บาท`}</strong>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={3}>
                <p>ราคาคำนวณ:</p>
              </Grid>
              <Grid item xs={9}>
                <strong>{`${numberToLocalString(
                  billItem?.calculatedPrice
                )} บาท`}</strong>
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item className="self-center" xs={3}>
                ราคาเรียกเก็บ:
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  type="number"
                  margin="dense"
                  variant="outlined"
                  label="ราคาเรียกเก็บ"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setLatestFinalPrice(e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Grid item className="self-center" xs={3}>
                เพิ่มหมายเหตุ:
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="เพิ่มหมายเหตุ"
                  variant="outlined"
                  onChange={(e) => setMark(e.target.value)}
                />
              </Grid>
            </Grid>

            <Divider className="my-2" />

            <Grid container justifyContent="flex-end">
              <Button color="primary" variant="contained" onClick={onConfirm}>
                แก้ไข
              </Button>

              <Button
                className="ml-2"
                color="secondary"
                variant="contained"
                onClick={onCloseModal}
              >
                ยกเลิก
              </Button>
            </Grid>
          </ContentModal>
        </Grid>
      </Modal>
    </div>
  );
}

export default ModalBillItem;
