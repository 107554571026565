import axios from "axios";
import { getApiUrl } from "../../../functions/Commons";

const getFileFromStorage = async (
  fileName: string,
  token: string
): Promise<string> => {
  try {
    const url = `${getApiUrl()}/v1/refer/file/expire?fileName=${fileName}`;
    const resp = await axios.get(url, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return resp.data;
  } catch (error) {
    console.log(error);
    return "";
  }
};
export default getFileFromStorage;
