import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// languages
import common_en from "./translations/en/common.json";
import common_th from "./translations/th/common.json";
import menu_en from "./translations/en/menu.json";
import menu_th from "./translations/th/menu.json";
import referral_en from "./translations/en/referral.json";
import referral_th from "./translations/th/referral.json";
import billing_en from "./translations/en/billing.json";
import billing_th from "./translations/th/billing.json";
import admin_en from "./translations/en/admin.json";
import admin_th from "./translations/th/admin.json";
import patientemr_en from "./translations/en/patientemr.json";
import patientemr_th from "./translations/th/patientemr.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    lng: "th",
    resources: {
      en: {
        common: common_en,
        menu: menu_en,
        referral: referral_en,
        billing: billing_en,
        admin: admin_en,
        patientemr: patientemr_en,
      },
      th: {
        common: common_th,
        menu: menu_th,
        referral: referral_th,
        billing: billing_th,
        admin: admin_th,
        patientemr: patientemr_th,
      },
    },
  });

export default i18n;
