import React from "react";
import { VisitFilterRadioValue } from "../../../constants/commons";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface IFilterDateTypeRadio {
  filterDateType: VisitFilterRadioValue;
  onRadioChange: (value: VisitFilterRadioValue) => void;
}

const FilterDateTypeRadio: React.FC<IFilterDateTypeRadio> = ({
  filterDateType,
  onRadioChange,
}) => {
  const { t } = useTranslation("patientemr");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onRadioChange(
      (event.target as HTMLInputElement).value as VisitFilterRadioValue
    );
  };

  return (
    <FormControl>
      <RadioGroup row value={filterDateType} onChange={handleChange}>
        <FormControlLabel
          value={VisitFilterRadioValue.MONTH}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                padding: 0.5,
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: 12 }}>
              {t("visit_list.month")}
            </Typography>
          }
          sx={{ margin: 0 }}
        />
        <FormControlLabel
          value={VisitFilterRadioValue.CUSTOM}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 16,
                },
                padding: 0.5,
              }}
            />
          }
          label={
            <Typography sx={{ fontSize: 12 }}>
              {t("visit_list.custom")}
            </Typography>
          }
          sx={{ margin: 0 }}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default FilterDateTypeRadio;
