import React, { useEffect, useState } from "react";
import { TClinic } from "../../../types/clinic";
import fetchClinics from "../../../api/SettingAppointment/fetchClinics";
import Doctors from "./Doctors";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore, MoreVert } from "@mui/icons-material";
import Swal from "sweetalert2";
import "../../AppointmentOPD/css/AlertStyle.css";
import deleteClinic from "../../../api/SettingAppointment/deleteClinic";
import updateClinic from "../../../api/SettingAppointment/updateClinic";
import EditClinicModal from "./EditClinicModal";

type IClinicProps = {
  departId: string;
  token: string;
  hospCode: string;
  fetching: boolean;
};
function Clinic({ departId, token, hospCode, fetching }: IClinicProps) {
  const [clinics, setClinics] = useState<TClinic[]>([]);

  useEffect(() => {
    onFetchClinic(token, departId, hospCode);
  }, [departId, fetching]);

  const onFetchClinic = async (
    token: string,
    departId: string,
    hospCode: string
  ) => {
    try {
      const result = await fetchClinics(token, departId, hospCode);
      setClinics(result);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const onEditClinicName = async (
    token: string,
    clinic: TClinic,
    clinicName: string,
    note: string
  ) => {
    try {
      if (!clinic || !clinic._id) return;
      await updateClinic(token, clinic._id, { ...clinic, clinicName, note });
      await onFetchClinic(token, clinic.departId, clinic.hospCode);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "แก้ไขข้อมูลคลินิกเรียบร้อย",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.log("error edit clinic", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "แก้ไขข้อมูลคลินิกล้มเหลว",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const onDeleteClinic = async (clinic: TClinic) => {
    try {
      if (!clinic || !clinic._id) return;
      const { isConfirmed } = await Swal.fire({
        title: `คุณต้องการที่จะลบคลินิก ${clinic.clinicName} ใช่หรือไม่`,
        showDenyButton: true,
        confirmButtonText: "บันทึก",
        denyButtonText: `ยกเลิก`,
      });
      if (isConfirmed) {
        await deleteClinic(token, clinic._id);
        await onFetchClinic(token, clinic.departId, clinic.hospCode);
      }
    } catch (error) {
      console.log("delete clinic error", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "ลบข้อมูลคลินิกล้มเหลว",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div>
      {clinics &&
        clinics.map((clinic) => (
          <CollapseRow
            token={token}
            clinic={clinic}
            key={clinic._id}
            onDeleteClinic={onDeleteClinic}
            onEditClinicName={onEditClinicName}
          />
        ))}
    </div>
  );
}

type TCollapseRow = {
  token: string;
  clinic: TClinic;
  onEditClinicName(
    token: string,
    clinic: TClinic,
    clinicName: string,
    note: string
  ): void;
  onDeleteClinic(clinic: TClinic): void;
};
const CollapseRow = ({
  token,
  clinic,
  onDeleteClinic,
  onEditClinicName,
}: TCollapseRow) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onHandleModal = (status: boolean) => setOpenEdit(status);
  return (
    <div className="mx-6">
      <Divider textAlign="left">
        <Box display="flex">
          <IconButton
            onClick={() => setOpen(!open)}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
          <Typography>
            {clinic.clinicName}
            <IconButton onClick={handleMenuOpen}>
              <MoreVert />
            </IconButton>
            <Menu
              key={clinic._id}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => onHandleModal(true)}>แก้ไข</MenuItem>
              <MenuItem onClick={() => onDeleteClinic(clinic)}>ลบ</MenuItem>
            </Menu>
          </Typography>
        </Box>
      </Divider>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Doctors token={token} clinic={clinic} />
      </Collapse>
      <EditClinicModal
        token={token}
        open={openEdit}
        onHandleModal={onHandleModal}
        onEditClinicName={onEditClinicName}
        clinic={clinic}
      />
    </div>
  );
};
export default Clinic;
