const emergencyText = (emergencyTypeName: string, hostname: string) => {
  const isPRI = hostname.toUpperCase().includes("PRI");

  switch (emergencyTypeName) {
    case "Life threatening":
    case "Unconcious":
      return {
        text: isPRI ? "Resuscitate (ESI 1)" : emergencyTypeName,
        sortOrder: 1,
      };
    case "Emergency":
      return {
        text: isPRI ? "Emergency (ESI 2)" : emergencyTypeName,
        sortOrder: 2,
      };
    case "Urgent":
      return {
        text: isPRI ? "Urgent (ESI 3)" : emergencyTypeName,
        sortOrder: 3,
      };
    case "Acute":
      return {
        text: isPRI ? "Ac.illness (ESI 4)" : emergencyTypeName,
        sortOrder: 4,
      };
    case "Non acute":
    case "":
      return {
        text: isPRI ? "Non Ac.illness (ESI 5)" : emergencyTypeName,
        sortOrder: 5,
      };
    default:
      return { text: isPRI ? "ไม่ระบุ" : emergencyTypeName, sortOrder: 6 };
  }
};

export { emergencyText };
