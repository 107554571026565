import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

export default (token: string, bid: string, referDateTime: Date) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/refer/read`;

  const body = {
    bid,
    referDateTime,
  };
  console.log(body);

  return axios
    .post(url, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(() => {
      const dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
