import { APPDATA } from "../../constants/appData";
import fetchDistinctRoles from "../../api/PermissionManagement/fetchDistinctRoles.service";

export default ({
  idToken,
  hospCode,
}: {
  idToken: string;
  hospCode: string;
}) => ({
  type: APPDATA,
  payload: fetchDistinctRoles({ idToken, hospCode }),
});
