import { APPDATA } from "../constants/appData";
import FetchUsers from "../api/UserManagement/FetchUsers";

export function fetchUsers({
  idToken,
  page,
  limit,
  sortColumn,
  sortType,
  filterText,
  filterList,
}) {
  return {
    type: APPDATA,
    payload: FetchUsers({
      idToken,
      page,
      limit,
      sortColumn,
      sortType,
      filterText,
      filterList,
    }),
  };
}
