import {
  APPPROP_PENDING,
  APPPROP_FULFILLED,
  APPPROP_REJECTED,
} from "../constants/appProperty";

const initialState = {
  ROOT_API: "",
  mailHost: "",
  visitSelect: "",
  ScKey: "",
  theme: "",
  visitLimit: 100,
  visitMenuClose: false,
  FetchingStatus: false,
  notifications: null,
  notificationsRead: null,
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case APPPROP_PENDING:
      return {
        ...state,
        FetchingStatus: true,
      };
    case APPPROP_FULFILLED:
      return {
        ...state,
        ...action.payload,
      };
    case APPPROP_REJECTED:
      return {
        ...state,
        FetchingStatus: false,
      };

    default:
      return state;
  }
}
