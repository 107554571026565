import React, { useState } from "react";
import { AssignmentReturned, CalendarToday } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import OperationItem from "./OperationItem";
import { IOperation } from "@/types/visit";

interface IOperations {
  operations: IOperation[];
}
function Operations({ operations }: IOperations) {
  const [isExpanded, setIsExpanded] = useState(true);
  const onChange = () => {
    setIsExpanded(!isExpanded);
  };
  const operationItem = (operationsParams: IOperation[]) =>
    operationsParams.map(
      (
        { requestOperationDateTime, operationDiagText, operationItemName },
        index: number
      ) => (
        <OperationItem
          key={index}
          requestOperationDateTime={requestOperationDateTime}
          operationDiagText={operationDiagText}
          operationItemName={operationItemName}
        />
      )
    );

  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-lg border-solid border-gray border-2 my-4"
    >
      <Accordion onChange={onChange} expanded={isExpanded}>
        <AccordionSummary
          expandIcon={<AssignmentReturned sx={{ color: "#79B7DF" }} />}
        >
          <Box className="flex items-center">
            <CalendarToday sx={{ color: "#79B7DF" }} />
            <Typography variant="h6" color="#58595B">
              Operation(หัตถการ)
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{operationItem(operations)}</AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default Operations;
