import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route, useHistory, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { SnackbarProvider } from "notistack";
import Home from "./Homes/Home";
import UserLogin from "./Authentications/UserLogin";
import AppBackdrop from "./AppBackdrop";
import PatientEmrLayout from "./PatientEmr/PatientEmrLayout";
import ReferIn from "./Refers/ReferIn";
import ReferOut from "./Refers/ReferOut";
import OnlineHospital from "./OnlineHospital/OnlineHospital";
import EditCategory from "./Settings/EditCategory/EditCategory";
import UserForm from "./UserManagement/UserForm";
import UserManagement from "./UserManagement/UserManagement/UserManagement";
import { HospitalForm, HospitalManagement } from "./HospitalManagement";
import InvoiceList from "./InvoiceCharge/InvoiceList";
import InvoiceDetail from "./InvoiceCharge/InvoiceDetail";
import CreateInvoice from "./InvoiceCharge/CreateInvoice";
import PatientInvoice from "./PatientInvoice/PatientInvoice";
import NoneInvoiceDetail from "./InvoiceCharge/NoneInvoiceDetail";
import ReportMain from "./Report/ReportMain";
import ServicePermissionMain from "./ServicePermission/ServicePermissionMain";
import SampleData from "./sampleData";
import PopulationHealth from "./PopulateHealth/demo";
import ReportDetail from "./PopulateHealth/demo/reportDetail";
import Settings from "./Settings/Settings";
import ReasonRefer from "./ReasonRefer";
import Calendar from "./AppointmentOPD/calendar";
import AppointmentSchedule from "./AppointmentOPD/Schedule";
import DepartmentHospital from "./SettingAppointment/DepartmentHospital";
import HospitalAppointment from "./AppointmentOPD/hospitalAppointment";
import ResetPasswordDialog from "./Authentications/ResetPasswordDialog";
import LoginHistory from "./LoginHistory/LoginHistory";
import ResetPassword from "./ResetPassword/ResetPassword";

function ContainerMain({ appData }) {
  const history = useHistory();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const redirect = ({ previousUser, currentUser }) => {
    if (currentUser && previousUser) {
      if (currentUser !== previousUser) {
        setTimeout(() => {
          history.push("/");
        }, 500);
      }
    }
  };

  useEffect(() => {
    redirect({
      previousUser: appData?.previousUser,
      currentUser: appData?.loginData?.username,
    });

    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [appData.loginData, appData.previousUser]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      variant="info"
    >
      <div id="main" className="flex-grow overflow-auto h-screen">
        <AppBackdrop />
        <UserLogin />

        <ResetPasswordDialog open={appData.loginData?.isFirstLogin} />

        <Switch>
          <Route
            exact
            path="/reset-password"
            render={(props) => (
              <ResetPassword {...props} width={width} height={height} />
            )}
          />
        </Switch>

        {appData.idToken && (
          <>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Home {...props} Layout={Home} />}
              />
              <Route
                exact
                path="/patient-emr/:cid?/:visitBid?/:hospCode?/:hn?"
                render={(props) => (
                  <PatientEmrLayout
                    {...props}
                    width={width}
                    height={height}
                    // Layout={PatientEmrLayout}
                  />
                )}
              />
              <Route
                exact
                path="/refer-in/:startDate?/:endDate?"
                render={(props) => (
                  <ReferIn
                    {...props}
                    width={width}
                    height={height}
                    Layout={ReferIn}
                  />
                )}
              />
              <Route
                exact
                path="/refer-out/:startDate?/:endDate?"
                render={(props) => (
                  <ReferOut
                    {...props}
                    width={width}
                    height={height}
                    Layout={ReferOut}
                  />
                )}
              />

              <Route
                exact
                path="/hosp-refer-report"
                render={(props) => (
                  <ReportMain {...props} Layout={ReportMain} />
                )}
              />

              <Route
                exact
                path="/online-hospital"
                render={(props) => (
                  <OnlineHospital
                    {...props}
                    width={width}
                    height={height}
                    Layout={OnlineHospital}
                  />
                )}
              />

              <Route
                exact
                path="/billing-charge/to"
                render={(props) => (
                  <InvoiceList {...props} width={width} height={height} />
                )}
              />

              <Route
                exact
                path="/billing-charge/to/detail/:provider/:payer/:invoiceId/:categoryId"
                render={(props) => (
                  <InvoiceDetail {...props} width={width} height={height} />
                )}
              />

              <Route
                exact
                path="/create-invoice"
                render={(props) => (
                  <CreateInvoice {...props} width={width} height={height} />
                )}
              />

              <Route
                exact
                path="/billing-charge/from"
                render={(props) => (
                  <InvoiceList {...props} width={width} height={height} />
                )}
              />
              <Route
                exact
                path="/billing-charge/from/detail/:provider/:payer/:invoiceId/:categoryId"
                render={(props) => (
                  <InvoiceDetail {...props} width={width} height={height} />
                )}
              />
              <Route
                exact
                path="/billing-charge/none/detail/:provider/:payer/:categoryId"
                render={(props) => (
                  <NoneInvoiceDetail {...props} width={width} height={height} />
                )}
              />

              <Route
                exact
                path="/billing-patient/:visitId/:categoryId"
                render={(props) => (
                  <PatientInvoice {...props} width={width} height={height} />
                )}
              />

              <Route
                exact
                path="/settings"
                render={(props) => (
                  <Settings
                    {...props}
                    width={width}
                    height={height}
                    Layout={Settings}
                  />
                )}
              />

              <Route
                exact
                path="/settings/edit-category/"
                render={(props) => (
                  <EditCategory
                    {...props}
                    width={width}
                    height={height}
                    Layout={EditCategory}
                  />
                )}
              />

              <Route
                exact
                path="/management/reason"
                render={(props) => (
                  <ReasonRefer
                    {...props}
                    width={width}
                    height={height}
                    Layout={ReasonRefer}
                  />
                )}
              />

              <Route
                exact
                path="/settings/edit-category/:id"
                render={(props) => (
                  <EditCategory
                    {...props}
                    width={width}
                    height={height}
                    Layout={EditCategory}
                  />
                )}
              />

              <Route
                exact
                path="/user-management"
                render={(props) => (
                  <UserManagement
                    {...props}
                    width={width}
                    height={height}
                    Layout={UserManagement}
                  />
                )}
              />

              <Route
                exact
                path="/user-management/add-edit-user"
                render={(props) => (
                  <UserForm
                    {...props}
                    width={width}
                    height={height}
                    Layout={UserForm}
                  />
                )}
              />

              <Route
                exact
                path="/user-management/add-edit-user/:id"
                render={(props) => (
                  <UserForm
                    {...props}
                    width={width}
                    height={height}
                    Layout={UserForm}
                  />
                )}
              />

              <Route
                exact
                path="/hospital-management"
                render={(props) => (
                  <HospitalManagement
                    {...props}
                    width={width}
                    height={height}
                    Layout={HospitalManagement}
                  />
                )}
              />

              <Route
                exact
                path="/hospital-management/add-edit-hospital"
                render={(props) => (
                  <HospitalForm
                    {...props}
                    width={width}
                    height={height}
                    Layout={HospitalForm}
                  />
                )}
              />

              <Route
                exact
                path="/hospital-management/add-edit-hospital/:hospCode"
                render={(props) => (
                  <HospitalForm
                    {...props}
                    width={width}
                    height={height}
                    Layout={HospitalForm}
                  />
                )}
              />

              <Route
                exact
                path="/permission-management"
                render={(props) => (
                  <ServicePermissionMain
                    {...props}
                    width={width}
                    height={height}
                    Layout={ServicePermissionMain}
                  />
                )}
              />

              <Route
                exact
                path="/report-link"
                render={(props) => (
                  <PopulationHealth {...props} width={width} height={height} />
                )}
              />

              <Route
                exact
                path="/report-link/detail/:docId"
                render={(props) => (
                  <ReportDetail {...props} width={width} height={height} />
                )}
              />
              <Route
                exact
                path="/manage/sample/data"
                render={(props) => (
                  <SampleData {...props} width={width} height={height} />
                )}
              />

              <Route
                exact
                path="/doctor-appointment"
                render={(props) => (
                  <Calendar {...props} width={width} height={height} />
                )}
              />
              <Route
                exact
                path="/slot-schedule/:referId"
                render={(props) => (
                  <Calendar {...props} width={width} height={height} />
                )}
              />
              <Route
                exact
                path="/verify-appointment"
                render={(props) => (
                  <HospitalAppointment
                    {...props}
                    width={width}
                    height={height}
                  />
                )}
              />
              <Route
                exact
                path="/setting-schedule"
                render={(props) => (
                  <AppointmentSchedule
                    {...props}
                    width={width}
                    height={height}
                  />
                )}
              />

              <Route
                exact
                path="/setting-department"
                render={(props) => (
                  <DepartmentHospital
                    {...props}
                    width={width}
                    height={height}
                  />
                )}
              />

              <Route
                exact
                path="/login-history"
                render={(props) => (
                  <LoginHistory {...props} width={width} height={height} />
                )}
              />
            </Switch>
          </>
        )}
      </div>
    </SnackbarProvider>
  );
}

function mapStateToProps(state) {
  return {
    appData: state.appData,
  };
}

ContainerMain.propTypes = {
  appData: PropTypes.shape({
    idToken: PropTypes.string,
    previousUser: PropTypes.string,
    loginData: PropTypes.shape({
      username: PropTypes.string,
      hospCode: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(ContainerMain));
