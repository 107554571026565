import React from "react";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import UpdateIcon from "@mui/icons-material/Update";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ReferralType } from "@/types/refers";

interface ICountBox {
  nTimes: number;
  encounterType: ReferralType | "PENDING" | "ACCEPTED" | "REJECTED";
}

export default function CountBox({ nTimes, encounterType }: ICountBox) {
  return (
    <div className="flex flex-col justify-center items-center border p-2 rounded-xl">
      <div className="inline-flex">
        {encounterType === "REFER_IN" && (
          <>
            <SystemUpdateAltIcon className="mr-2 text-sky-600" />
            <p className="text-gray-400">Refer In</p>
          </>
        )}

        {encounterType === "REFER_OUT" && (
          <>
            <SystemUpdateAltIcon className="mr-2 text-sky-600" />
            <p className="text-gray-400">Refer Out</p>
          </>
        )}

        {encounterType === "PENDING" && (
          <>
            <UpdateIcon className="mr-2 text-amber-500" />
            <p className="text-gray-400">Pending</p>
          </>
        )}

        {encounterType === "ACCEPTED" && (
          <>
            <CheckCircleOutlineIcon className="mr-2 text-green-600" />
            <p className="text-gray-400">Accepted</p>
          </>
        )}

        {encounterType === "REJECTED" && (
          <>
            <HighlightOffIcon className="mr-2 text-red-600" />
            <p className="text-gray-400">Rejected</p>
          </>
        )}
      </div>

      <h4 className="font-bold mt-2">{`${nTimes} ครั้ง`}</h4>
    </div>
  );
}

CountBox.defaultProps = {
  nPeople: 0,
};
