import axios from "axios";
import jwtDecode from "jwt-decode";
import { StrEncrypt } from "../../functions/FuncPerjer";
import { getApiUrl, isConfirmationExpired } from "../../functions/Commons";
import { ILogin, IUser } from "@/types/user";

export default async ({ username, password }: ILogin) => {
  try {
    const bodyData = {
      username,
      password,
      returnSecureToken: true,
    };

    const res = await axios.post(`${getApiUrl()}/user/login`, bodyData);

    const loginData: IUser = jwtDecode(res.data.token);
    const rules = res.data.rules;

    const twoFactor = loginData.security?.twoFactor;
    const isTwoFactorEnabled = twoFactor?.enabled;
    const lastConfirmationDateTime = twoFactor?.lastConfirmationDateTime;
    const isCheckTwoFactor =
      !lastConfirmationDateTime ||
      isConfirmationExpired(lastConfirmationDateTime);

    let loginStatus = true;
    if (isTwoFactorEnabled && isCheckTwoFactor) {
      loginStatus = false;
    }

    const data = {
      loginData,
      loginStatus,
      status: loginData.status,
      idToken: res.data.token,
      FetchingStatus: false,
      alert: { show: false, msg: "", type: "success" },
      permissionRules: rules,
    };

    const expireDate = new Date();
    expireDate.setHours(expireDate.getHours() + 1);

    localStorage.setItem("Token", StrEncrypt(res.data.token, "CrIRWrUNC"));
    localStorage.setItem("expireDate", expireDate.toISOString());
    return data;
  } catch (error) {
    console.log("AXIOS ERROR: User Login error !");

    const err = error as Error;
    const errorLogin = {
      FetchingStatus: false,
      alert: { show: true, msg: err.message, type: "error" },
    };

    return errorLogin;
  }
};
