import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

interface params {
  idToken: string;
}

export default ({ idToken }: params) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/hospital/hospitaltype`;

  return axios
    .get(url, {
      headers: { authorization: `Bearer ${idToken}` },
    })
    .then((res) => {
      dispatch = {
        hospitalTypeList: res.data,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      console.log("fetch hospital type list axios error");

      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
