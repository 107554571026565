import { Table, TableBody, TableHead, Tooltip } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import React from "react";
import { TableCell, TableRow } from "./styles";
import { EditTwoTone } from "@mui/icons-material";
import { IEditItemList, IInvoiceItem } from "./type";
import { IBillingItem } from "@/types/BillItem";

const InvoiceItem = ({
  items,
  nonCalculateCapTotal,
  bill,
  providerEditBillItem,
  payerEditBillItem,
  onEditBillItem,
  editedBillItemList,
}: IInvoiceItem) => {
  const numberToLocalString = (number: number) =>
    number?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const duplicateBillingItem = (row: IBillingItem, items: IBillingItem[]) =>
    items.some((item) => item?.icode === row?.icode && item?._id !== row?._id);

  const renderLastedFinalPrice = ({
    editedBillItem,
    row,
  }: {
    editedBillItem: IEditItemList | undefined;
    row: IBillingItem;
  }) => {
    let element: JSX.Element | string = <div />;

    if (editedBillItem) {
      element = (
        <div className="flex justify-end">
          <span className="line-through mr-1 text-red-600 text-xs inline-block align-baseline">
            {numberToLocalString(row?.latestFinalPrice)}
          </span>
          {numberToLocalString(+editedBillItem.latestFinalPrice)}

          {editedBillItem.message && (
            <Tooltip title={editedBillItem.message}>
              <ChatIcon className="ml-1 text-sm text-yellow-400" />
            </Tooltip>
          )}
        </div>
      );
    } else if (row.history.length > 0) {
      const lastedHistoryPrice = row.history[row.history.length - 2]?.price;
      const lastedHistoryMessage = row.history[row.history.length - 1].message;

      element = (
        <div className="flex justify-end">
          <span className="line-through mr-1 text-red-600 text-xs inline-block align-baseline">
            {numberToLocalString(lastedHistoryPrice)}
          </span>
          {numberToLocalString(row.latestFinalPrice)}

          {lastedHistoryMessage && (
            <Tooltip title={lastedHistoryMessage}>
              <ChatIcon className="ml-1 text-sm text-yellow-400" />
            </Tooltip>
          )}
        </div>
      );
    } else {
      element = numberToLocalString(row.latestFinalPrice);
    }

    return element;
  };
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">#</TableCell>
            <TableCell align="center">Icode</TableCell>
            <TableCell align="left">ชื่อรายการ</TableCell>
            <TableCell align="right">ยอดเงินตามจริง (฿)</TableCell>
            <TableCell align="right">ยอดที่คำนวณ (฿)</TableCell>
            <TableCell align="right">ยอดที่เรียกเก็บ (฿)</TableCell>
            <TableCell align="right">แก้ไขรายการ</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.length > 0 ? (
            <>
              {items?.map((row, index, arrayItem) => {
                const editedBillItem: IEditItemList | undefined =
                  editedBillItemList.find(
                    (item) => item.billItemId === row._id
                  );

                return (
                  <TableRow
                    key={row?._id || index}
                    style={{
                      backgroundColor: duplicateBillingItem(row, arrayItem)
                        ? "#F4E185"
                        : undefined,
                    }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row?.icode}</TableCell>
                    <TableCell align="left">
                      {row?.drugNondugFullName}
                    </TableCell>
                    <TableCell align="right">
                      {numberToLocalString(row?.actualPrice)}
                    </TableCell>
                    <TableCell align="right">
                      {numberToLocalString(row?.calculatedPrice)}
                    </TableCell>
                    <TableCell align="right">
                      {renderLastedFinalPrice({ editedBillItem, row })}
                    </TableCell>

                    {providerEditBillItem || payerEditBillItem ? (
                      <TableCell
                        align="right"
                        onClick={() =>
                          onEditBillItem({
                            _id: row?._id,
                            icode: row?.icode,
                            drugNondugFullName: row?.drugNondugFullName,
                            actualPrice: row?.actualPrice,
                            calculatedPrice: row?.calculatedPrice,
                            latestFinalPrice: row?.latestFinalPrice,
                          })
                        }
                      >
                        <EditTwoTone
                          color="primary"
                          className="cursor-pointer"
                        />
                      </TableCell>
                    ) : (
                      <TableCell> </TableCell>
                    )}
                  </TableRow>
                );
              })}

              <TableRow>
                <TableCell align="right"> </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">รวม</TableCell>
                <TableCell align="right">
                  {numberToLocalString(nonCalculateCapTotal?.actualTotal)}
                </TableCell>
                <TableCell align="right">
                  {numberToLocalString(nonCalculateCapTotal?.calculatedTotal)}
                </TableCell>
                <TableCell align="right">
                  {editedBillItemList.length > 0 ? (
                    <p className="line-through inline-block align-baseline">
                      {numberToLocalString(nonCalculateCapTotal?.finalTotal)}
                    </p>
                  ) : (
                    numberToLocalString(nonCalculateCapTotal?.finalTotal)
                  )}
                </TableCell>
                <TableCell align="right"> </TableCell>
              </TableRow>

              <TableRow>
                <TableCell align="right"> </TableCell>
                <TableCell align="right"> </TableCell>
                <TableCell align="right">ยอดรวมสุทธิ</TableCell>
                <TableCell align="right">
                  {numberToLocalString(bill?.actualTotal)}
                </TableCell>
                <TableCell align="right">
                  {numberToLocalString(bill?.calculatedTotal)}
                </TableCell>
                <TableCell align="right">
                  {editedBillItemList.length > 0 ? (
                    <p className="line-through inline-block align-baseline">
                      {numberToLocalString(bill?.finalTotal)}
                    </p>
                  ) : (
                    numberToLocalString(bill?.finalTotal)
                  )}
                </TableCell>
                <TableCell align="right"> </TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center" component="th" scope="row">
                ไม่พบข้อมูล
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default InvoiceItem;
