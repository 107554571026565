import {
  INVOICES_DETAIL_FULFILLED,
  INVOICES_LIST_FULFILLED,
  INVOICES_CONFIRM_FULFILLED,
  INVOICES_REJECT_FULFILLED,
  INVOICES_ACCEPT_FULFILLED,
  INVOICES_CANCEL_FULFILLED,
} from "../constants/invoices";

const initialState = {
  docs: [],
  totalDocs: 0,
  limit: 0,
  fetchStatus: 0,
  message: "",
};

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICES_LIST_FULFILLED:
      return action.payload;
    case INVOICES_DETAIL_FULFILLED:
      return action.payload;
    case INVOICES_CONFIRM_FULFILLED:
      return action.payload;
    case INVOICES_REJECT_FULFILLED:
      return action.payload;
    case INVOICES_ACCEPT_FULFILLED:
      return action.payload;
    case INVOICES_CANCEL_FULFILLED:
      return action.payload;
    default:
      return state;
  }
};
export default invoicesReducer;
