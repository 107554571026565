import styled from "styled-components";
import { TextField } from "@mui/material";

const UserManagement = "";

const SearchInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: 40,
    width: 272,
    borderRadius: "24px 0px 0px 24px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000CC",
    backgroundColor: "#F3F4F6",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E7E9E0",
  },
});
const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .button {
    flex: 1;
    margin: 0 5px;
    max-width: 100px;
  }
  .table-title {
    text-align: left;
  }
  .MuiTableRow-root:nth-child(even) {
    background-color: #f9f9f9;
  }
  .MuiPaper-root {
    width: 100%;
    max-width: 1200px;
  }
  .MuiTableCell-root {
    padding: 4px 50px;
  }
  .TabsCustom {
    .MuiTab-root {
      text-transform: none;
      font-weight: 700;
      font-size: 14px;
      font-family: "Helvetica Neue";
    }
    .Mui-selected {
      background-color: #ffff;
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiButtonBase-root {
      border-radius: 10px 10px 0px 0px;
    }
  }
`;

export { UserManagement, Container, SearchInput };
