export function ThaiDate(d) {
  if (d !== "" && d !== undefined && d !== null && d !== "undefined") {
    const tnow = new Date(d);

    if (tnow.toString() !== "Invalid Date") {
      const thmonth = [
        "ม.ค",
        "ก.พ",
        "มี.ค",
        "เม.ย",
        "พ.ค",
        "มิ.ย",
        "ก.ค",
        "ส.ค",
        "ก.ย",
        "ต.ค",
        "พ.ย",
        "ธ.ค",
      ];

      return `${tnow.getDate()} ${thmonth[tnow.getMonth()]} ${
        0 + tnow.getFullYear() + 543
      }`;
    }
    return "";
  }
  return "";
}

export function ThaiDateTime(d) {
  if (d !== "" && d !== undefined && d !== null && d !== "undefined") {
    const tnow = new Date(d.replace(" ", "T"));

    if (tnow.toString() !== "Invalid Date") {
      const thmonth = [
        "ม.ค",
        "ก.พ",
        "มี.ค",
        "เม.ย",
        "พ.ค",
        "มิ.ย",
        "ก.ค",
        "ส.ค",
        "ก.ย",
        "ต.ค",
        "พ.ย",
        "ธ.ค",
      ];

      let Hours = "";
      let Minutes = "";
      let Seconds = "";
      if (tnow.getHours().toString().length === 1) {
        Hours = `0${tnow.getHours().toString()}`;
      } else {
        Hours = tnow.getHours().toString();
      }

      if (tnow.getMinutes().toString().length === 1) {
        Minutes = `0${tnow.getMinutes().toString()}`;
      } else {
        Minutes = tnow.getMinutes().toString();
      }

      if (tnow.getSeconds().toString().length === 1) {
        Seconds = `0${tnow.getSeconds().toString()}`;
      } else {
        Seconds = tnow.getSeconds().toString();
      }

      return `${tnow.getDate()} ${thmonth[tnow.getMonth()]} ${
        0 + tnow.getFullYear() + 543
      } ${Hours}:${Minutes}:${Seconds}`;
    }
    return "ไม่ระบุ";
  }
  return "ไม่ระบุ";
}

export function ThaiDateTimeNonSec(d) {
  if (d !== "" && d !== undefined && d !== null && d !== "undefined") {
    const tnow = new Date(d.replace(" ", "T"));
    if (tnow.toString() !== "Invalid Date") {
      // let thday = new Array('อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์')
      const thmonth = [
        "ม.ค",
        "ก.พ",
        "มี.ค",
        "เม.ย",
        "พ.ค",
        "มิ.ย",
        "ก.ค",
        "ส.ค",
        "ก.ย",
        "ต.ค",
        "พ.ย",
        "ธ.ค",
      ];

      let Hours = "";
      let Minutes = "";
      if (tnow.getHours().toString().length === 1) {
        Hours = `0${tnow.getHours().toString()}`;
      } else {
        Hours = tnow.getHours().toString();
      }

      if (tnow.getMinutes().toString().length === 1) {
        Minutes = `0${tnow.getMinutes().toString()}`;
      } else {
        Minutes = tnow.getMinutes().toString();
      }

      return `${tnow.getDate()} ${thmonth[tnow.getMonth()]} ${
        0 + tnow.getFullYear() + 543
      } ${Hours}:${Minutes}`;
    }
    return "";
  }
  return "";
}

export function DateNow() {
  const dnow = new Date();

  let dateNow = "";
  let Days = "";
  let Months = "";

  if (dnow.getDate().toString().length === 1) {
    Days = `0${dnow.getDate().toString()}`;
  } else {
    Days = dnow.getDate().toString();
  }

  if ((dnow.getMonth() + 1).toString().length === 1) {
    Months = `0${(dnow.getMonth() + 1).toString()}`;
  } else {
    Months = (dnow.getMonth() + 1).toString();
  }

  dateNow = `${dnow.getFullYear()}-${Months}-${Days}`;

  return dateNow;
}

export function TimeNow() {
  const tnow = new Date();
  let Hours = "";
  let Minutes = "";
  let Seconds = "";
  if (tnow.getHours().toString().length === 1) {
    Hours = `0${tnow.getHours().toString()}`;
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = `0${tnow.getMinutes().toString()}`;
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = `0${tnow.getSeconds().toString()}`;
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return `${Hours}:${Minutes}:${Seconds}`;
}

export function DateTimeNow() {
  const tnow = new Date();

  const month = tnow.getMonth() + 1;

  let monthst = "";

  let datest = "";

  if (month.toString().length === 1) {
    monthst = `0${month.toString()}`;
  } else {
    monthst = month.toString();
  }

  if (tnow.getDate().toString().length === 1) {
    datest = `0${tnow.getDate().toString()}`;
  } else {
    datest = tnow.getDate().toString();
  }

  const dateNow = `${tnow.getFullYear()}-${monthst}-${datest}`;
  let Hours = "";
  let Minutes = "";
  let Seconds = "";

  if (tnow.getHours().toString().length === 1) {
    Hours = `0${tnow.getHours().toString()}`;
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = `0${tnow.getMinutes().toString()}`;
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = `0${tnow.getSeconds().toString()}`;
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return `${dateNow} ${Hours}:${Minutes}:${Seconds}`;
}

export function StrToDate(d) {
  if (d !== "" && d !== undefined && d !== null && d !== "undefined") {
    const tnow = new Date(d.replace(" ", "T"));
    return tnow;
  }
  return "";
}

export function DateTimeToStrDate(d) {
  const tnow = new Date(d);
  let dateNow = "";
  let Days = "";
  let Months = "";
  if (tnow.getDate().toString().length === 1) {
    Days = `0${tnow.getDate().toString()}`;
  } else {
    Days = tnow.getDate().toString();
  }

  if ((tnow.getMonth() + 1).toString().length === 1) {
    Months = `0${(tnow.getMonth() + 1).toString()}`;
  } else {
    Months = (tnow.getMonth() + 1).toString();
  }

  dateNow = `${tnow.getFullYear()}-${Months}-${Days}`;

  return dateNow;
}

export function DateTimeToStrDatetime(d) {
  const tnow = new Date(d);
  let dateNow = "";
  let Days = "";
  let Months = "";
  if (tnow.getDate().toString().length === 1) {
    Days = `0${tnow.getDate().toString()}`;
  } else {
    Days = tnow.getDate().toString();
  }

  if ((tnow.getMonth() + 1).toString().length === 1) {
    Months = `0${(tnow.getMonth() + 1).toString()}`;
  } else {
    Months = (tnow.getMonth() + 1).toString();
  }

  dateNow = `${tnow.getFullYear()}-${Months}-${Days}`;

  let Hours = "";
  let Minutes = "";
  let Seconds = "";
  if (tnow.getHours().toString().length === 1) {
    Hours = `0${tnow.getHours().toString()}`;
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = `0${tnow.getMinutes().toString()}`;
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = `0${tnow.getSeconds().toString()}`;
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return `${dateNow} ${Hours}:${Minutes}:${Seconds}`;
}

export function DateTimeToStrTime(d) {
  const tnow = new Date(d);
  let Hours = "";
  let Minutes = "";
  let Seconds = "";
  if (tnow.getHours().toString().length === 1) {
    Hours = `0${tnow.getHours().toString()}`;
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = `0${tnow.getMinutes().toString()}`;
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = `0${tnow.getSeconds().toString()}`;
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return `${Hours}:${Minutes}:${Seconds}`;
}

export function DateTimeToIntDateTime(d) {
  const tnow = new Date(d);
  let dateNow = "";
  let Days = "";
  let Months = "";
  let Hours = "";
  let Minutes = "";
  let Seconds = "";

  if (tnow.getDate().toString().length === 1) {
    Days = `0${tnow.getDate().toString()}`;
  } else {
    Days = tnow.getDate().toString();
  }

  if ((tnow.getMonth() + 1).toString().length === 1) {
    Months = `0${(tnow.getMonth() + 1).toString()}`;
  } else {
    Months = (tnow.getMonth() + 1).toString();
  }

  dateNow = `${tnow.getFullYear()}${Months}${Days}`;

  if (tnow.getHours().toString().length === 1) {
    Hours = `0${tnow.getHours().toString()}`;
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = `0${tnow.getMinutes().toString()}`;
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  if (tnow.getSeconds().toString().length === 1) {
    Seconds = `0${tnow.getSeconds().toString()}`;
  } else {
    Seconds = tnow.getSeconds().toString();
  }

  return `${dateNow}${Hours}${Minutes}${Seconds}`;
}

export function DateTimeToStrTimeNoneSec(d) {
  const tnow = new Date(d);
  let Hours = "";
  let Minutes = "";
  if (tnow.getHours().toString().length === 1) {
    Hours = `0${tnow.getHours().toString()}`;
  } else {
    Hours = tnow.getHours().toString();
  }

  if (tnow.getMinutes().toString().length === 1) {
    Minutes = `0${tnow.getMinutes().toString()}`;
  } else {
    Minutes = tnow.getMinutes().toString();
  }

  return `${Hours}:${Minutes}`;
}

export function DateTimeId(d) {
  if (d !== "" && d !== undefined && d !== null && d !== "undefined") {
    const tnow = new Date(d.replace(" ", "T"));

    const Years = String(tnow.getFullYear());
    let Months = String(tnow.getMonth() + 1);
    let Dates = String(tnow.getDate());

    if (Months.length === 1) {
      Months = `0${Months}`;
    }
    if (Dates.length === 1) {
      Dates = `0${Dates}`;
    }

    const dateNow = Years + Months + Dates;

    let Hours = "";
    let Minutes = "";
    let Seconds = "";
    if (tnow.getHours().toString().length === 1) {
      Hours = `0${tnow.getHours().toString()}`;
    } else {
      Hours = tnow.getHours().toString();
    }

    if (tnow.getMinutes().toString().length === 1) {
      Minutes = `0${tnow.getMinutes().toString()}`;
    } else {
      Minutes = tnow.getMinutes().toString();
    }

    if (tnow.getSeconds().toString().length === 1) {
      Seconds = `0${tnow.getSeconds().toString()}`;
    } else {
      Seconds = tnow.getSeconds().toString();
    }

    return `${dateNow}${Hours}${Minutes}${Seconds}`;
  }
  return "";
}

export function ThiaDateToDate(d) {
  if (d !== null && d !== "") {
    const Yt = d.substr(0, 4) - 543;
    const Mt = d.substr(4, 2);
    const Dt = d.substr(6, 2);
    return `${Yt}-${Mt}-${Dt}`;
  }
  return "";
}

export function StrToDateTime(d) {
  if (d !== null && d !== "") {
    const tnow = new Date(d.replace(" ", "T"));
    return tnow;
  }
  return new Date();
}

export function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

export function delDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() - days);
  return copy;
}

export function diffMinutes(dt22, dt11) {
  if (
    dt11 !== "" &&
    dt11 !== undefined &&
    dt11 !== null &&
    dt11 !== "undefined" &&
    dt22 !== "" &&
    dt22 !== undefined &&
    dt22 !== null &&
    dt22 !== "undefined"
  ) {
    const dt1 = new Date(dt11.replace(" ", "T"));
    const dt2 = new Date(dt22.replace(" ", "T"));

    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  return 999999;
}

export const dateToLocalDateString = (date, showDay = true) => {
  if (!date || new Date(date).toString() === "Invalid Date") return "";

  const options = {
    year: "numeric",
    month: "long",
  };

  if (showDay) {
    options.day = "numeric";
  }

  return new Date(date).toLocaleDateString("th-TH", options);
};
