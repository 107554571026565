import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

const EditHospital = ({ idToken, body }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/hospital`;

  return axios
    .put(url, body, { headers: { Authorization: `Bearer ${idToken}` } })
    .then(() => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: false,
          msg: "",
          type: "success",
        },
      };

      return dispatch;
    })
    .catch((err) => {
      dispatch = {
        FetchingStatus: false,
        alert: {
          show: true,
          msg: err.response.data.msg,
          type: "error",
        },
      };

      return dispatch;
    });
};

export default EditHospital;
