import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import TDepartment from "../../types/department";

const updateDepartment = async (
  token: string,
  id: string,
  data: TDepartment
) => {
  const url = getApiUrl();
  await axios.put(`${url}/v1/department/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export default updateDepartment;
