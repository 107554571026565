import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ListItem,
  // InputBase,
  // Box,
  // Hidden,
} from "@mui/material";
import {
  Menu as MenuIcon,
  // Search,
  // FiberManualRecord,
} from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";
import { IAppbar } from "./type";
import BarApp from "../../NavigationBar/BarApp";

const index = ({ toggleDrawer, fullName, loginData }: IAppbar) => (
  <AppBar
    sx={{
      background: "rgba(255, 255, 255, 0.8)",
      boxShadow: "inset 0px -1px 0px #D4D9DF",
    }}
  >
    <Toolbar className="app-tool-bar">
      <IconButton onClick={toggleDrawer("left", true)}>
        <MenuIcon className="text-2xl" color="inherit" />
      </IconButton>

      <Typography
        variant="h6"
        className="cursor-pointer font-family-custom"
        noWrap
      >
        <ListItem
          component={NavLink}
          to="/"
          exact
          className="pl-1 !hidden
          md:!block"
          activeClassName="ListItem-active"
          onClick={toggleDrawer("left", false)}
        >
          <img
            alt="logo-ever"
            src="/ever_logo.png"
            width="100px"
            height="33.05px"
          />
        </ListItem>
      </Typography>
      {/* <div className="bg-gray-300 rounded-lg ml-auto mr-auto max-w-lg w-full">
        <IconButton
          aria-label="search"
          className="!hidden
          xs:!inline"
        >
          <Search />
        </IconButton>
        <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
      </div> */}
      {/* <Hidden mdDown>
        <Box
          component="div"
          className="rounded-3xl border-gray-300 border-2 h-9 w-28 text-center"
        >
          <div className="my-1">
            <FiberManualRecord fontSize="small" className="text-green-600" />
            <span className="text-black mx-1 ">Active</span>
          </div>
        </Box>
      </Hidden> */}

      <div className="ml-auto text-right">
        <div className="text-right">
          <BarApp fullName={fullName} loginData={loginData} />
        </div>
      </div>
    </Toolbar>
  </AppBar>
);
export default index;
