import React from "react";
import VisitReferralItem from "./VisitReferralItem";
import { IReferral } from "@/types/refers";
import { Box } from "@mui/material";

interface IReferralsProps {
  referrals: IReferral[];
  onRefresh(): void;
}
function VisitReferrals({ referrals, onRefresh }: IReferralsProps) {
  return (
    <Box>
      {referrals?.map((referral) => (
        <VisitReferralItem
          key={referral._id}
          referral={referral}
          onRefresh={onRefresh}
        />
      ))}
    </Box>
  );
}

export default VisitReferrals;
