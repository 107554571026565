import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// mui
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MUIDataTable from "mui-datatables";

// style
import { Container } from "./HospitalManagement.style";

// apis
import DeleteHospital from "../../../api/HospitalManagement/DeleteHospital";

// actions
import fetchHospitalListForUserManagement from "../../../actions/UserManagement/fetchHospitalListForUserManagement.action";

export default function HospitalManagement() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(20);

  const { appData } = useSelector((state) => state);
  const { idToken, hospitalList, loginData, permission } = appData;

  const handleFetchHospitalList = ({
    page,
    limit,
    sortColumn,
    sortType,
    filterText,
    filterList,
  }) => {
    dispatch(
      fetchHospitalListForUserManagement({
        idToken,
        page,
        limit,
        sortColumn,
        sortType,
        filterText,
        filterList,
      })
    );
  };

  const handleDelete = async () => {
    setLoading(true);
    await DeleteHospital({ idToken, hospCode: modal.hospCode });
    handleFetchHospitalList();
    setLoading(false);
    setModal(false);
  };

  useEffect(() => {
    handleFetchHospitalList();
  }, []);

  const columns = [
    {
      name: "hospCode",
      label: "รหัส รพ.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "hospName",
      label: "ชื่อ รพ.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "",
      options: {
        filter: false,
        customBodyRender: (_value, tableMeta) => (
          <div className="button-wrapper">
            <Tooltip title="Edit This Hospital">
              <IconButton
                onClick={() =>
                  history.push(
                    `/hospital-management/add-edit-hospital/${tableMeta?.rowData[0]}`,
                    {
                      hospital: hospitalList?.docs?.find(
                        (item) => item.hospCode === tableMeta?.rowData[0]
                      ),
                    }
                  )
                }
                size="large"
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete This Hospital">
              <IconButton
                onClick={() => setModal({ hospCode: tableMeta?.rowData[0] })}
                size="large"
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "scroll",
    serverSide: true,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: [20, 50, 100],
    count: hospitalList?.totalDocs,
    selectableRows: false,
    download: false,
    print: false,
    viewColumns: false,
    customToolbar: () => (
      <Tooltip title="Create New Hospital">
        <IconButton
          onClick={() => history.push("/hospital-management/add-edit-hospital")}
          size="large"
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    ),
    onTableChange: (action, tableState) => {
      const query = {
        idToken,
        page: tableState.page + 1,
        limit: tableState?.rowsPerPage,
        sortColumn: tableState?.sortOrder?.name,
        sortType: tableState?.sortOrder?.direction,
        filterText: tableState?.searchText,
        filterList: tableState?.filterList,
      };

      switch (action) {
        case "changePage":
          handleFetchHospitalList(query);
          break;
        case "sort":
          handleFetchHospitalList(query);
          break;
        case "search":
          handleFetchHospitalList(query);
          break;
        case "filterChange":
        case "resetFilters":
          handleFetchHospitalList(query);
          break;
        case "changeRowsPerPage":
          setRowPerPage(tableState?.rowsPerPage);
          handleFetchHospitalList(query);
          break;
        default:
          break;
      }
    },
  };

  return (
    <Container>
      {permission?.hospitalManagement ? (
        <MUIDataTable
          title={
            <div className="table-title">
              <ApartmentIcon /> จัดการ รพ.
            </div>
          }
          data={hospitalList?.docs}
          columns={columns}
          options={options}
        />
      ) : (
        loginData?.role && history.push("/")
      )}

      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle>ลบ รพ. {modal.hospCode}</DialogTitle>
        <DialogContent>
          <DialogContentText>ยืนยันที่จะลบ ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => setModal(false)}
            color="primary"
          >
            ยกเลิก
          </Button>
          <Button disabled={loading} onClick={handleDelete} color="primary">
            ยืนยัน
          </Button>
        </DialogActions>
        {loading && <LinearProgress />}
      </Dialog>
    </Container>
  );
}
