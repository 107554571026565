import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReferralType } from "../../types/refers";
import { RootState } from "../../reducers";
import CountBar from "./CountBar";
import ReportNavbar from "./ReportNavbar";
import {
  IReportRequest,
  IReportResponse,
  ISelectedHospital,
} from "@/types/report";
import Box from "@mui/material/Box";
import ReportEmergency from "./ReportEmergency";
import ReportFemale from "./ReportFemale";
import ReportMale from "./ReportMale";
import ReportProvince from "./ReportProvince";
import ReportDecease from "./ReportDecease";
import ContainerReportQtyAndPercent from "./ContainerReportQtyAndPercent";
import fetchReport from "../../actions/Report/fetchReport.action";
import ContainerReportDistrict from "./ContainerReportDistrict";
import ContainerReportSubDistrict from "./ContainerReportSubDistrict";
import { Typography } from "@mui/material";
import ContainerReportDiag4Branch from "./ContainerReportDiag4Branch";

export default function ReportMain() {
  const dispatch = useDispatch();

  const { appData } = useSelector((state: RootState) => state);
  const idToken: string = appData.idToken;

  const [encounterType, setEncounterType] = useState<ReferralType>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [report, setReport] = useState<IReportResponse | null>(null);

  const handleFetchReport = (
    selectedHospital: ISelectedHospital,
    encounterType: ReferralType,
    startDate: Date,
    endDate: Date
  ) => {
    setReport(null);
    setEncounterType(encounterType);
    setStartDate(startDate);
    setEndDate(endDate);

    const body: IReportRequest = {
      startDate,
      endDate,
      hospCode: selectedHospital.hospCode,
    };

    dispatch(fetchReport({ idToken, body }));
  };

  useEffect(() => {
    if (!appData.report) {
      return;
    }

    setReport(appData.report);
  }, [appData.report]);

  return (
    <Box>
      <ReportNavbar onProcess={handleFetchReport} />

      {report &&
      encounterType &&
      Object.values(report).some((value) => Object.keys(value).length > 0) ? (
        <Box className="bg-gray-100 p-4">
          <Box className="bg-white p-4 rounded-xl">
            <CountBar referType={encounterType} report={report} />

            <ContainerReportQtyAndPercent
              report={report}
              referType={encounterType}
              startDate={startDate}
              endDate={endDate}
            />

            <ContainerReportDiag4Branch
              report={report}
              referType={encounterType}
              startDate={startDate}
              endDate={endDate}
            />

            <ReportEmergency
              report={report}
              referType={encounterType}
              startDate={startDate}
              endDate={endDate}
            />

            <Box className="grid grid-cols-2 mt-5 gap-4">
              <ReportDecease
                report={report}
                referType={encounterType}
                startDate={startDate}
                endDate={endDate}
              />

              <ReportProvince
                report={report}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>

            <Box className="grid grid-cols-2 mt-5 gap-4">
              <ContainerReportDistrict
                report={report}
                startDate={startDate}
                endDate={endDate}
              />

              <ContainerReportSubDistrict
                report={report}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>

            <Box className="grid grid-cols-2 mt-5 gap-4">
              <ReportMale
                report={report}
                startDate={startDate}
                endDate={endDate}
              />

              <ReportFemale
                report={report}
                startDate={startDate}
                endDate={endDate}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} padding={10}>
          <Typography variant="h5">ไม่พบ Report</Typography>
        </Box>
      )}
    </Box>
  );
}
