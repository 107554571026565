import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import EventNoteIcon from "@mui/icons-material/EventNote";
import {
  Table,
  TableBody,
  TableHead,
  TablePagination,
  Paper,
} from "@mui/material";
import { Container, TableRow, TableCell, Status } from "./styles";
// action
import { actionInvoicesDetail } from "../../actions/InvoiceAction";
import { getBillingStatusConfig } from "../../functions/FuncPerjer";
import { IInvoiceDetail, UseParamsType } from "./type";

function NoneInvoiceDetail() {
  const { categoryId, provider, payer } = useParams<UseParamsType>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const { appData, invoices }: any = useSelector((state) => state);
  const { idToken, hospCode, loginData } = appData;
  const fetchData = () => {
    const params = {
      idToken,
      provider,
      payer,
      categoryId,
      page: page + 1,
      limit: rowsPerPage,
    };
    dispatch(actionInvoicesDetail(params));
  };
  useEffect(() => {
    fetchData();
  }, [idToken, hospCode, loginData, page, rowsPerPage]);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Container className="!mt-20">
      <div className="header">
        <EventNoteIcon className="header-icon" />
        <h5 className="title">รายละเอียดรายการที่ยังไม่ออกอินวอยซ์</h5>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">วันที่</TableCell>
            <TableCell align="left">CID</TableCell>
            <TableCell align="left">ชื่อ</TableCell>
            <TableCell align="right">ยอดเงินตามจริง (฿)</TableCell>
            <TableCell align="right">ยอดที่คำนวณ (฿)</TableCell>
            <TableCell align="right">ยอดที่เรียกเก็บ (฿)</TableCell>
            <TableCell align="right">สถานะ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices && invoices?.docs?.length > 0 ? (
            invoices?.docs?.map((row: IInvoiceDetail) => (
              <TableRow
                key={row?.id || row.categoryId}
                onClick={() =>
                  history.push(`/billing-patient/${row?.visitId}/${categoryId}`)
                }
              >
                <TableCell align="left" component="th" scope="row">
                  {new Date(row?.visitDateTime)?.toLocaleDateString("th-TH", {
                    month: "short",
                    year: "numeric",
                    day: "numeric",
                  }) || ""}
                </TableCell>
                <TableCell align="left">
                  {Array.isArray(row?.patient) && row?.patient[0]?.cid}
                </TableCell>
                <TableCell align="left">
                  {Array.isArray(row?.patient) &&
                    `${row?.patient[0]?.data?.fullname?.title} ${row?.patient[0]?.data?.fullname?.firstName} ${row?.patient[0]?.data?.fullname?.lastName}`}
                </TableCell>
                <TableCell align="right">
                  {row?.actualTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  {row?.calculatedTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  {row?.finalTotal?.toLocaleString("en-EN")}
                </TableCell>
                <TableCell align="right">
                  <Status color={getBillingStatusConfig(row.status)?.color}>
                    {getBillingStatusConfig(row?.status)?.text}
                  </Status>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center" component="th" scope="row">
                ไม่พบข้อมูล
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={invoices?.totalDocs || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default NoneInvoiceDetail;
