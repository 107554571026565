import { Box, Typography } from "@mui/material";
import React from "react";
import { IVisitVital } from "@/types/visit";

function Screen({
  temperature,
  pulse,
  rr,
  bps,
  bpd,
  fbs,
  waist,
  height,
  bw,
  cc,
  hpi,
  pmh,
  fh,
  sh,
  ros,
  pi,
}: IVisitVital) {
  const splitString = (word: string) =>
    word.split("\r").map((item, index) => <div key={index}>{item}</div>);
  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box className="border-b-2 border-gray-100 h-10 px-6 py-1">
        <Typography variant="h6" color="#58595B">
          Screen
        </Typography>
      </Box>
      <Box>
        <Box className="grid grid-rows-2 pl-7 mt-2">
          <Box className="grid grid-cols-4">
            <Box>
              <span className="text-sm text-gray-400">TEMP: </span>
              <span className="text-gray-800">{`${temperature} °C`}</span>
            </Box>
            <Box>
              <span className="text-sm text-gray-400">PULSE: </span>
              <span className="text-gray-800">{`${pulse} /min.`}</span>
            </Box>
            <Box>
              <span className="text-sm text-gray-400">RR: </span>
              <span className="text-gray-800">{`${rr} /min.`}</span>
            </Box>
            <Box>
              <span className="text-sm text-gray-400">BP: </span>
              <span className="text-gray-800">{`${bps} / ${bpd} mmHg`}</span>
            </Box>
          </Box>
          <Box className="grid grid-cols-4">
            <Box>
              <span className="text-sm text-gray-400">FBS: </span>
              <span className="text-gray-800">{fbs}</span>
            </Box>
            <Box>
              <span className="text-sm text-gray-400">WAIST: </span>
              <span className="text-gray-800">{waist}</span>
            </Box>
            <Box>
              <span className="text-sm text-gray-400">HEIGHT: </span>
              <span className="text-gray-800">{height}</span>
            </Box>
            <Box>
              <span className="text-sm text-gray-400">BW: </span>
              <span className="text-gray-800">{bw}</span>
            </Box>
          </Box>
        </Box>
        {cc && (
          <Box className="pl-7 my-2">
            <p className="text-gray-400">CC: </p>
            <span className="text-gray-800">{splitString(cc)}</span>
          </Box>
        )}
        {hpi && (
          <Box className="pl-7 my-2">
            <p className="text-gray-400">HPI:</p>
            <span className="text-gray-800">{splitString(hpi)}</span>
          </Box>
        )}
        {pmh && (
          <Box className="pl-7 my-2">
            <p className="text-gray-400">PMH:</p>
            <span className="text-gray-800">{splitString(pmh)}</span>
          </Box>
        )}
        {fh && (
          <Box className="pl-7 my-2">
            <p className="text-gray-400">FH:</p>
            <span className="text-gray-800">{splitString(fh)}</span>
          </Box>
        )}
        {sh && (
          <Box className="pl-7 my-2">
            <p className="text-gray-400">SH:</p>
            <span className="text-gray-800">{splitString(sh)}</span>
          </Box>
        )}
        {ros && (
          <Box className="pl-7 my-2">
            <p className="text-gray-400">ROS:</p>
            <span className="text-gray-800">{splitString(ros)}</span>
          </Box>
        )}
        {pi && (
          <Box className="pl-7 my-2">
            <p className="text-gray-400">PI:</p>
            <span className="text-gray-800">{splitString(pi)}</span>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Screen;
