import { Box, Typography } from "@mui/material";
import React from "react";
import {
  IAppointment,
  IXrays,
  IDrug,
  IDiagnosis,
  ILabResult,
  IAdmission,
  IOperation,
} from "@/types/visit";
import TabPanel from "../../../Utility/TabPanel";
import VisitAdmit from "../../visit/VisitAdmit";
import Appointment from "../Appointment/Appointment";
import Diagnosis from "../Diagnosis/Diagnosis";
import Drugs from "../Drug/Drugs";
import LabResult from "../LabResult/LabResult";
import Xrays from "../Xray/Xrays";
import { ICustomerConfig } from "@/types/customerConfig";
import VisitDetail from "../../visit/VisitDetail";
import Operations from "../Operation/Operations";
import { ThaiDateTime } from "../../../../functions/FuncDateTimes";

interface IIPD {
  value: number;
  an: string;
  appointments: IAppointment[];
  xrays: IXrays[];
  drugs: IDrug[];
  otherItems: IDrug[] | null;
  labResults: ILabResult[];
  diagnoses: IDiagnosis[];
  admission: IAdmission;
  customerConfig: ICustomerConfig;
  hospName: string;
  departmentName: string;
  spcltyName: string;
  vn: string;
  pttype: string;
  pttypeName: string;
  hospmainName: string;
  hospsubName: string;
  doctorName: string;
  hospmain: string;
  hospsub: string;
  hn: { hospCode: string; value: string }[];
  hospCode: string;
  operations: IOperation[];
  regdate?: string;
  regtime?: string;
  dchdate?: string;
  dchtime?: string;
}
function Ipd({
  appointments,
  xrays,
  drugs,
  labResults,
  otherItems,
  diagnoses,
  admission,
  value,
  an,
  customerConfig,
  hospName,
  departmentName,
  spcltyName,
  vn,
  pttype,
  pttypeName,
  hospmainName,
  hospsubName,
  doctorName,
  hospmain,
  hospsub,
  hn,
  hospCode,
  operations,
  regdate,
  regtime,
  dchdate,
  dchtime,
}: IIPD) {
  const regDateTime = ThaiDateTime(`${regdate} ${regtime}`);
  const dchDateTime = ThaiDateTime(`${dchdate} ${dchtime}`);

  return (
    <TabPanel value={value} index={1}>
      <Box
        component="div"
        className="flex flex-col w-full h-auto bg-white rounded-2xl border-solid border-gray border-2 my-4 px-2"
      >
        <Box className="border-b-2 border-gray-200 px-3 py-3">
          <Typography color="#58595B">{`วันที่รับเข้า: ${regDateTime}`}</Typography>
          <Typography color="#58595B">{`วันที่จำหน่าย: ${dchDateTime}`}</Typography>
        </Box>
        <VisitDetail
          hospName={hospName}
          departmentName={departmentName}
          spcltyName={spcltyName}
          vn={vn}
          pttype={pttype}
          pttypeName={pttypeName}
          hospmainName={hospmainName}
          hospsubName={hospsubName}
          doctorName={doctorName}
          hospmain={hospmain}
          hospsub={hospsub}
          hn={hn}
          hospCode={hospCode}
          an={an}
        />
        {operations?.length > 0 && <Operations operations={operations} />}
        {Object.keys(admission).length > 1 && <VisitAdmit {...admission} />}
        {appointments?.length > 0 && (
          <Appointment appointments={appointments} />
        )}
        {diagnoses?.length > 0 && <Diagnosis diagnosis={diagnoses} />}
        {xrays?.length > 0 && <Xrays xrays={xrays} />}
        {drugs?.length > 0 && (
          <Drugs
            drugs={drugs}
            title="Drug Items"
            customerConfig={customerConfig}
          />
        )}
        {otherItems && otherItems?.length > 0 && (
          <Drugs
            drugs={otherItems}
            title="Other Items"
            customerConfig={customerConfig}
          />
        )}
        {labResults?.length > 0 && <LabResult labResults={labResults} />}
      </Box>
    </TabPanel>
  );
}

export default Ipd;
