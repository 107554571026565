import { Theme, TypeText, createTheme } from "@mui/material/styles";

interface CustomPaletteText {
  warning: string;
}

interface CustomTypeText extends Theme {
  text: TypeText & { custom: CustomPaletteText };
}

const theme = createTheme({
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 0.65)",
    },
    custom: {
      warning: "#FF9800",
    } as CustomPaletteText,
  },
} as unknown as CustomTypeText);

export default theme;
