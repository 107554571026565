import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { IConfirmModal } from "../type";

function DialogConfirm(props: IConfirmModal) {
  const { open, onClose, onConfirm } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="responsive-dialog-title">ยืนยันการทำรายการ</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ท่านยืนยันที่จะทำรายการต่อหรือไม่ ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          No
        </Button>
        <Button onClick={onConfirm} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogConfirm;
