import {
  INVOICES_LIST,
  INVOICES_DETAIL,
  INVOICES_CONFIRM,
  INVOICES_ACCEPT,
  INVOICES_REJECT,
  INVOICES_CANCEL,
} from "../constants/invoices";
import {
  fetchsInvoiceList,
  fetchInvoiceDetail,
  acceptInvoice,
  rejectInvoice,
  fetchConfirm,
  createInvoice,
  cancelInvoice,
} from "../api/Invoices/InvoiceApi";

export function actionInvoicesList(params) {
  const payload = fetchsInvoiceList(params);
  return {
    type: INVOICES_LIST,
    payload,
  };
}

export function actionInvoicesDetail(params) {
  return {
    type: INVOICES_DETAIL,
    payload: fetchInvoiceDetail(params),
  };
}

export const actionAcceptInvoice = (body) => ({
  type: INVOICES_ACCEPT,
  payload: acceptInvoice(body),
});
export const actionRejectInvoice = (body) => ({
  type: INVOICES_REJECT,
  payload: rejectInvoice(body),
});

export const actionConfirmData = (query) => ({
  type: INVOICES_CONFIRM,
  payload: fetchConfirm(query),
});

export const actionsCancelInvoice = (body) => ({
  type: INVOICES_CANCEL,
  payload: cancelInvoice(body),
});

export const actionCreateInvoice = (body) => ({
  payload: createInvoice(body),
});
