// constants
import { COMPLETE, REJECTED } from "../constants/referralStatus";
import { REFER_IN, REFER_OUT } from "../constants/referType";

const getReferType = ({ hospCode, fromHospCode }) => {
  if (!hospCode || !fromHospCode) return false;

  if (hospCode === fromHospCode) return REFER_OUT;
  return REFER_IN;
};

const getReferColor = ({ referral }) => {
  let referralClass = "";

  if (referral?.ptDeceasedBoolean) {
    referralClass = "referrals-card-deceased";
  } else if (referral?.status?.toUpperCase() === COMPLETE) {
    referralClass = "referrals-card-complete";
  } else if (referral?.status?.toUpperCase() === REJECTED) {
    referralClass = "referrals-card-rejected";
  }

  return referralClass;
};

export { getReferType, getReferColor };
