import React from "react";
import { Box, Typography } from "@mui/material";
import DrugItem from "./DrugItem";
import { IDrug } from "@/types/visit";
import { ICustomerConfig } from "@/types/customerConfig";
import dayjs from "dayjs";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

interface IPropsDrug {
  drugs: IDrug[];
  title: string;
  customerConfig: ICustomerConfig;
}

function groupDrugsByMonth(drugs: IDrug[]) {
  const groupedDrugs: Record<string, IDrug[]> = {};

  drugs.forEach((drug) => {
    const monthYear = dayjs(drug.rxDateTime).format("YYYY-MM");
    if (!groupedDrugs[monthYear]) {
      groupedDrugs[monthYear] = [];
    }
    groupedDrugs[monthYear].push(drug);
  });

  const sortedGroupedDrugs = Object.keys(groupedDrugs)
    .sort((a, b) => dayjs(b).diff(dayjs(a)))
    .reduce((acc, key) => {
      acc[key] = groupedDrugs[key];
      return acc;
    }, {} as Record<string, IDrug[]>);

  return sortedGroupedDrugs;
}

function Drugs({ drugs, title, customerConfig }: IPropsDrug) {
  const groupedDrugs = groupDrugsByMonth(drugs);

  return (
    <Box
      component="div"
      sx={{ maxHeight: "512px" }}
      className="flex flex-col w-full bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box
        sx={{ borderBottom: "1px solid #D4D9DF", boxSizing: "border-box" }}
        height="40px"
        padding="5px 20px"
      >
        <Typography variant="h6" color="#58595B">
          {title}
        </Typography>
      </Box>
      <div className="px-3 overflow-scroll">
        {Object.keys(groupedDrugs).map((monthYear) => (
          <Box key={monthYear} className="mb-4">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", padding: "12px", color: "#58595B" }}
            >
              {dateToLocalDateString(monthYear, false)}
            </Typography>
            <Box className="grid grid-cols-3 gap-2">
              {groupedDrugs[monthYear].map((drug) => (
                <DrugItem
                  key={drug._id}
                  drugNondugFullName={
                    drug?.drugNondugFullName || drug.drugNondugName
                  }
                  sumPrice={drug?.sumPrice}
                  rxDateTime={drug?.rxDateTime}
                  drugUsage={drug?.drugUsage || drug?.touse}
                  qty={drug?.qty || drug?.toorder}
                  customerConfig={customerConfig}
                />
              ))}
            </Box>
          </Box>
        ))}
      </div>
    </Box>
  );
}

export default Drugs;
