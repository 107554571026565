import { Box, Typography } from "@mui/material";
import React from "react";
import { IDental } from "@/types/visit";
import DentalItem from "./DentalItem";

interface IDentalProps {
  dentals: IDental[];
}
function Dental({ dentals }: IDentalProps) {
  const dentalItem = () =>
    dentals.map((dental, index) => <DentalItem key={index} {...dental} />);
  return (
    <Box
      component="div"
      className="flex flex-col w-full h-auto bg-white rounded-xl border-solid border-gray border-2 my-3"
    >
      <Box
        sx={{ borderBottom: "1px solid #D4D9DF", boxSizing: "border-box" }}
        height="40px"
        padding="5px 20px"
      >
        <Typography variant="h6" color="#58595B">
          Dental
        </Typography>
      </Box>
      <Box>{dentalItem()}</Box>
    </Box>
  );
}

export default Dental;
