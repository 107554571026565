import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export const createInvoice = async (body) => {
  try {
    const response = await axios.post(
      `${getApiUrl()}/v1/invoices/create`,
      body,
      {
        headers: { Authorization: `Bearer ${body.idToken}` },
      }
    );
    return {
      status: 200,
      message: "สร้างอินวอยซ์เรียบร้อย",
      invoiceId: response.data.invoiceResult._id,
    };
  } catch (error) {
    return { status: 400, message: "สร้างอินวอยซ์ล้มเหลว" };
  }
};
export const fetchConfirm = async (query) => {
  const { idToken, payer, date, categoryId } = query;
  try {
    const response = await axios.get(
      `${getApiUrl()}/v1/invoices/confirm?payer=${payer}&categoryId=${categoryId}&date=${date}`,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return response.data.result;
  } catch (error) {
    return [];
  }
};

export const fetchsInvoiceList = async (params) => {
  const { idToken, categoryId, provider, payer, page = 1, limit = 20 } = params;
  try {
    const response = await axios.get(
      `
        ${getApiUrl()}/v1/invoices/list?categoryId=${categoryId}&provider=${provider}&payer=${payer}&page=${page}&limit=${limit}`,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return response.data.result;
  } catch (error) {
    return [];
  }
};

export const fetchInvoiceDetail = async (params) => {
  const {
    idToken,
    invoiceId,
    payer,
    provider,
    categoryId,
    page = 1,
    limit = 20,
  } = params;
  try {
    const url = invoiceId
      ? `${getApiUrl()}/v1/invoices/details?invoiceId=${invoiceId}&payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`
      : `${getApiUrl()}/v1/invoices/details?payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`;
    const result = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return {
      ...result.data.billResult,
      invoiceResult: result.data.invoiceResult,
    };
  } catch (error) {
    return {};
  }
};

export const acceptInvoice = async (body) => {
  const {
    idToken,
    invoiceId,
    payer,
    provider,
    categoryId,
    page = 1,
    limit = 20,
  } = body;
  try {
    await axios.put(`${getApiUrl()}/v1/invoices/accept`, body, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    const url = invoiceId
      ? `${getApiUrl()}/v1/invoices/details?invoiceId=${invoiceId}&payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`
      : `${getApiUrl()}/v1/invoices/details?payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`;
    const result = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return {
      ...result.data.billResult,
      invoiceResult: result.data.invoiceResult,
      fetchStatus: 200,
      message: "Accept Success",
    };
  } catch (error) {
    return {
      fetchStatus: 400,
      message: "Accept Failed",
    };
  }
};

export const rejectInvoice = async (body) => {
  const {
    idToken,
    invoiceId,
    payer,
    provider,
    categoryId,
    page = 1,
    limit = 20,
  } = body;
  try {
    await axios.put(`${getApiUrl()}/v1/invoices/reject`, body, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    const url = invoiceId
      ? `${getApiUrl()}/v1/invoices/details?invoiceId=${invoiceId}&payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`
      : `${getApiUrl()}/v1/invoices/details?payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`;
    const result = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return {
      ...result.data.billResult,
      invoiceResult: result.data.invoiceResult,
      fetchStatus: 200,
      message: "Reject Success",
    };
  } catch (error) {
    return {
      fetchStatus: 400,
      message: "Reject Failed",
    };
  }
};

export const cancelInvoice = async (body) => {
  const {
    idToken,
    invoiceId,
    payer,
    provider,
    categoryId,
    page = 1,
    limit = 20,
  } = body;
  try {
    await axios.put(`${getApiUrl()}/v1/invoices/cancel`, body, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    const url = invoiceId
      ? `${getApiUrl()}/v1/invoices/details?invoiceId=${invoiceId}&payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`
      : `${getApiUrl()}/v1/invoices/details?payer=${payer}&provider=${provider}&categoryId=${categoryId}&page=${page}&limit=${limit}`;
    const result = await axios.get(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return {
      ...result.data.billResult,
      invoiceResult: result.data.invoiceResult,
      fetchStatus: 200,
      message: "Cancel Success",
    };
  } catch (error) {
    return {
      fetchStatus: 400,
      message: "Cancel Failed",
    };
  }
};
