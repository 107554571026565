import React from "react";

// mui
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider } from "@mui/material/styles";

import theme from "./theme";
import Router from "./routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Router />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
