import React, { useEffect, useState } from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { ThaiDate } from "../../functions/FuncDateTimes";
import { IReportResponse, IReportBarSeries } from "@/types/report";
import { calculateReportHeight } from "../../functions/report";
import _ from "lodash";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { emergencyText } from "../../functions/transform";
import { ReferralType } from "../../types/refers";

interface IReportEmergency {
  report: IReportResponse;
  referType: ReferralType;
  startDate: Date;
  endDate: Date;
}

export default function ReportEmergency({
  report,
  referType,
  startDate,
  endDate,
}: IReportEmergency) {
  const [categories, setCategories] = useState<string[]>();
  const [series, setSeries] = useState<IReportBarSeries[]>();

  const options: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    title: {
      text: `ระดับความเร่งด่วน | ${ThaiDate(startDate)} - ${ThaiDate(endDate)}`,
    },
    tooltip: {
      y: {
        formatter(val: any) {
          return `${val} ครั้ง`;
        },
      },
    },
    xaxis: {
      categories,
    },
    legend: {
      position: "top",
    },
  };

  const formatCategories = () => {
    const referIn = referType === ReferralType.REFER_IN;
    const referOut = referType === ReferralType.REFER_OUT;

    if (referIn && !report.emergencyIn) {
      return;
    }
    if (referOut && !report.emergencyOut) {
      return;
    }

    const hostname = window.location.hostname;
    const emergency = referIn ? report.emergencyIn : report.emergencyOut;
    const listOfEmergencyName = Object.keys(emergency);
    const transform = listOfEmergencyName
      .map((type) => emergencyText(type, hostname))
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((item) => item.text);

    setCategories(transform);
  };

  const formatSeries = () => {
    const referIn = referType === ReferralType.REFER_IN;
    const referOut = referType === ReferralType.REFER_OUT;

    if (referIn && !report.emergencyIn) {
      return;
    }
    if (referOut && !report.emergencyOut) {
      return;
    }

    const emergency = referIn ? report.emergencyIn : report.emergencyOut;
    const formattedData = [
      { data: _.map(emergency, (category) => category[0].total) },
    ];

    setSeries(formattedData);
  };

  useEffect(() => {
    formatCategories();
    formatSeries();
  }, []);

  return categories && categories.length > 0 && series && series.length > 0 ? (
    <Box className="border" padding={2} marginTop={2}>
      <ApexCharts
        options={options}
        series={series}
        type="bar"
        height={calculateReportHeight({
          categoriesLength: categories.length,
        })}
      />
    </Box>
  ) : (
    <Box
      className="border"
      padding={2}
      display={"flex"}
      marginTop={2}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="h6">ไม่พบรายงานระดับความเร่งด่วน</Typography>
    </Box>
  );
}
