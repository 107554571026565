import React, { useEffect, useState } from "react";
import fetchDoctors from "../../../api/SettingAppointment/fetchDoctors";
import { IDoctor, TClinic } from "../../../types/clinic";
import updateClinic from "../../../api/SettingAppointment/updateClinic";
import DialogAddDoctor from "./DialogAddDoctor";
import { Button, IconButton, Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
type IDoctorId = {
  token: string;
  clinic: TClinic;
};
function Doctors({ clinic, token }: IDoctorId) {
  const [open, setOpen] = useState<boolean>(false);
  const [doctors, setDoctos] = useState<IDoctor[]>([]);
  const onFetchDoctors = async (clinic: TClinic) => {
    try {
      if (!clinic) return;
      const result = await fetchDoctors({ doctorId: clinic.doctorId, token });
      setDoctos(result);
    } catch (error: unknown) {
      console.log(error);
    }
  };
  useEffect(() => {
    onFetchDoctors(clinic);
  }, [clinic]);
  if (!doctors) {
    return <div>Empty doctor</div>;
  }

  const onHandleDialog = (status: boolean) => setOpen(status);
  const onAddDoctor = async (newDoctors: IDoctor[]) => {
    if (newDoctors.length === 0) return;
    const doctorsMap = doctors.map((doctor) => doctor._id);
    const newDoctorId = newDoctors.map((doc) => doc._id);
    const data: TClinic = {
      ...clinic,
      doctorId: [...doctorsMap, ...newDoctorId],
    };
    try {
      if (!clinic._id) return;
      await updateClinic(token, clinic._id, data);
      await onFetchDoctors(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveDoctor = async (doctorId: string) => {
    try {
      if (!clinic._id) return;
      const data: TClinic = {
        ...clinic,
        doctorId: doctors
          .filter((doctor) => doctor._id !== doctorId)
          .map((d) => d._id),
      };

      await updateClinic(token, clinic._id, data);
      await onFetchDoctors(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className=" overflow-scroll">
      <Button onClick={() => onHandleDialog(true)}>add doctor</Button>
      <div className="grid grid-cols-4">
        {doctors &&
          doctors.map(({ _id, username, fullName }) => (
            <div key={_id} className="border-2 m-2 rounded-md h-36 text-center">
              <div className="flex justify-end">
                <IconButton onClick={() => onRemoveDoctor(_id)}>
                  <Cancel color="error" />
                </IconButton>
              </div>
              <Typography
                variant="h6"
                gutterBottom
              >{`${fullName?.firstName} ${fullName?.lastName}`}</Typography>
              <Typography variant="subtitle1" gutterBottom>
                {username}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {clinic.clinicName}
              </Typography>
            </div>
          ))}
      </div>

      <DialogAddDoctor
        open={open}
        onHandleDialog={onHandleDialog}
        onAddDoctor={onAddDoctor}
        token={token}
        doctorsInClinic={clinic.doctorId}
      />
    </div>
  );
}

export default Doctors;
