import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  Grid,
  Button,
  IconButton,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { ContentModal, ContentAlert } from "../styles";
// api
import { createInvoice } from "../../../api/Invoices/InvoiceApi";
import { IConfirmModal } from "../type";

function ConfirmModal({
  open,
  onCloseModal,
  date,
  hospital,
  category,
  detail,
  token,
  providerHospCode,
}: IConfirmModal) {
  const [status, setStatus] = useState<number | null>(null);
  const [message, setMessage] = useState<string>("");
  const [invoiceId, setInvoiceId] = useState<string>("");
  const history = useHistory();
  const onConfirm = async () => {
    const body = {
      payer: hospital?.hospCode,
      date: new Date(date),
      categoryId: category?._id,
      idToken: token,
    };
    const response = await createInvoice(body);
    setStatus(response.status);
    setMessage(response.message);
    setInvoiceId(response.invoiceId);
  };
  const redirect = () => {
    setTimeout(() => {
      history.push(
        `/billing-charge/to/detail/${providerHospCode}/${hospital?.hospCode}/${invoiceId}/${category?._id}`
      );
    }, 1000);
  };

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Grid container direction="column" alignItems="center">
        <ContentAlert>
          {status === 200 && (
            <Alert
              severity="success"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setStatus(null);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>Success</AlertTitle>
              {message}
              {redirect()}
            </Alert>
          )}
          {status === 400 && (
            <Alert
              severity="error"
              variant="filled"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setStatus(null);
                  }}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
            >
              <AlertTitle>Error</AlertTitle>
              {message}
            </Alert>
          )}
        </ContentAlert>
        <ContentModal>
          <Grid container direction="column" alignItems="flex-start">
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={onCloseModal}
              >
                X
              </Button>
            </Grid>
            <Grid container justifyContent="center">
              <h4 style={{ fontWeight: "bold" }}>ยืนยันการสร้าง invoice</h4>
            </Grid>
            <Grid>
              <h5>{`Month: ${new Date(date)?.toLocaleDateString("th-TH", {
                month: "short",
                year: "numeric",
              })}`}</h5>
              <h5>{`Hospital: ${hospital?.hospName}`}</h5>
              <h5>{`Category: ${category?.name}`}</h5>
            </Grid>
            <Grid>
              <h5>{`จำนวนผู้ป่วย: ${detail?.totalPatients?.toLocaleString(
                "en-EN"
              )}`}</h5>
              <h5>{`จำนวน vist: ${detail?.totalBills?.toLocaleString(
                "en-EN"
              )}`}</h5>
              <h5>{`ยอดเงินตามจริง: ${detail?.actualTotal?.toLocaleString(
                "en-EN"
              )}`}</h5>
              <h5>{`ยอดที่คำนวณ: ${detail?.calculatedTotal?.toLocaleString(
                "en-EN"
              )}`}</h5>
              <h5>{`ยอดที่เรียกเก็บ: ${detail?.finalTotal?.toLocaleString(
                "en-EN"
              )}`}</h5>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Button variant="contained" color="primary" onClick={onConfirm}>
              สร้าง invoice
            </Button>
          </Grid>
        </ContentModal>
      </Grid>
    </Modal>
  );
}

export default ConfirmModal;
