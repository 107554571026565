import { APPDATA } from "../../constants/appData";
import fetchReport from "../../api/Report/fetchReport.service";
import { IReportRequest } from "@/types/report";

export default ({
  idToken,
  body,
}: {
  idToken: string;
  body: IReportRequest;
}) => ({
  type: APPDATA,
  payload: fetchReport({ idToken, body }),
});
