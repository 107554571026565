import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { RootState } from "../../reducers";
import { ISyncUser } from "@/types/syncUser";
import { IHospital } from "@/types/hospital";
import fetchOnlineHospitals from "../../actions/fetchOnlineHospitals.action";
import OnlineHospitalCard from "./OnlineHospitalCard";
import { shortHospName } from "../../functions/FuncPerjer";

interface IOnlineHospital extends ISyncUser {
  hospital: IHospital;
}

export default function OnlineHospital() {
  const dispatch = useDispatch();
  const { appData } = useSelector((state: RootState) => state);

  const onlineGatewayHospital = appData.onlineGatewayHospital as
    | IOnlineHospital[]
    | undefined;

  useEffect(() => {
    dispatch(fetchOnlineHospitals());
  }, []);

  return (
    <div id="online-hospital">
      <AppBar
        id="online-hospital-navbar"
        position="static"
        className="mt-16 !bg-white"
      >
        <Toolbar variant="dense" className="py-4">
          <h5 className="text-xl text-sky-500 mr-8">Online Hospital</h5>
        </Toolbar>
      </AppBar>

      <div className="bg-gray-100 p-4">
        {onlineGatewayHospital && onlineGatewayHospital.length !== 0 ? (
          <>
            <div
              className="grid gap-4 p-4 bg-gray-100
              md:grid-cols-3
              xl:grid-cols-4"
            >
              {onlineGatewayHospital.map((hospital, index) => (
                <OnlineHospitalCard
                  key={hospital._id}
                  title={`${hospital.hospCode} : ${shortHospName(
                    hospital.hospName
                  )}`}
                  cardIndex={index + 1}
                  gatewayVersion={hospital.gatewayVersion}
                  lastActive={hospital.lastActive?.toString()}
                />
              ))}
            </div>
          </>
        ) : (
          <h1> There is no Online Hospital Right now!</h1>
        )}
      </div>
    </div>
  );
}
