import React from "react";
import { ApexOptions } from "apexcharts";
import ApexCharts from "react-apexcharts";
import { ThaiDate } from "../../functions/FuncDateTimes";
import { calculateReportHeight } from "../../functions/report";
import Box from "@mui/material/Box";

interface IReportPercent {
  categories: string[];
  series: { name: string; data: number[] }[];
  startDate: Date;
  endDate: Date;
  colors: string[];
}

export default function ReportPercent({
  categories,
  series,
  startDate,
  endDate,
  colors,
}: IReportPercent) {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    title: {
      text: `เปอร์เซ็นรีเฟอร์ | ${ThaiDate(startDate)} - ${ThaiDate(endDate)}`,
    },
    xaxis: {
      categories,
      labels: {
        formatter(val: any) {
          return `${val} %`;
        },
      },
    },
    yaxis: {
      labels: {
        align: "left",
      },
    },
    tooltip: {
      y: {
        formatter(val: any) {
          return `${val} %`;
        },
      },
    },
    legend: {
      position: "top",
    },
    colors,
  };

  return (
    <Box maxHeight={400} sx={{ overflow: "scroll" }}>
      {categories && (
        <ApexCharts
          options={options}
          series={series}
          type="bar"
          height={calculateReportHeight({
            categoriesLength: categories.length,
          })}
        />
      )}
    </Box>
  );
}
