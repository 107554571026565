import { APPDATA } from "../constants/appData";
import AddHospital from "../api/HospitalManagement/AddHospital";
import EditHospital from "../api/HospitalManagement/EditHospital";
import FetchHospitalTypeList from "../api/HospitalManagement/FetchHospitalTypeList";

export function addHospital({ idToken, body }) {
  return {
    type: APPDATA,
    payload: AddHospital({ idToken, body }),
  };
}

export function editHospital(req) {
  return {
    type: APPDATA,
    payload: EditHospital(req),
  };
}

export function fetchHospitalTypeList({ idToken }) {
  return {
    type: APPDATA,
    payload: FetchHospitalTypeList({ idToken }),
  };
}
