import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default async (dataRequest) => {
  const { hospCode, isProvider = true, idToken } = dataRequest;
  try {
    const response = await axios.get(
      `${getApiUrl()}/v1/billings/category/${hospCode}?isProvider=${isProvider}`,
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );
    return {
      categories: response.data,
      fetchStatus: 200,
      FetchingStatus: false,
    };
  } catch (error) {
    return { fetchStatus: 400, FetchingStatus: false };
  }
};
