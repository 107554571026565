import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import TDepartment from "../../types/department";

const createDepartment = async (token: string, data: TDepartment) => {
  const url = getApiUrl();
  await axios.post(`${url}/v1/department`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export default createDepartment;
