import { Typography, Box } from "@mui/material";
import React from "react";
import { IAppointment } from "@/types/visit";
import { dateToLocalDateString } from "../../../../functions/FuncDateTimes";

function AppointmentItem({
  appointmentDateTime,
  clinicName,
  doctorName,
  appUser,
  note,
}: IAppointment) {
  return (
    <Box className="grid grid-cols-2 bg-slate-50 px-5 py-2 rounded-lg my-2">
      <div>
        <Typography className="text-gray-600">{`วันที่พบแพทย์ : ${dateToLocalDateString(
          appointmentDateTime
        )}`}</Typography>
        <Typography className="text-gray-600">{`พบแพทย์ : ${
          doctorName || "ไม่ระบุ"
        }`}</Typography>
        <Typography className="text-gray-600">{`Note : ${
          note || "-"
        }`}</Typography>
      </div>
      <div>
        <Typography className="text-gray-600">{`คลินิก : ${
          clinicName || "-"
        }`}</Typography>
        <Typography className="text-gray-600">{`ผู้ทำนัด : ${
          appUser || "-"
        }`}</Typography>
      </div>
    </Box>
  );
}

export default AppointmentItem;
