import axios from "axios";
import { getApiUrl } from "../../functions/Commons";

const updateAppointment = async (
  token: string,
  scheduleId: string,
  referId: string,
  cid: string,
  clinicId: string
) => {
  const url = getApiUrl();
  const resp = await axios.put(
    `${url}/v1/schedule/appointment/${scheduleId}`,
    { referId, cid, clinicId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};
export default updateAppointment;
