import { Box } from "@mui/material";
import React from "react";
import { IAdmission } from "@/types/visit";

function VisitAdmit(props: IAdmission) {
  const {
    wardName,
    regDateTime,
    dchDateTime,
    spcltyName,
    pttypeName,
    inchargeDoctorName,
    prediag,
  } = props;
  return (
    <Box className="w-full h-28 border-2 border-gray-200 rounded-lg px-6">
      <Box className="grid grid-cols-3 mt-3">
        <Box>
          <span className="text-gray-400">WARD: </span>
          <span className="text-gray-800">{wardName}</span>
        </Box>
        <Box>
          <span className="text-gray-400">วันที่รับ:</span>
          <span className="text-gray-800">{regDateTime}</span>
        </Box>
        <Box>
          <span className="text-gray-400">วันที่จำหน่าย:</span>
          <span className="text-gray-800">{dchDateTime}</span>
        </Box>
      </Box>
      <Box className="grid grid-cols-3">
        <Box>
          <span className="text-gray-400">แผนก:</span>
          <span className="text-gray-800">{spcltyName}</span>
        </Box>
        <Box>
          <span className="text-gray-400">สิทธิ์ในการรักษา:</span>
          <span className="text-gray-800">{pttypeName}</span>
        </Box>
        <Box>
          <span className="text-gray-400">แพทย์:</span>
          <span className="text-gray-800">{inchargeDoctorName}</span>
        </Box>
      </Box>
      <Box>
        <span className="text-gray-400">Pre Diag::</span>
        <span className="text-gray-800">{prediag}</span>
      </Box>
    </Box>
  );
}

export default VisitAdmit;
