import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { IReportRequest } from "@/types/report";

export default ({
  idToken,
  body,
}: {
  idToken: string;
  body: IReportRequest;
}) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/report`;
  const { startDate, endDate, hospCode } = body;

  return axios
    .get(url, {
      headers: { authorization: `Bearer ${idToken}` },
      params: {
        startDate,
        endDate,
        hospCode,
      },
    })
    .then((res) => {
      dispatch = {
        report: res.data,
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
