import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import "../css/AlertStyle.css";

type TChoice = {
  value: string;
  label: string;
};
type TDialogConfirmSchedule = {
  open: boolean;
  onClose(status: boolean): void;
  choices: TChoice[];
  isDeleted: boolean;
  title: string;
  onConfirm(value: string, isDeleted: boolean): Promise<void>;
};
function DialogChoiceConfirm({
  open,
  onClose,
  choices,
  isDeleted,
  title,
  onConfirm,
}: TDialogConfirmSchedule) {
  const [value, setValue] = useState<string>("id");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (choices.length === 0) return await onConfirm("dayof", false);
      const { isConfirmed } = await Swal.fire({
        position: "center",
        title: `คุณต้องการที่จะ${title} ใช่หรือไม่`,
        showDenyButton: true,
        confirmButtonText: "ตกลง",
        denyButtonText: `ยกเลิก`,
        customClass: {
          container: "swal2-container",
        },
      });
      if (isConfirmed) {
        await onConfirm(value, isDeleted);
        onClose(false);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `${title}เรียบร้อย`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.log("error", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${title}ล้มเหลว`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <FormControl>
            <RadioGroup value={value} onChange={handleRadioChange}>
              {choices.map((choice) => (
                <FormControlLabel
                  key={choice.value}
                  value={choice.value}
                  label={choice.label}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit">ตกลง</Button>
          <Button onClick={() => onClose(false)}>ยกเลิก</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default DialogChoiceConfirm;
