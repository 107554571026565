import axios from "axios";
import { getApiUrl } from "../functions/Commons";
import { IReferHistory } from "@/types/referHistory";

export const getReferHistoryId = async (
  token: string,
  referBid: string
): Promise<IReferHistory[]> => {
  try {
    const url = getApiUrl();
    const encodedReferralBid = encodeURIComponent(referBid);

    const response = await axios.get(
      `${url}/v1/history/refer/${encodedReferralBid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
