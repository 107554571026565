import axios from "axios";

// functions
import { getApiUrl } from "../../functions/Commons";

export default ({ idToken, role }: { idToken: string; role: string }) => {
  let dispatch = {};
  const url = `${getApiUrl()}/v1/roles`;

  const body = {
    role,
  };

  return axios
    .post(url, body, { headers: { Authorization: `Bearer ${idToken}` } })
    .then((res) => {
      dispatch = {
        permissionManagement: {
          role: res.data,
        },
        FetchingStatus: false,
      };

      return dispatch;
    })
    .catch(() => {
      dispatch = {
        FetchingStatus: false,
      };

      return dispatch;
    });
};
