import axios from "axios";
import { getApiUrl } from "../../functions/Commons";
import { TSchedule } from "../../types/schedule";

const exportExcel = async (
  token: string,
  referList: string[]
): Promise<TSchedule[]> => {
  const url = getApiUrl();
  const resp = await axios.post(
    `${url}/v1/schedule/export-excel`,
    { referList },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return resp.data;
};
export default exportExcel;
