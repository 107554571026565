import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Box,
  TextField,
  DialogContent,
  DialogTitle,
  InputLabel,
} from "@mui/material";

import { TClinic } from "../../../types/clinic";

type TModalEdit = {
  token: string;
  open: boolean;
  onHandleModal(status: boolean): void;
  onEditClinicName(
    token: string,
    clinic: TClinic,
    clinicName: string,
    note: string
  ): void;
  clinic: TClinic;
};
function EditClinicModal({
  open,
  onHandleModal,
  clinic,
  token,
  onEditClinicName,
}: TModalEdit) {
  const [clinicName, setClinicName] = useState<string>(clinic.clinicName || "");
  const [note, setNote] = useState<string>(clinic.note || "");

  return (
    <Dialog
      open={open}
      onClose={() => onHandleModal(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>แก้ไขข้อมูลคลินิก {clinic.clinicName} </DialogTitle>
      <DialogContent>
        <Box>
          <InputLabel id="demo-simple-select-label">ชื่อคลินิก</InputLabel>
          <TextField
            value={clinicName}
            onChange={(e) => setClinicName(e.target.value)}
          />
        </Box>
        <Box>
          <InputLabel id="demo-simple-select-label">หมายเหตุ</InputLabel>
          <TextField value={note} onChange={(e) => setNote(e.target.value)} />
        </Box>
        <Box className="m-2">
          <Button
            onClick={() => onEditClinicName(token, clinic, clinicName, note)}
            color="warning"
            variant="contained"
          >
            แก้ไขข้อมูล
          </Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => onHandleModal(false)}>ปิด</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditClinicModal;
