import { Grid } from "@mui/material";
import React from "react";
import { getBillingStatusConfig } from "../../functions/FuncPerjer";
import { Status } from "./styles";
import { IVisitDetail } from "./type";

const VisitDetail = ({ billStatus, visit, isProvider }: IVisitDetail) => {
  const { diagnoses } = visit;
  function dateToLocalDateString(date: string) {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  return (
    <div style={{ padding: 10 }}>
      <Grid container>
        <Grid item xs={9}>
          <h4>
            {isProvider ? "เรียกเก็บ" : "ตามจ่าย"}-
            <strong>{dateToLocalDateString(visit?.visitDateTime)}</strong>
          </h4>
        </Grid>
        <Grid item className="text-right" xs={3}>
          <p>
            <Status
              color={getBillingStatusConfig(billStatus)?.color}
            >{`สถานะ: ${getBillingStatusConfig(billStatus)?.text}`}</Status>
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <strong>VN:</strong> {visit?.vn || ""} &emsp;
          <strong>แผนก:</strong> {visit?.spcltyName || ""} &emsp;
          <strong>สิทธิย่อย:</strong> {visit?.pttypeName || ""} -{" "}
          {visit?.pttypeno || ""}
        </Grid>
        <Grid item xs={12}>
          <strong>ICD10:</strong>{" "}
          {Array.isArray(diagnoses) &&
            diagnoses?.map((item, index) => (
              <p key={item.icd10 || index}>{`${item?.icd10 || ""} - ${
                item?.icd10Name || ""
              }`}</p>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default VisitDetail;
